import React, { useState } from 'react';
import { useNotify, Notification } from 'react-admin';
import { ThemeProvider } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import authCTX from './authCTX';
import qs from 'qs';
import { createBrowserHistory } from 'history';

const ResetPassword = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const notify = useNotify();
  const parsedQueryParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });

  React.useEffect(() => {
    if (props.resetPasswordError) {
      notify(props.resetPasswordErrorMessage);
    }
  }, [props.resetPasswordError]);

  React.useEffect(() => {
    if (props.resetPasswordResponse) {
      const history = createBrowserHistory({ forceRefresh: true });
      history.push('/login');
    }
  }, [props.resetPasswordResponse]);

  const submit = async (e) => {
    e.preventDefault();
    await props.resetPassword({ 
      password: password,
      password_confirmation: confirmPassword,
      token: parsedQueryParams?.token,
      email: email
    });
  };

  return (
    <ThemeProvider theme={props.theme}>
      <form onSubmit={submit}>
        <div className="container">
          <div className="login-warpper">
            <div className="login-container">
              <h1>Passwort zurücksetzen</h1> {/* @TODO Translate: Reset Password */}
              <TextField
                name="email"
                type="email"
                label="E-Mail" // @TODO Translate: e-mail
                required
                variant="filled"
                style={{ width: '100%', marginBottom: 24 }}
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <TextField
                name="password"
                type="password"
                label="New Password"
                required
                variant="filled"
                style={{ width: '100%', marginBottom: 24 }}
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              <TextField
                name="confirmpassword"
                type="password"
                label="Confirm Password"
                required
                variant="filled"
                style={{ width: '100%', marginBottom: 24 }}
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
              />
              <Button style={{ width: '100%', marginTop: 4 }} variant="contained" color="secondary" type="submit">
                Passwort zurücksetzen
              </Button>
            </div>
          </div>
        </div>
      </form>
      <Notification />
    </ThemeProvider>
  );
};

// Prop types of props.
ResetPassword.propTypes = {
  resetPassword: PropTypes.func,
  resetPasswordResponse: PropTypes.any,
  resetPasswordError: PropTypes.bool,
  resetPasswordErrorMessage: PropTypes.string
}

// Set default props.
ResetPassword.defaultProps = {
  resetPassword: () => { },
  resetPasswordResponse: null,
  resetPasswordError: false,
  resetPasswordErrorMessage: ''
}

export default compose(authCTX)(ResetPassword);
