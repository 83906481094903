import React, { Component } from 'react';
import { Switch, Grid, TextField, Button } from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom';
import Dexie from 'dexie';
import { Form, Field } from 'react-final-form';
import LinearProgress from '@material-ui/core/LinearProgress';
import { compose } from 'redux';
import importerCTX from './importerCTX';
import PropTypes from 'prop-types';

import { Crypt } from 'hybrid-crypto-js';

const db = new Dexie('zfu');
db.version(1).stores({
  confirmations:
    '++id, ustid1, ustid2, name, error_code, street, place, postcode, date, time, result_name, result_place, result_postcode, result_street, valid_from, valid_to, print, import_id, license_id, user_id, created_at, updated_at',
});

var parseString = require('xml2js').parseString;
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

class Inputs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expert: true,
      data: false,
      json: [],
      ustid: '',
    };
  }

  handleChange() {
    this.setState({ expert: !this.state.expert }); // TOGGLE
  }

  componentDidMount() {
    const data = JSON.parse(localStorage.getItem('auth'));
    this.setState({ data: data });
  }

  async loadLocal() {
    await this.props.getConfirmationList();
    if (this.props.confirmationListResponse) {
      const privateKey = localStorage.getItem('privateKey');
        var crypt = new Crypt();
        const json = this.props.confirmationListResponse;
        await json.map(async (value, key) => {
          await db
            .transaction('rw', db.confirmations, async () => {
              if (await db.confirmations.where({ id: value.id })) {
                var decrypted = await crypt.decrypt(privateKey, value.data);
                var message = JSON.parse(decrypted.message);
                const id = await db.confirmations.put({
                  id: value.id,
                  ustid1: message.ustid1,
                  ustid2: message.ustid2,
                  name: message.name,
                  error_code: message.errorCode,
                  street: message.street,
                  place: message.place,
                  postcode: message.postcode,
                  date: message.date,
                  time: message.time,
                  result_name: message.result_name,
                  result_place: message.result_place,
                  result_postcode: message.result_postcode,
                  result_street: message.result_street,
                  valid_from: message.valid_from,
                  valid_to: message.valid_to,
                  print: message.print,
                  import_id: value.import_id,
                  license_id: value.license_id,
                  user_id: value.user_id,
                  created_at: value.created_at,
                  updated_at: value.updated_at,
                });
              }
            })
            .catch(e => {
              console.error(e.stack || e);
            });
        });
        await sleep(500);
        await this.setState({ loading: false });
        await this.setState({ done: true });
    }
  }

  onSubmit = async values => {
    this.setState({ loading: true });
    var expertString = this.state.expert
      ? '&Firmenname=' + values.name + '&Ort=' + values.place + '&PLZ=' + values.postcode + '&Strasse=' + values.street
      : '';
    const queryString = '?UstId_1=' + this.state.data.license.ustid + '&UstId_2=' + values.ustid + '' + expertString;
    await this.props.evatRequest(queryString);
    if (this.props.evatRequestResponse) {
      const response = this.props.evatRequestResponse;
      parseString(response, { trim: true }, async (err, result) => {
        const obj = {};
        obj.ustid1 = result.params.param[0].value[0].array[0].data[0].value[1].string[0];
        obj.ustid2 = result.params.param[2].value[0].array[0].data[0].value[1].string[0];
        obj.date = result.params.param[6].value[0].array[0].data[0].value[1].string[0];
        obj.print = result.params.param[3].value[0].array[0].data[0].value[1].string[0];
        obj.time = result.params.param[9].value[0].array[0].data[0].value[1].string[0];
        obj.errorCode = parseInt(result.params.param[1].value[0].array[0].data[0].value[1].string[0]);
        obj.name = result.params.param[14].value[0].array[0].data[0].value[1].string[0] || '';
        obj.place = result.params.param[5].value[0].array[0].data[0].value[1].string[0] || '';
        obj.street = result.params.param[13].value[0].array[0].data[0].value[1].string[0] || '';
        obj.postcode = result.params.param[7].value[0].array[0].data[0].value[1].string[0] || '';
        obj.result_name = result.params.param[10].value[0].array[0].data[0].value[1].string[0] || '';
        obj.result_place = result.params.param[8].value[0].array[0].data[0].value[1].string[0] || '';
        obj.result_street = result.params.param[15].value[0].array[0].data[0].value[1].string[0] || '';
        obj.result_postcode = result.params.param[4].value[0].array[0].data[0].value[1].string[0] || '';
        obj.valid_from = result.params.param[11].value[0].array[0].data[0].value[1].string[0] || '';
        obj.valid_to = result.params.param[12].value[0].array[0].data[0].value[1].string[0] || '';

        var crypt = new Crypt();

        const auth = await JSON.parse(localStorage.getItem('auth'));
        const publicKey = auth.license.publickey;
        var encrypted = crypt.encrypt(publicKey, JSON.stringify(obj));

        await this.props.saveConfirmation(JSON.stringify({ data: encrypted }));
        if (this.props.savedConfirmationResponse) {
          this.setState({ json: this.props.savedConfirmationResponse });
          this.loadLocal();
        }
      });
    }
  };

  render() {
    return (
      <>
        {this.state.loading ? (
          <div style={{ minHeight: '80vh' }}>
            <LinearProgress style={{ margin: 60 }} />
            <p style={{ textAlign: 'center' }}>Bitte warten Sie einen Moment. Die Anfrage wurde gestartet.</p> {/* @TODO Translate: Please wait a moment. Die Request had been started.  */}
          </div>
        ) : this.state.json && this.state.done ? (
          <div style={{ minHeight: '80vh' }}>
            <Redirect to={'/confirmations/' + this.state.json.id + '/show'} />
          </div>
        ) : !this.state.data ? (
          <div>Loading... {/* @TODO Translate to german: Lädt...  */}</div>
        ) : (
          <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }}>
            <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Switch
                checked={this.state.expert}
                onChange={() => this.handleChange()}
                name="expert"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <p>Qualifizierte Abfrage</p>
              {/* @TODO Translate: Extended request  */}
            </div>
            <Grid container>
              <Form onSubmit={this.onSubmit}>
                {props => (
                  <form onSubmit={props.handleSubmit} style={{ width: '100%' }}>
                    <div className="separator">Einfache Abfrage</div> {/* @TODO Translate: Simple request  */}
                    <Grid container md={12} direction="row" style={{ alignItems: 'center' }}>
                      <Grid item md={6}>
                        <b>USt-IdNr.</b> {/* @TODO Translate: VAT registration number  */}
                      </Grid>
                      <Grid item md={6}>
                        <Field name="ustid">
                          {props => (
                            <TextField
                              placeholder=" z.B. IT123344"
                              variant="filled"
                              fullWidth
                              autoComplete="off" // @TODO Translate: e.g. IT123344
                              required
                              label="USt-IdNr." // @TODO Translate: VAT registration number
                              {...props.input}
                            />
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                    {this.state.expert ? (
                      <>
                        <div className="separator">Qualifizierte Abfrage</div>

                        <Grid container md={12} direction="row" style={{ alignItems: 'center', marginBottom: 24 }}>
                          <Grid item md={6}>
                            <b>Firmenname inkl. Rechtsform</b> {/* @TODO Translate: Company including legal form  */}
                          </Grid>
                          <Grid item md={6}>
                            <Field name="name">
                              {props => (
                                <TextField
                                  placeholder="z.B. Firmenname GmbH"
                                  variant="filled"
                                  fullWidth
                                  autoComplete="off" // @TODO Translate: e.g. Company Inc.
                                  required
                                  label="Firmenname inkl. Rechtsform" // @TODO Translate: Company including legal form
                                  {...props.input}
                                />
                              )}
                            </Field>
                          </Grid>
                        </Grid>

                        <Grid container md={12} direction="row" style={{ alignItems: 'center', marginBottom: 24 }}>
                          <Grid item md={6}>
                            <b>Ort</b> {/* @TODO Translate: City  */}
                          </Grid>
                          <Grid item md={6}>
                            <Field name="place">
                              {props => (
                                <TextField
                                  placeholder=" z.B. Berlin"
                                  variant="filled"
                                  fullWidth
                                  autoComplete="off" // @TODO Translate: e.g. Berlin
                                  required
                                  label="Ort" // @TODO Translate: City
                                  {...props.input}
                                />
                              )}
                            </Field>
                          </Grid>
                        </Grid>

                        <Grid container md={12} direction="row" style={{ alignItems: 'center', marginBottom: 24 }}>
                          <Grid item md={6}>
                            <b>Postleitzahl</b> {/* @TODO Translate: Zip code  */}
                          </Grid>
                          <Grid item md={6}>
                            <Field name="postcode">
                              {props => (
                                <TextField
                                  placeholder=" z.B. 12222"
                                  variant="filled"
                                  fullWidth
                                  autoComplete="off" // @TODO Translate: e.g. 12345
                                  label="Postleitzahl" // @TODO Translate: Zip code
                                  {...props.input}
                                />
                              )}
                            </Field>
                          </Grid>
                        </Grid>

                        <Grid container md={12} direction="row" style={{ alignItems: 'center', marginBottom: 24 }}>
                          <Grid item md={6}>
                            <b>Straße & Hausnummer</b> {/* @TODO Translate: Street and Housenumber  */}
                          </Grid>
                          <Grid item md={6}>
                            <Field name="street">
                              {props => (
                                <TextField
                                  placeholder=" z.B. Musterstraße 12a"
                                  variant="filled"
                                  fullWidth
                                  autoComplete="off" // @TODO Translate: e.g. Examplestreet 12a
                                  label="Straße & Hausnummer" // @TODO Translate: Street and Housenumber
                                  {...props.input}
                                />
                              )}
                            </Field>
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <div style={{ marginBottom: 24 }}></div>
                    )}
                    <Grid container spacing={3} style={{ marginBottom: 24, justifyContent: 'flex-end' }}>
                      <Grid item>
                        <Button>
                          <Link to="/confirmations" className="customButton">
                            Abbrechen
                          </Link>
                        </Button> {/* @TODO Translate: Cancel  */}
                      </Grid>
                      <Grid item>
                        <Button type="submit" variant="contained" color="primary">
                          Anfrage senden
                        </Button> {/* @TODO Translate: Send request  */}
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Form>
            </Grid>
          </div>
        )}
      </>
    );
  }
}

// Prop types of props.
Inputs.propTypes = {
  evatRequest: PropTypes.func,
  evatRequestResponse: PropTypes.any,

  saveConfirmation: PropTypes.func,
  savedConfirmationResponse: PropTypes.any,

  getConfirmationList: PropTypes.func,
  confirmationListResponse: PropTypes.array
}

// Set default props.
Inputs.defaultProps = {
  evatRequest: () => { },
  evatRequestResponse: null,

  saveConfirmation: () => { },
  savedConfirmationResponse: null,

  getConfirmationList: () => { },
  confirmationListResponse: []
}

export default compose(importerCTX)(Inputs);
