import React from 'react';
import { connect } from 'react-redux';

import { evatRequest, importRequest, saveConfirmation } from '@zfu-importer/actions';
import { getImporterResponse, getRequestImportResponse, getConfirmationSaveResponse } from '@zfu-importer/selector';

import { getConfirmationList } from '@zfu-confirmations/actions';
import { getConfirmationResponse } from '@zfu-confirmations/selector';

const HOC = WrappedComponent => {
  const Decorator = props => <WrappedComponent {...props} />;

  const mapStateToProps = state => ({
    evatRequestResponse: getImporterResponse(state).evatRequestResponse,
    importResponse: getRequestImportResponse(state).importResponse,
    savedConfirmationResponse: getConfirmationSaveResponse(state).confirmationSaveResponse,

    confirmationListResponse: getConfirmationResponse(state).confirmationList
  });

  const mapDispatchToProps = {
    evatRequest,
    importRequest,
    saveConfirmation,

    getConfirmationList
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Decorator);
};

export default HOC;
