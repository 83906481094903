import React from 'react';
import { connect } from 'react-redux';

import { resetPassword, forgetPassword } from '../actions';
import { getForgetPasswordResponse, getResetPasswordResponse } from '../selector';

const HOC = WrappedComponent => {
  const Decorator = props => <WrappedComponent {...props} />;

  const mapStateToProps = state => ({
    forgetPasswordResponse: getForgetPasswordResponse(state).forgetPasswordResponse,
    forgetPasswordError: getForgetPasswordResponse(state).forgetPasswordError,
    forgetPasswordErrorMessage: getForgetPasswordResponse(state).forgetPasswordErrorMessage,
  
    resetPasswordResponse: getResetPasswordResponse(state).resetPasswordResponse,
    resetPasswordError: getResetPasswordResponse(state).resetPasswordError,
    resetPasswordErrorMessage: getResetPasswordResponse(state).resetPasswordErrorMessage
  });

  const mapDispatchToProps = {
    forgetPassword,
    resetPassword
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Decorator);
};

export default HOC;
