import React, { Component } from 'react';
import { Switch, Typography, Grid, Button, MenuItem } from '@material-ui/core';
import { ExcelRenderer } from "react-excel-renderer";
import Select from 'react-select';
import FakeInput from './fakeInput';
import SelectList from './SelectList';

const FileContentTypeEnum = Object.freeze({
  DATA: 1,
  LABEL: 2
});

const fileLabelColumns = [
  'id', 'ustid1', 'ustid2', 'name', 'error_code', 'street', 'place', 'postcode', 'date', 'time', 'result_name', 'result_place', 'result_postcode', 'result_street', 'valid_from', 'valid_to', 'print', 'import_id', 'license_id', 'user_id', 'created_at', 'updated_at'
];

const customStyles = {
  control: base => ({
    ...base,
    height: 45,
    minHeight: 45,
  }),
};

class Sorter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expert: true,
      data: false,
      ustid: '',
      name: '',
      postcode: '',
      place: '',
      street: '',
      selectedContentType: { value: FileContentTypeEnum.LABEL, label: 'Beschriftung' },
      allocatedDatas: []
    };
  }

  handleChange() {
    this.setState({ expert: !this.state.expert }); // TOGGLE
  }

  checkStates() {
    if (this.state.expert) {
      if (this.state.ustid.value && this.state.name.value && this.state.place.value) {
        const obj = {};
        obj.expert = this.state.expert;
        obj.ustid = this.state.ustid.value;
        obj.name = this.state.name.value || null;
        obj.postcode = this.state.postcode.value || null;
        obj.place = this.state.place.value || null;
        obj.street = this.state.street.value || null;
        sessionStorage.setItem('sorter', JSON.stringify(obj));
        this.props.nextStep(this.state.data);
      }
    } else {
      if (this.state.ustid.value) {
        const obj = {};
        obj.expert = this.state.expert;
        obj.ustid = this.state.ustid.value;
        obj.name = this.state.name.value || null;
        obj.postcode = this.state.postcode.value || null;
        obj.place = this.state.place.value || null;
        obj.street = this.state.street.value || null;
        sessionStorage.setItem('sorter', JSON.stringify(obj));
        this.props.nextStep(this.state.data);
      }
    }
  }

  componentDidMount() {
    this.fileParser(this.props.uploadedFile, FileContentTypeEnum.LABEL);
  }

  fileParser = (fileObj, selectedContentType) => {
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.error(err);
      } else {
        const newRows = [];
        let cols = [];
        let responseRows = resp.rows;
        if (selectedContentType === FileContentTypeEnum.LABEL) {
          cols = resp.rows[0];
          responseRows = resp.rows.slice(1);
        } else {
          cols = fileLabelColumns;
          responseRows = resp.rows;
        }
        let data = {};
        responseRows.map((row) => {
          if (row && row.length > 0) {
            cols.map((col, key) => {
              if (row && row.length > 0) {
                row.map((obj, i) => {
                  data = {
                    ...data,
                    [col]: row[key] ? row[key] : ""
                  }
                });
              }
            });
            newRows.push(data);
          }
        });
        const result = {
          data: newRows
        }
        this.updateData(result);
      }
    });
  }

  updateData = result => {
    const data = result.data;
    this.setState({ data });
  };

  selectFileContentType = (data) => {
    this.setState({ selectedContentType: data });
    this.fileParser(this.props.uploadedFile, data.value);
  }

  onChangeSeletion = (item, changedItem, stateName) => {
    const allocatedDatas = this.state.allocatedDatas;
    if (item && item.value) {
      allocatedDatas.push(item.value);
    }
    if (changedItem && changedItem.value) {
      const index = allocatedDatas && allocatedDatas.findIndex((obj) => obj === changedItem.value);
      if (index !== -1) {
        allocatedDatas.splice(index, 1);
      }
    }
    this.setState({ [stateName]: item, allocatedDatas });
  }

  render() {
    return (
      <>
        {!this.state.data ? (
          <div>Loading.... {/* @TODO Translate to German: Lädt... */}</div>
        ) : (
          <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }}>
            <Typography variant="h2" align="center">
              Zuordnung Datenfelder {/* @TODO Translate: Mapping data fields  */}
            </Typography>
            <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Switch
                checked={this.state.expert}
                onChange={() => this.handleChange()}
                name="expert"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <p>Qualifizierte Abfrage</p> {/* @TODO Translate: Extended request  */}
            </div>
            <Grid container>
              <Grid container md={12} direction="row">
                <Grid item md={6}>
                  <FakeInput name="Die erste Zeile der Datei ist:" isRequired={true} />
                </Grid>
                <Grid item md={6}>
                  <div style={{ padding: 0, backgroundColor: 'rgba(0, 0, 0, 0.04)', borderRadius: 4, margin: 10, marginLeft: 0, fontWeight: 'bold' }}>
                    <Select
                      options={[
                        { value: FileContentTypeEnum.DATA, label: 'Daten' },
                        { value: FileContentTypeEnum.LABEL, label: 'Beschriftung' }
                      ]}
                      value={this.state.selectedContentType}
                      styles={customStyles}
                      onChange={this.selectFileContentType} />
                  </div>
                  </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <div className="separator">Einfache Abfrage</div> {/* @TODO Translate: simple request  */}
              <Grid container md={12} direction="row">
                <Grid item md={6}>
                  <FakeInput name="UstId." isRequired={true} />
                </Grid>
                <Grid item md={6}>
                  <SelectList
                    name="ustid"
                    data={this.state.data}
                    selected={this.state.ustid}
                    allocatedDatas={this.state.allocatedDatas}
                    onChange={item => this.onChangeSeletion(item, this.state.ustid, 'ustid')}
                  />
                </Grid>
              </Grid>
              {this.state.expert ? (
                <>
                  <div className="separator">Qualifizierte Abfrage</div> {/* @TODO Translate: Extended request  */}
                  <Grid container md={12} direction="row">
                    <Grid item md={6}>
                      <FakeInput name="Firmenname inkl. Rechtsform" isRequired={true} />
                    </Grid> {/* @TODO Translate: Company including legal form  */}
                    <Grid item md={6}>
                      <SelectList
                        name="name"
                        data={this.state.data}
                        selected={this.state.name}
                        allocatedDatas={this.state.allocatedDatas}
                        onChange={item => this.onChangeSeletion(item, this.state.name, 'name')}
                      />
                    </Grid>
                  </Grid>
                  <Grid container md={12} direction="row">
                    <Grid item md={6}>
                      <FakeInput name="Ort" isRequired={true} />
                    </Grid> {/* @TODO Translate: City  */}
                    <Grid item md={6}>
                      <SelectList
                        name="place"
                        data={this.state.data}
                        selected={this.state.place}
                        allocatedDatas={this.state.allocatedDatas}
                        onChange={item => this.onChangeSeletion(item, this.state.place, 'place')}
                      />
                    </Grid>
                  </Grid>
                  <Grid container md={12} direction="row">
                    <Grid item md={6}>
                      <FakeInput name="Postleitzahl" />
                    </Grid> {/* @TODO Translate: ZIP code  */}
                    <Grid item md={6}>
                      <SelectList
                        name="postcode"
                        data={this.state.data}
                        selected={this.state.postcode}
                        allocatedDatas={this.state.allocatedDatas}
                        onChange={item => this.onChangeSeletion(item, this.state.postcode, 'postcode')}
                      />
                    </Grid>
                  </Grid>
                  <Grid container md={12} direction="row">
                    <Grid item md={6}>
                      <FakeInput name="Straße und Hausnummer" />
                    </Grid> {/* @TODO Translate: Street and housenumber  */}
                    <Grid item md={6}>
                      <SelectList
                        name="street"
                        data={this.state.data}
                        selected={this.state.street}
                        allocatedDatas={this.state.allocatedDatas}
                        onChange={item => this.onChangeSeletion(item, this.state.street, 'street')}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : null}
              <div style={{ width: '100%', flexDirection: 'row', justifyContent: 'flex-end', display: 'flex', padding: 20 }}>
                <Button variant="text" color="primary" onClick={() => this.props.preStep()}>
                  Abbrechen
                </Button> {/* @TODO Translate: cancel  */}
                <div style={{ width: 16 }}></div>
                <Button variant="contained" color="primary" onClick={() => this.checkStates()}>
                  Anfragen
                </Button> {/* @TODO Translate: Send request  */}
              </div>
            </Grid>
          </div>
        )}
      </>
    );
  }
}

export default Sorter;
