import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import { apiUrl } from '@zfu-utils';
import { importer as T } from './constants';
import { handleError, handleResponse, getConfig } from '../../reducers/api';

// Single request input
export const requestSingleRequestInput = () => ({
	type: T.REQUEST_SINGLE_REQUEST_INPUT
});

export const receiveSingleRequestInput = response => ({
	type: T.RECEIVE_SINGLE_REQUEST_INPUT,
	response
});

export const receiveSingleRequestInputFailure = error => ({
	type: T.RECEIVE_SINGLE_REQUEST_INPUT_FAILURE,
	error
});

/**
 * Single request input
 *
*/
export const evatRequest = (queryString) => dispatch => {
	dispatch(requestSingleRequestInput());
	const finalUrl = apiUrl + '/evatrequest' + queryString;
	return trackPromise(axios.get(finalUrl, getConfig())
		.then(response => handleResponse(response))
		.then(result => {
				if (result) {
					dispatch(receiveSingleRequestInput(result));
				}
		})
		.catch(error =>
			handleError(error, dispatch, receiveSingleRequestInputFailure, 'Single request input')
		));
}

// Request import
export const requestImport = () => ({
	type: T.REQUEST_IMPORT
});

export const receiveImport = response => ({
	type: T.RECEIVE_IMPORT,
	response
});

export const receiveImportFailure = error => ({
	type: T.RECEIVE_IMPORT_FAILURE,
	error
});

/**
 * Request import
 *
*/
export const importRequest = (data) => dispatch => {
	dispatch(requestImport());
	const finalUrl = apiUrl + '/import';
	return trackPromise(axios.post(finalUrl, data, getConfig())
		.then(response => handleResponse(response))
		.then(result => dispatch(receiveImport(result)))
		.catch(error =>
			handleError(error, dispatch, receiveImportFailure, 'Import')
		));
}

// Confirmation save
export const requestConfirmationSave = () => ({
	type: T.REQUEST_SAVE_CONFIRMATION
});

export const receiveConfirmationSave = response => ({
	type: T.RECEIVE_SAVE_CONFIRMATION,
	response
});

export const receiveConfirmationSaveFailure = error => ({
	type: T.RECEIVE_SAVE_CONFIRMATION_FAILURE,
	error
});

/**
 * Confirmation save request
 *
*/
export const saveConfirmation = (data) => dispatch => {
	dispatch(requestConfirmationSave());
	const finalUrl = apiUrl + '/confirmations';
	return trackPromise(axios.post(finalUrl, data, getConfig())
		.then(response => handleResponse(response))
		.then(result => dispatch(receiveConfirmationSave(result)))
		.catch(error =>
			handleError(error, dispatch, receiveConfirmationSaveFailure, 'Save confirmation')
		));
}
