export const authentication = {
    REQUEST_CHECK_LICENSE: 'REQUEST_CHECK_LICENSE',
    RECEIVE_CHECK_LICENSE: 'RECEIVE_CHECK_LICENSE',
    RECEIVE_CHECK_LICENSE_FAILURE: 'RECEIVE_CHECK_LICENSE_FAILURE',

    REQUEST_FORGET_PASSWORD: 'REQUEST_FORGET_PASSWORD',
    RECEIVE_FORGET_PASWWORD: 'RECEIVE_FORGET_PASWWORD',
    RECEIVE_FORGET_PASWWORD_FAILURE: 'RECEIVE_FORGET_PASWWORD_FAILURE',

    REQUEST_RESET_PASSWORD: 'REQUEST_RESET_PASSWORD',
    RECEIVE_RESET_PASWWORD: 'RECEIVE_RESET_PASWWORD',
    RECEIVE_RESET_PASWWORD_FAILURE: 'RECEIVE_RESET_PASWWORD_FAILURE'
}

export default authentication;