import * as React from 'react';
import { Edit, SimpleForm, useInput, ReferenceInput, SelectInput, Toolbar, SaveButton, DeleteButton } from 'react-admin';
import { Select, FormControl, InputLabel, MenuItem } from '@material-ui/core';

const MySelect = props => {
  const [value, setValue] = React.useState(props.record.roles[0].name); // INIT DEFAULT VALUE

  const {
    input,
    meta: { touched, error },
  } = useInput(props);

  const handleChange = event => {
    setValue(event.target.value); // VALUE WRITE
    input.onChange(event.target.value); // VALUE WRITE SAME VALUE
  };

  return (
    <FormControl variant="filled">
      <InputLabel>Rolle</InputLabel> {/* @TODO Translate: role. */}
      <Select
        {...input}
        onChange={handleChange}
        value={value} //  ( input.value => value )
      >
        <MenuItem value="user">Benutzer</MenuItem> {/* @TODO Translate: User */}
        <MenuItem value="admin">Administrator</MenuItem> {/* @TODO Translate: admin */}
        {props.permissions === 'superadmin' ? (
          <MenuItem value="superadmin">Super-Administrator</MenuItem> // @TODO Translate: super admin
        ) : null}
      </Select>
    </FormControl>
  );
};

const UserTitle = ({ record }) => {
  return <span>{record ? `Konto bearbeiten: ${record.email}` : ''}</span>;
};

const PostCreateToolbar = props => (
  <Toolbar {...props}>
    <SaveButton submitOnEnter={true} />
    <DeleteButton />
  </Toolbar>
);

const Headline = props => {
  return <h1>{props.record.email}</h1>;
};

export const UserEdit = ({ permissions, ...props }) => {
  return (
    <Edit {...props} title={<UserTitle />} redirect="list">
      <SimpleForm toolbar={<PostCreateToolbar />}>
        <Headline {...props} />
        <MySelect source="role" {...props} permissions={permissions} />
        {permissions === 'superadmin' ? (
          <ReferenceInput label="Firma" source="license_id" reference="license">
            <SelectInput optionText="company_name" optionValue="id" />
          </ReferenceInput>
        ) : null}
      </SimpleForm>
    </Edit>
  );
};
