import { default as DataProvider } from './dataProvider';
import { default as DataProvider2 } from '../realm/index';

class CompositeDataProvider {
  constructor(dataProviders) {
    this.dataProviders = dataProviders;
  }

  _delegate(name, resource, params) {
    const { dataProvider } = this.dataProviders.find(dp => dp.resources.includes(resource));

    return dataProvider[name](resource, params);
  }

  getList(resource, params) {
    return this._delegate('getList', resource, params);
  }

  getOne(resource, params) {
    return this._delegate('getOne', resource, params);
  }

  getMany(resource, params) {
    return this._delegate('getMany', resource, params);
  }

  getManyReference(resource, params) {
    return this._delegate('getManyReference', resource, params);
  }

  create(resource, params) {
    return this._delegate('create', resource, params);
  }

  update(resource, params) {
    return this._delegate('update', resource, params);
  }

  updateMany(resource, params) {
    return this._delegate('updateMany', resource, params);
  }

  delete(resource, params) {
    return this._delegate('delete', resource, params);
  }

  deleteMany(resource, params) {
    return this._delegate('deleteMany', resource, params);
  }
}

export default new CompositeDataProvider([
  {
    dataProvider: DataProvider,
    resources: ['user', 'license', 'import', 'confirmationUserList'],
  },
  {
    dataProvider: DataProvider2,
    resources: ['confirmations'],
  },
]);
