import { authentication as T } from './constants';
import { updateObject } from '../../redux-utils/updateObject';
import { defaultStore } from '../../reducers/store';

const authenticationReducer = (state = defaultStore, action) => {
	switch (action.type) {
		case T.REQUEST_CHECK_LICENSE:
			return updateObject(state, {
				checkLiscenseResponse: {
					...state.checkLiscenseResponse,
					loading: true,
					checkLicenseError: false,
					checkLicenseErrorMessage: null
				},
			});

		case T.RECEIVE_CHECK_LICENSE:
			return updateObject(state, {
				checkLiscenseResponse: {
					...state.checkLiscenseResponse,
					loading: false,
					checkLicenseError: false,
					checkLicenseErrorMessage: null,
					checkLicenseResponse: action.checkLicenseResponse
				},
			});

		case T.RECEIVE_CHECK_LICENSE_FAILURE:
			return updateObject(state, {
				checkLiscenseResponse: {
					...state.checkLiscenseResponse,
					loading: false,
					checkLicenseError: true,
					checkLicenseErrorMessage: action.error,
				},
			});

		case T.REQUEST_FORGET_PASSWORD:
			return updateObject(state, {
				forgetPassword: {
					...state.forgetPassword,
					loading: true,
					forgetPasswordError: false,
					forgetPasswordErrorMessage: null
				},
			});

		case T.RECEIVE_FORGET_PASWWORD:
			return updateObject(state, {
				forgetPassword: {
					...state.forgetPassword,
					loading: false,
					forgetPasswordError: false,
					forgetPasswordErrorMessage: null,
					forgetPasswordResponse: action.response.message
				},
			});

		case T.RECEIVE_FORGET_PASWWORD_FAILURE:
			return updateObject(state, {
				forgetPassword: {
					...state.forgetPassword,
					loading: false,
					forgetPasswordError: true,
					forgetPasswordErrorMessage: action.error,
				},
			});

		case T.REQUEST_RESET_PASSWORD:
			return updateObject(state, {
				resetPassword: {
					...state.resetPassword,
					loading: true,
					resetPasswordError: false,
					resetPasswordErrorMessage: null
				},
			});

		case T.RECEIVE_RESET_PASWWORD:
			return updateObject(state, {
				resetPassword: {
					...state.resetPassword,
					loading: false,
					resetPasswordError: false,
					resetPasswordErrorMessage: null,
					resetPasswordResponse: action.response
				},
			});

		case T.RECEIVE_RESET_PASWWORD_FAILURE:
			return updateObject(state, {
				resetPassword: {
					...state.resetPassword,
					loading: false,
					resetPasswordError: true,
					resetPasswordErrorMessage: action.error,
				},
			});

		default:
			return updateObject(state, {});
	}
}

export default authenticationReducer;