import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Lock from '@material-ui/icons/Lock';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles((theme) => ({
  downloadButton: {
    marginLeft: 6,
    backgroundColor: 'var(--red)',
    margin: '30px 0px'
  },
  buttenText: {
    color: 'var(--white)',
    textTransform: 'Uppercase'
  },
  description: {
    color: 'var(--blue)',
    fontSize: '16px',
    marginLeft: '10px'
  },
  infoBox: {
    background: 'var(--infoBox-background)',
    width: '100%',
    borderRadius: '5px',
    margin: '20px 0px',
    color: 'var(--infoBox-color)',
    display: 'flex',
    flexDirection: 'row',
    padding: '15px 0px'
  },
  downloadButtonWrapper: {
    textAlign: 'center'
  },
  title: {
    textAlign: 'center',
    margin: '5px 0px'
  },
  loaderWrapper: {
    textAlign: 'center',
    marginTop: '20px'
  },
  success: {
    color: 'var(--status-green)'
  },
  errorMessage: {
    color: 'var(--red)'
  }
}));

export function DownloadPDF(props) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.infoBox}>
        <Lock style={{ padding: '10px' }} />
        <span className={classes.description}>
          <b>Eine revisionssichere PDF-Datei wird erzeugt: </b>Speichern Sie die Datei und legen Sie diese anschließend in Ihrer IT-infrastruktur zur Archivierung ab</span>
      </div>
      <h3 className={classes.title}>Revisionssicheres PDF</h3>
      {!props.signPDFError ? (
        <>
          {props.signPDFLoading ? (
            <div className={classes.loaderWrapper}>
              <CircularProgress size={22} style={{ marginRight: 12 }} />
              <span>Revisionssicheres PDF-Datei wird erzeugt...</span>
            </div>
          ) :
            (<div className={classes.loaderWrapper}>
              <DoneIcon className={classes.success} size={22} style={{ marginRight: 12 }} />
              <span style={{ verticalAlign: 'super' }}>Revisionssicheres PDF wurde erfolgreich erzeugt!</span>
            </div>)}
        </>
      ) :
        <div className={classes.loaderWrapper}><span className={classes.errorMessage}>{props.signPDFErrorMessage}</span></div>}
      <div className={classes.downloadButtonWrapper}>
        <Button
          className={classes.downloadButton}
          aria-controls="simple-menu"
          size="large"
          aria-haspopup="true"
          color="primary"
          variant="contained"
          disabled={props.signPDFLoading || props.signPDFError}
          startIcon={<CloudDownload />}
        >
          <a download="confirmations.pdf"
            className={classes.buttenText}
            href={`data:application/pdf;base64,${props.signPDFResponse}`}>Revisionssicheres pdf speichern</a>
        </Button>
      </div>

    </>
  );
}