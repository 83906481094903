import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
  Image
} from "@react-pdf/renderer";
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Search from '@material-ui/icons/Search';
import * as moment from 'moment';
import logo from '../../../../../assets/images/VIP-logo.png';

const ValidTypeColor = '#00D020';
const InvalidTypeColor = '#C70000';

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff"
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: '#989898',
    borderWidth: 1,
    borderBottomWidth: 0,
    margin: '10px'
  },
  tableRow: {
    flexDirection: "row",
    borderStyle: "solid",
    borderColor: '#989898',
    borderWidth: 1,
    borderLeft: 0,
    borderRight: 0,
    borderTop: 0
  },
  tableCol: {
    borderStyle: "solid",
    borderColor: '#989898',
    borderWidth: 1,
    borderLeft: 0,
    borderTop: 0,
    borderBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    textOverflow: 'hidden',
    flexWrap: 'wrap'
  },
  tableColHeader: {
    backgroundColor: '#E6EAF2',
    color: '#084b8e'
  },
  tableCell: {
    marginTop: 5,
    fontSize: 9,
    padding: '5px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    textOverflow: 'hidden'
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row'
  },
  title: {
    color: '#084b8e',
    padding: '10px',
    fontSize: 16,
    textAlign: 'center',
    fontWeight: 'extrabold',
    top: 5
  },
  statusIcon: {
    width: 12,
    height: 12,
    fontWeight: 800,
    borderRadius: 30,
    fontSize: 7,
    paddingTop: 2,
    paddingLeft: 4,
    paddingRight: 2,
    backgroundColor: '#fff',
    color: '#000',
    borderWidth: 1,
    borderColor: '#000'
  },
  statusLegendMainWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    right: 10
  },
  statusLegendWrapper: {
    display: 'flex',
    flexDirection: 'row',
    margin: 4
  },
  statuText: {
    fontSize: 10,
    marginLeft: 2
  },
  centerImage: {
    width: 100,
    height: 30,
    top: 10,
    left: 10,
  },
  tableCellTextWrapper: {
    flex: 1
  },
  tableCellStatusIconWrapper: {
    flex: '0 0 10px',
    top: 5,
    right: 5
  }
});

function TextStatusIcon(sourceType) {
  if (sourceType) {
    const text = sourceType;
    const invalidStatus = sourceType !== 200 && sourceType !== 'A' && sourceType !== 'B' && sourceType !== 'C' && sourceType !== 'D';

    if (sourceType === 200) {
      return (
        <View style={{
          display: 'inline-block',
          width: 13,
          height: 13,
          transform: 'rotate(45deg)'
        }}>
          <Text style={{
            position: 'absolute',
            width: 13,
            height: 13,
            backgroundColor: ValidTypeColor,
            borderRadius: 11,
            left: 0,
            top: 0
          }}></Text>
         <Text style={{
             position: 'absolute',
             width: 2,
             height: 8,
             backgroundColor: '#fff',
             left: 6,
             top: 2
          }}></Text>
           <Text style={{
            position: 'absolute',
            width: 2,
            height: 2,
            backgroundColor: '#fff',
            left: 4,
            top: 8
          }}></Text>
        </View>
      )
    }

    if (invalidStatus) {
      return (
        <View style={{
          display: 'inline-block',
          width: 15,
          height: 15,
          transform: 'rotate(360deg)'
        }}>
          <Text style={{
            position: 'absolute',
            left: 0,
            top: 0,
            borderWidth: 1,
            borderLeft: 7,
            borderLeftColor: '#fff',
            borderRight: 7,
            borderRightColor: '#fff',
            borderBottom: 11,
            borderBottomColor: InvalidTypeColor,
            borderTop: 0,
          }}></Text>
          <Text style={{
            position: 'absolute',
            color: '#fff',
            left: 7,
            top: 3,
            fontSize: 6,
            fontWeight: 'bold'
         }}>i</Text>
        </View>
      )
    }

    return (
      <Text style={styles.statusIcon}>{text}</Text>
    )
  }
  return <></>;
}

function PDFView(props) {
  const confirmationList = props.confirmationList;

  const now = moment().format();
  
  const [company, setCompany] = useState('');
  const [vatID, setVatId] = useState('');
  const [userName, setUserName] = useState('');

  useEffect(() => {
    async function fetchUserInformation() {
      const auth = await JSON.parse(localStorage.getItem('auth'));
      if (auth !== null) {
        const license = auth.license;
        setCompany(license.company_name);
        setVatId(license.ustid);
        setUserName(license.firstname + ' ' + license.lastname);
      }
    }
    fetchUserInformation();
  }, []);

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.titleWrapper}>
          <View style={{ flex: 1 }}>
            <Image style={styles.centerImage} src={logo} />
          </View>
          <View style={{ flex: 1 }}>
            <Text style={styles.title}>Prüfungsbestätigung</Text>
          </View>
          <View style={{ flex: 1, textAlign: 'right', right: 10, bottom: 6, justifyContent: 'flex-end', color: '#084b8e' }}>
            <Text style={{ fontSize: 10 }}>{moment(now).format('DD.MM.YYYY')}</Text>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={Object.assign({}, styles.tableCol, styles.tableColHeader, { width: '4%' })}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View style={Object.assign({}, styles.tableCol, styles.tableColHeader, { width: '15%' })}>
              <Text style={styles.tableCell}>USt-IdNr.</Text>
            </View>
            <View style={Object.assign({}, styles.tableCol, styles.tableColHeader, { width: '20%' })}>
              <Text style={styles.tableCell}>Firmenname</Text>
            </View>
            <View style={Object.assign({}, styles.tableCol, styles.tableColHeader, { width: '20%' })}>
              <Text style={styles.tableCell}>Firmenort</Text>
            </View>
            <View style={Object.assign({}, styles.tableCol, styles.tableColHeader, { width: '10%' })}>
              <Text style={styles.tableCell}>PLZ</Text>
            </View>
            <View style={Object.assign({}, styles.tableCol, styles.tableColHeader, { width: '15%' })}>
              <Text style={styles.tableCell}>Straße & Nr.</Text>
            </View>
            <View style={Object.assign({}, styles.tableCol, styles.tableColHeader, { width: '6%' })}>
              <Text style={styles.tableCell}>Status</Text>
            </View>
            <View style={Object.assign({}, styles.tableCol, styles.tableColHeader, { width: '10%', borderRight: 0 })}>
              <Text style={styles.tableCell}>Datum</Text>
            </View>
          </View>
          {confirmationList && confirmationList.map((confirmation) => {
            return (
              <View style={styles.tableRow} key={confirmation.id} wrap={false}>
                <View style={Object.assign({}, styles.tableCol, { width: '4%' })}>
                  <View style={{ top: 6, left: 3 }}>{TextStatusIcon(confirmation.error_code)}</View>
                </View>
                <View style={Object.assign({}, styles.tableCol, { width: '15%' })}>
                  <Text style={styles.tableCell}>{confirmation.ustid2}</Text>
                </View>
                <View style={Object.assign({}, styles.tableCol, { width: '20%' })}>
                  <View style={styles.tableCellTextWrapper}><Text style={styles.tableCell}>{confirmation.name}</Text></View>
                  <View style={styles.tableCellStatusIconWrapper}>{TextStatusIcon(confirmation.result_name)}</View>
                </View>
                <View style={Object.assign({}, styles.tableCol, { width: '20%' })}>
                  <View style={styles.tableCellTextWrapper}><Text style={styles.tableCell}>{confirmation.place}</Text></View>
                  <View style={styles.tableCellStatusIconWrapper}>{TextStatusIcon(confirmation.result_place)}</View>
                </View>
                <View style={Object.assign({}, styles.tableCol, { width: '10%' })}>
                  <View style={styles.tableCellTextWrapper}><Text style={styles.tableCell}>{confirmation.postcode}</Text></View>
                  <View style={styles.tableCellStatusIconWrapper}>{TextStatusIcon(confirmation.result_postcode)}</View>
                </View>
                <View style={Object.assign({}, styles.tableCol, { width: '15%' })}>
                  <View style={styles.tableCellTextWrapper}><Text style={styles.tableCell}>{confirmation.street}</Text></View>
                  <View style={styles.tableCellStatusIconWrapper}>{TextStatusIcon(confirmation.result_street)}</View>
                </View>
                <View style={Object.assign({}, styles.tableCol, { width: '6%' })}>
                  <Text style={styles.tableCell}>{confirmation.error_code}</Text>
                </View>
                <View style={Object.assign({}, styles.tableCol, { width: '10%', borderRight: 0 })}>
                  <Text style={styles.tableCell}>{confirmation.date}</Text>
                </View>
              </View>
            )
          })}
        </View>
        <View style={styles.statusLegendMainWrapper}>
          <View style={styles.statusLegendWrapper}>{TextStatusIcon(200)}
            <Text style={styles.statuText}>gültig</Text>
          </View>
          <View style={styles.statusLegendWrapper}>{TextStatusIcon(212)}
            <Text style={styles.statuText}>ungültig</Text>
          </View>
          <View style={styles.statusLegendWrapper}>{TextStatusIcon('A')}
            <Text style={styles.statuText}>stimmt überein</Text>
          </View>
          <View style={styles.statusLegendWrapper}>{TextStatusIcon('B')}
            <Text style={styles.statuText}>stimmt nicht überein</Text>
          </View>
          <View style={styles.statusLegendWrapper}>{TextStatusIcon('C')}
            <Text style={styles.statuText}>nicht angefragt</Text>
          </View>
          <View style={styles.statusLegendWrapper}>{TextStatusIcon('D')}
            <Text style={styles.statuText}>vom EU-Mitgliedstaat nicht mitgeteilt</Text>
          </View>
        </View>
        <View style={{ display: 'flex', flexWrap: 'nowrap' }}>
          <View style={{ width: 250, flexWrap: 'nowrap' }}>
            <Text style={{ fontSize: 9, margin: 10, color: '#084b8e' }}>
              Prüfungsbericht {moment(now).format('DD.MM.YYYY')} um {moment(now).format('HH:mm')} Uhr{"\n"}
              Erstellt für: {company}{"\n"}
              Ansprechpartner: {userName}{"\n"}
              USt-IdNr. gemäß §27 a Umsatzsteuergesetz: {vatID}</Text>
          </View>
          <View style={{ flexWrap: 'nowrap' }}>
            <Text style={{ fontSize: 9, margin: 10, color: '#084b8e' }}>
              Die Abfrage erfolgte durch Nutzung der Schnittstelle, die das Bundeszentralamt für Steuern (BZSt) bereitstellt.{"\n"}
              Das hier dokumentierte Ergebnis ist das Resultat, das die Schnittstelle des BZSt zu dem dokumentierten Zeitpunkt ausgegeben hat.
                            </Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    margin: '5px 0px'
  },
  description: {
    color: 'var(--blue)',
    fontSize: '16px',
    marginLeft: '10px'
  },
  infoBox: {
    background: 'var(--infoBox-background)',
    width: '100%',
    borderRadius: '5px',
    margin: '20px 0px',
    color: 'var(--infoBox-color)',
    display: 'flex',
    flexDirection: 'row',
    padding: '15px 0px'
  },
  pdfButton: {
    marginLeft: 6,
    backgroundColor: 'var(--red)',
    margin: '30px 0px',
    textTransform: 'Uppercase'
  },
}));

export function PreviewSection(props) {
  const classes = useStyles();
  const [base64String, setBase64Data] = React.useState('');
  const handleNext = () => {
    props.handleNext(base64String);
  }

  return (
    <>
      <h3 className={classes.title}>Vorschau-Ansicht</h3>
      <div className={classes.infoBox}>
        <Search style={{ padding: '10px' }} />
        <span className={classes.description}>
          <b>Vorschau: </b>{props.confirmationList.length} Datensätze aufgelistet. Im nächsten Schritt wird ein revisionssicheres PDF mit einem qualifizierten elektronischen Zeitstempel erstellt, das Sie sicher in Ihrer IT-Infrastruktur speichern können.</span>
      </div>

      {props.isGeneratedPDFView && (
        <>
          <PDFViewer width={'100%'} height={400}>
            <PDFView {...props} confirmationList={props.confirmationList} />
          </PDFViewer>
          <PDFDownloadLink document={<PDFView confirmationList={props.confirmationList} />} fileName="PDF">
            {
              (data) => {
                if (data && data.blob) {
                  const reader = new FileReader();
                  reader.readAsDataURL(data.blob);
                  reader.onloadend = function () {
                    const bs64String = reader.result.substr(reader.result.indexOf(',') + 1);
                    setBase64Data(bs64String);
                  }
                }

                return (
                  data.loading ? 'Loading document...' :
                    <Button style={{ display: 'none' }}></Button>
                )
              }
            }
          </PDFDownloadLink>
        </>
      )}
      <div>
        <div style={{ width: '100%', flexDirection: 'row', justifyContent: 'flex-end', display: 'flex', paddingTop: 20, paddingBottom: 14 }}>
          <Button style={{ margin: '30px 0px' }} variant="text" color="primary" onClick={props.handleBack}>
            Zurück {/* @TODO Translate */}
          </Button>
          <Button className={classes.pdfButton} variant="contained" color="primary" onClick={handleNext}>
            Revisionssicheres PDF erzeugen {/* @TODO Translate */}
          </Button>
        </div>
      </div>
    </>

  );
}
