import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

class SelectList extends React.Component {
  state = {
    data: []
  }

  async componentWillReceiveProps(nextProps) {
    this.setOptionsData(nextProps.data, nextProps.allocatedDatas);
  }

  async componentDidMount() {
    this.setOptionsData(this.props.data, this.props.allocatedDatas);
  }

  setOptionsData = async (data, allocatedDatas) => {
    const transformed = [];
    if (data.length > 0) {
      const iterator = await Object.keys(data[0]);
      await iterator.map(value => {
        transformed.push({
          disabled: allocatedDatas && allocatedDatas.includes(value),
          value: value,
          label:
            value +
            ' (' +
            data.filter(el => el.value === el.value).length +
            ') ' +
            ' / ' +
            data.filter(el => el.value === el.value)[0][value] +
            '',
        });
      });
      this.setState({ data: transformed });
    }
  }

  render() {
    return (
      <div style={{ padding: 0, backgroundColor: 'rgba(0, 0, 0, 0.04)', borderRadius: 4, margin: 10, marginLeft: 0, fontWeight: 'bold' }}>
        <Select
          options={this.state.data}
          styles={customStyles}
          onChange={this.props.onChange}
          isClearable={true}
          selected={this.props.selected}
          placeholder="Bitte auswählen"
          isOptionDisabled={(option) => option.disabled}
          /> {/* @TODO Translate: Please choose */}
      </div>
    );
  }
}

SelectList.propTypes = {
  data: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool || false,
};

export default SelectList;

const customStyles = {
  control: base => ({
    ...base,
    height: 45,
    minHeight: 45,
  }),
};
