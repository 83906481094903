import React from 'react';
import ImporterComponent from './importerComponent';
import { Title } from 'react-admin';
import { Card, Typography, Grid } from '@material-ui/core';
const Importer = props => {
  return (
    <>
      <Title title="Mehrfach-Anfrage" />
      <Card style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
        <Grid lg={8} xs={12} container>
          <Grid item lg={12} style={{ padding: 40, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
            <Typography variant="h1" align="center" style={{ fontSize: 32, fontWeight: 'bold', textAlign: 'center' }}>
              Mehrfach-Anfrage
            </Typography> {/* @TODO Translate: Multiple requests */}
          </Grid>
          <Grid item lg={12}>
            <ImporterComponent {...props} />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};
export default Importer;
