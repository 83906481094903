import * as React from 'react';
import { TextInput, CreateButton, EditButton, Create, SimpleForm, Edit, SelectInput, regex } from 'react-admin';
import { Typography } from '@material-ui/core';

const validateUstid = regex(/^(DE)?[0-9]{9}$/, 'Muss eine Deutsche USt-IdNr. sein.');

export const LicenseCreate = props => {
  return (
    <Create {...props} title="Kontent">
      {/* @TODO I have no idea what Kontent should mean here. */}
      <SimpleForm redirect="list">
        <Typography variant="h6">Firma</Typography>{/* @TODO Translate: Company */}
        <TextInput source="company_name" label="Firmenname" required />{/* @TODO Translate: Company Name */}
        <TextInput source="ustid" label="USt-IdNr." required validate={validateUstid} />{/* @TODO Translate: VAT registration number */}
        <TextInput source="street" label="Straße" />{/* @TODO Translate: Street */}
        <TextInput source="street_number" label="Hausnummer" />{/* @TODO Translate: House number */}
        <TextInput source="place" label="Ort" />{/* @TODO Translate: City */}
        <TextInput source="postcode" label="Postleitzahl" />{/* @TODO Translate: Zip code */}
        <TextInput source="phone" label="Telefonummer" />{/* @TODO Translate: Phone number */}
        <TextInput source="email" label="E-Mail" />{/* @TODO Translate: e-mail */}
        <TextInput source="fax" label="FAX" />{/* @TODO Translate: Telefax */}
        <Typography variant="h6">Ansprechpartner</Typography>{/* @TODO Translate: Contact person */}
        <TextInput source="firstname" label="Vorname" />{/* @TODO Translate: Forename */}
        <TextInput source="lastname" label="Nachname" />{/* @TODO Translate: Surname */}
        <SelectInput
          source="title"
          label="Anrede" // @TODO Translate: Salutation
          choices={[
            { id: 0, name: 'Keine Angabe' }, // @TODO Translate: not specified
            { id: 1, name: 'Herr' }, // @TODO Translate: Mr.
            { id: 2, name: 'Frau' }, // @TODO Translate: Ms.
          ]}
        />
      </SimpleForm>
    </Create>
  );
};

const LicenseTitle = ({ record }) => {
  return <span>{record ? `Lizenz bearbeiten: ${record.company_name}` : ''}</span>;
};

export const LicenseEdit = props => (
  <Edit title={<LicenseTitle />} {...props}>
    <SimpleForm redirect="list">
      <Typography variant="h6">Firma</Typography>{/* @TODO Translate: Company */}
      <TextInput source="company_name" label="Firmenname" required />{/* @TODO Translate: Company Name */}
      <TextInput source="ustid" label="USt-IdNr." required validate={validateUstid} />{/* @TODO Translate: VAT registration number */}
      <SelectInput
        source="status"
        label="Status"
        required // @TODO Translate: status
        choices={[
          { id: 1, name: 'Aktiv' }, // @TODO Translate: active
          { id: 2, name: 'Gesperrt' }, // @TODO Translate: restricted
        ]}
      />
      <TextInput source="street" label="Straße" />{/* @TODO Translate: Street */}
      <TextInput source="street_number" label="Hausnummer" />{/* @TODO Translate: House number */}
      <TextInput source="place" label="Ort" />{/* @TODO Translate: City */}
      <TextInput source="postcode" label="Postleitzahl" />{/* @TODO Translate: Zip code */}
      <TextInput source="phone" label="Telefonummer" />{/* @TODO Translate: phone number */}
      <TextInput source="email" label="E-Mail" />{/* @TODO Translate: e-mail */}
      <TextInput source="fax" label="FAX" />{/* @TODO Translate: Telefax */}
      <Typography variant="h6">Ansprechpartner</Typography>{/* @TODO Translate: Contact person */}
      <TextInput source="firstname" label="Vorname" />{/* @TODO Translate: Forename */}
      <TextInput source="lastname" label="Nachname" />{/* @TODO Translate: Surname */}
      <SelectInput
        source="title"
        label="Anrede" // @TODO Translate: Salutation
        choices={[
          { id: 0, name: 'Keine Angabe' }, // @TODO Translate: not specified
          { id: 1, name: 'Herr' }, // @TODO Translate: Mr.
          { id: 2, name: 'Frau' }, // @TODO Translate: Ms.
        ]}
      />
    </SimpleForm>
  </Edit>
);
