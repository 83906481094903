export const importer = {
    REQUEST_SINGLE_REQUEST_INPUT: 'REQUEST_SINGLE_REQUEST_INPUT',
    RECEIVE_SINGLE_REQUEST_INPUT: 'RECEIVE_SINGLE_REQUEST_INPUT',
    RECEIVE_SINGLE_REQUEST_INPUT_FAILURE: 'RECEIVE_SINGLE_REQUEST_INPUT_FAILURE',

    REQUEST_SAVE_CONFIRMATION: 'REQUEST_SAVE_CONFIRMATION',
    RECEIVE_SAVE_CONFIRMATION: 'RECEIVE_SAVE_CONFIRMATION',
    RECEIVE_SAVE_CONFIRMATION_FAILURE: 'RECEIVE_SAVE_CONFIRMATION_FAILURE',

    REQUEST_IMPORT: 'REQUEST_IMPORT',
    RECEIVE_IMPORT: 'RECEIVE_IMPORT',
    RECEIVE_IMPORT_FAILURE: 'RECEIVE_IMPORT_FAILURE'
}

export default importer;