import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ChipField,
  Filter,
  TextInput,
  ShowButton,
  CreateButton,
  SimpleShowLayout,
  Show,
  SelectField,
  ReferenceField,
  EditButton,
} from 'react-admin';

const PostFilter = props => (
  <Filter {...props}>
    <TextInput label="Suche..." source="q" alwaysOn />
  </Filter>
);

export const LicenseList = props => (
  <List {...props} title="Lizenzen" filters={<PostFilter />} bulkActionButtons={false}>
    <Datagrid>
      <ChipField source="ustid" label="USt-IdNr." size="small" variant="outlined" /> {/* @TODO Translate: VAT registration number  */}
      <SelectField
        source="status"
        label="Status"
        choices={[
          { id: 1, name: 'Aktiv' }, // @TODO Translate: active
          { id: 2, name: 'Gesperrt' }, // @TODO Translate: restricted
        ]}
      />
      <TextField source="company_name" label="Firma" /> {/* @TODO Translate: Company  */}
      <ChipField source="key" label="Produktschlüssel" size="small" variant="default" /> {/* @TODO Translate: product key */}
      <DateField source="created_at" label="erstelltt am" /> {/* @TODO Translate: created at */}
      <ShowButton resource="license" />
      <EditButton resource="license" />
    </Datagrid>
  </List>
);

const Headline = props => {
  return <h1>{props.record.company_name}</h1>;
};

const PostTitle = ({ record }) => {
  return <span>{record ? `Lizenz: ${record.company_name}` : ''}</span>;
  /* { */
  /* @TODO Translate: license  */
  /* } */
};

export const LicenseShow = props => (
  <>
    <Show title={<PostTitle />} {...props} filters={<PostFilter />}>
      <SimpleShowLayout>
        <Headline {...props} label="Firma" /> {/* @TODO Translate: Company  */}
        <ChipField source="ustid" label="USt-IdNr." size="small" variant="outlined" /> {/* @TODO Translate: VAT registration number  */}
        <SelectField
          source="status"
          label="Status" // status
          choices={[
            { id: 1, name: 'Aktiv' }, // @TODO Translate: active
            { id: 2, name: 'Gesperrt' }, // @TODO Translate: restricted
          ]}
        />
        <ChipField source="key" label="Produktschlüssel" size="small" variant="default" /> {/* @TODO Translate: product key */}
        <DateField source="created_at" label="erstelltt am" /> {/* @TODO Translate: created at */}
      </SimpleShowLayout>
    </Show>
  </>
);
