import * as React from 'react';
import { AppBar } from 'react-admin';
import { Box, Typography, Button } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MyMenu from './MyMenu';

export const MyAppBar = props => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = React.useState(false);
  return (
    <AppBar
      {...props}
      userMenu={<MyMenu />}
      style={{ backgroundColor: 'linear-gradient(to left, green 0%, #fff 100%)', marginBottom: 124 }}
    >
      <Box flex="1">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6" id="react-admin-title"></Typography>
        </div>
      </Box>
    </AppBar>
  );
};
