import { createBrowserHistory } from 'history';

// Get token from local storage.
export const getToken = () => {
	try {
		const userdetail = localStorage.getItem('auth');
		return {
			authorizationHeader: userdetail ? JSON.parse(userdetail).access_token : null
		};
	} catch (e) {
		console.error(`Could not get Token from localStorage for authorization: ${e}`);
	}
};

// Get configuration to set headers.
export const getConfig = () => {
	return {
		method: null,
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + getToken().authorizationHeader || null
		}
	}
}

// Handled response after fetching APIS and throws error while failure.
export const handleResponse = response => {
	if (response.status === 400 || response.status === 403 || response.status === 404 || response.status === 500) {
		return new Error(response);
	} else if (response.status >= 200 && response.status <= 299) {
		return response.data;
	}
};

// Handled and display error while failure. if unauthorized, navigate to login.
export const handleError = (error, dispatch, fn, messageString) => {
	if (error.response && error.response.status === 401) {
		const history = createBrowserHistory({ forceRefresh: true });
		history.push('/login');
		localStorage.removeItem('auth');

		return dispatch(fn(messageString));
	}

	let errorMessage = '';
	if (error.response) {
		errorMessage = error.response.data.errors;
	}

	return dispatch(fn(errorMessage));
};
