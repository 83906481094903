import { importer as T } from './constants';
import { updateObject } from '../../redux-utils/updateObject';
import { defaultStore } from '../../reducers/store';

const importerReducer = (state = defaultStore, action) => {
	switch (action.type) {
		case T.REQUEST_SINGLE_REQUEST_INPUT:
			return updateObject(state, {
				importerResponse: {
					...state.importerResponse,
					loading: true,
					evatRequestError: false,
					evatRequestErrorMessage: null
				},
			});

		case T.RECEIVE_SINGLE_REQUEST_INPUT:
			return updateObject(state, {
				importerResponse: {
					...state.importerResponse,
					loading: false,
					evatRequestError: false,
					evatRequestErrorMessage: null,
					evatRequestResponse: action.response
				},
			});

		case T.RECEIVE_SINGLE_REQUEST_INPUT_FAILURE:
			return updateObject(state, {
				importerResponse: {
					...state.importerResponse,
					loading: false,
					evatRequestError: true,
					evatRequestErrorMessage: action.error,
				},
			});

		case T.REQUEST_IMPORT:
			return updateObject(state, {
				import: {
					...state.import,
					loading: true,
					importError: false,
					importErrorMessage: null
				},
			});

		case T.RECEIVE_IMPORT:
			return updateObject(state, {
				import: {
					...state.import,
					loading: false,
					importError: false,
					importErrorMessage: null,
					importResponse: action.response
				},
			});

		case T.RECEIVE_IMPORT_FAILURE:
			return updateObject(state, {
				import: {
					...state.import,
					loading: false,
					importError: true,
					importErrorMessage: action.error,
				},
			});
		
		case T.REQUEST_SAVE_CONFIRMATION:
			return updateObject(state, {
				confirmationSaveResponse: {
					...state.confirmationSaveResponse,
					loading: true,
					confirmationSaveError: false,
					confirmationSaveErrorMessage: null
				},
			});

		case T.RECEIVE_SAVE_CONFIRMATION:
			return updateObject(state, {
				confirmationSaveResponse: {
					...state.confirmationSaveResponse,
					loading: false,
					confirmationSaveError: false,
					confirmationSaveErrorMessage: null,
					confirmationSaveResponse: action.response
				},
			});

		case T.RECEIVE_SAVE_CONFIRMATION_FAILURE:
			return updateObject(state, {
				confirmationSaveResponse: {
					...state.confirmationSaveResponse,
					loading: false,
					confirmationSaveError: true,
					confirmationSaveErrorMessage: action.error,
				},
			});

		default:
			return updateObject(state, {});
	}
}

export default importerReducer;