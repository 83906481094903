import * as React from 'react';
import { UserMenu, MenuItemLink } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';

const ConfigurationMenu = React.forwardRef(({ onClick, props }, ref) => {
  const user = JSON.parse(localStorage.getItem('auth'));
  const label = (user === null) ? 'Mein Profil' : user.user.email;
  return (
    <MenuItemLink
      ref={ref}
      to="/my-profile"
      primaryText={label}
      leftIcon={<SettingsIcon />}
      onClick={onClick} // close the menu on click
    />
  );
});

const MyCustomIcon = () => {
  return <></>;
};

const MyMenu = props => {
  const user = JSON.parse(localStorage.getItem('auth'));
  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
      <UserMenu {...props} label={user && user.user.email}>
        <ConfigurationMenu {...props} />
      </UserMenu>
    </div>
  );
};
export default MyMenu;
