import React, { Component } from 'react';
import { Switch, Typography, Grid } from '@material-ui/core';
import FakeInput from '../fakeInput';
import SelectList from '../SelectList';

class Sorter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expert: true,
      data: false,
      ustid: '',
    };
  }

  handleChange() {
    this.setState({ expert: !this.state.expert }); // TOGGLE
  }

  componentDidMount() {
    const data = JSON.parse(sessionStorage.getItem('importer'));
    this.setState({ data: data });
  }

  render() {
    return (
      <>
        {!this.state.data ? (
          <div>Loading... {/* @TODO Translate to german: Lädt... */}</div>
        ) : (
          <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }}>
            <Typography variant="h2" align="center">
              Zuordnung Datenfelder {/* @TODO Translate: Field mapping */}
            </Typography>
            <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Switch
                checked={this.state.expert}
                onChange={() => this.handleChange()}
                name="expert"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <p>Qualifizierte Abfrage</p>
              {/* @TODO Translate: Extended requests */}
            </div>
            <Grid container>
              <Grid container md={12} direction="row">
                <Grid item md={6}>
                  <FakeInput name="UstId." isRequired={true} />
                </Grid>
                <Grid item md={6}>
                  <SelectList name="" data={this.state.data} onChange={item => console.log(item)} />
                </Grid>
              </Grid>

              {this.state.expert ? (
                <>
                  <div className="separator">Qualifizierte Abfrage</div>
                  {/* @TODO Translate: Extended request */}
                  <Grid container md={12} direction="row">
                    <Grid item md={6}>
                      <FakeInput name="Test1234" isRequired={true} />
                    </Grid>
                    <Grid item md={6}>
                      <SelectList name="" data={this.state.data} onChange={item => console.log(item)} />
                    </Grid>
                  </Grid>

                  <Grid container md={12} direction="row">
                    <Grid item md={6}>
                      <FakeInput name="Test1234" />
                    </Grid>
                    <Grid item md={6}>
                      <SelectList name="" data={this.state.data} onChange={item => console.log(item)} />
                    </Grid>
                  </Grid>

                  <Grid container md={12} direction="row">
                    <Grid item md={6}>
                      <FakeInput name="Test1234" />
                    </Grid>
                    <Grid item md={6}>
                      <SelectList name="" data={this.state.data} onChange={item => console.log(item)} />
                    </Grid>
                  </Grid>

                  <Grid container md={12} direction="row">
                    <Grid item md={6}>
                      <FakeInput name="Test1234" />
                    </Grid>
                    <Grid item md={6}>
                      <SelectList name="" data={this.state.data} onChange={item => console.log(item)} />
                    </Grid>
                  </Grid>

                  <Grid container md={12} direction="row">
                    <Grid item md={6}>
                      <FakeInput name="Test1234" />
                    </Grid>
                    <Grid item md={6}>
                      <SelectList name="" data={this.state.data} onChange={item => console.log(item)} />
                    </Grid>
                  </Grid>
                </>
              ) : null}
            </Grid>
          </div>
        )}
      </>
    );
  }
}

export default Sorter;
