import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import confirmationsCTX from '../confirmationsCTX';
import { PreviewSection } from "./steps/previewSection";
import { DownloadPDF } from './steps/downloadPDF';

const useStyles = makeStyles(theme => ({
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  wrapperClass: {
    margin: '5px 50px'
  }
}));

function getSteps() {
  return ['Vorschau', 'Archivieren']; // @TODO Translate'
}

function pdfViewWizardSingleConfirmation(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const steps = getSteps();

  const handleNextWithBase64 = async (base64) => {
    handleNext();
    await props.requestToSignPDF(base64);
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <div className={classes.wrapperClass}>
          <PreviewSection
            {...props}
            confirmationList={props.list}
            isGeneratedPDFView={props.list}
            handleBack={props.onClose}
            handleNext={handleNextWithBase64} />
        </div>;
      case 1:
        return <div className={classes.wrapperClass}>
          <DownloadPDF {...props} />
        </div>;
      default:
        return 'Unknown stepIndex';
    }
  }

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <div style={{ display: 'flex', justifyContent: 'center' }}>{getStepContent(activeStep)}</div>
    </Grid>
  );
}

// Prop types of props.
pdfViewWizardSingleConfirmation.propTypes = {
  requestToSignPDF: PropTypes.func,
  signPDFLoading: PropTypes.bool,
  signPDFError: PropTypes.bool,
  signPDFErrorMessage: PropTypes.string
}

// Set default props.
pdfViewWizardSingleConfirmation.defaultProps = {
  requestToSignPDF: () => { },
  signPDFError: false,
  signPDFErrorMessage: ''
}

export default compose(confirmationsCTX)(pdfViewWizardSingleConfirmation);
