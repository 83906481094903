import React from 'react';
import { connect } from 'react-redux';

import { getConfirmationList, requestToSignPDF } from '@zfu-confirmations/actions';
import { getConfirmationResponse, getSignPDFResponse } from '@zfu-confirmations/selector';

const HOC = WrappedComponent => {
  const Decorator = props => <WrappedComponent {...props} />;

  const mapStateToProps = state => ({
    confirmationListResponse: getConfirmationResponse(state).confirmationList,
    signPDFResponse: getSignPDFResponse(state).signPDFResponse,
    signPDFLoading: getSignPDFResponse(state).loading,
    signPDFErrorMessage: getSignPDFResponse(state).signPDFErrorMessage,
    signPDFError: getSignPDFResponse(state).signPDFError
  });

  const mapDispatchToProps = {
    getConfirmationList,
    requestToSignPDF
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Decorator);
};

export default HOC;
