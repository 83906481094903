// in src/Menu.js
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, getResources } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import DashBoardIcon from '@material-ui/icons/Dashboard';

export const Menu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  return (
    <div>
      <MenuItemLink to="/" primaryText="Home" leftIcon={<DashBoardIcon />} onClick={onMenuClick} sidebarIsOpen={open} />
      {resources
        .filter(resource => resource.name !== 'import')
        .map(resource => (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={(resource.options && resource.options.label) || resource.name}
            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        ))}
      {isXSmall && logout}
    </div>
  );
};
