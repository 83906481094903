import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import { apiUrl } from '@zfu-utils';
import { confirmation as T } from './constants';
import { handleError, handleResponse, getConfig } from '../../reducers/api';

// Confirmation list
export const requestConfirmationList = () => ({
	type: T.REQUEST_CONFIRMATION_LIST
});

export const receiveConfirmationList = confirmationList => ({
	type: T.RECEIVE_CONFIRMATION_LIST,
	confirmationList
});

export const receiveConfirmationListFailure = error => ({
	type: T.RECEIVE_CONFIRMATION_LIST_FAILURE,
	error
});

/**
 * Confirmation list
 *
 * @return {array} return confirmation list.
*/
export const getConfirmationList = () => dispatch => {
	dispatch(requestConfirmationList());
	const finalUrl = apiUrl + '/confirmations';
	return trackPromise(axios.get(finalUrl, getConfig())
		.then(response => handleResponse(response))
		.then(result => {
			dispatch(receiveConfirmationList(result));
		})
		.catch(error =>
			handleError(error, dispatch, receiveConfirmationListFailure, 'Confirmation')
		));
}

// Request sign pdf
export const requestSignPDF = () => ({
	type: T.REQUEST_SIGN_PDF
});

export const receiveSignPDF = response => ({
	type: T.RECEIVE_SIGN_PDF,
	response
});

export const receiveSignPDFFailure = error => ({
	type: T.RECEIVE_SIGN_PDF_FAILURE,
	error
});

/**
 * Sign pdf
 *
 * @return receive sign in pdf url.
*/
export const requestToSignPDF = (base64Data) => dispatch => {
	dispatch(requestSignPDF());
	const finalUrl = apiUrl + '/confirmations/export-pdf';
	return trackPromise(axios.post(finalUrl, { pdf_data: base64Data }, getConfig())
		.then(response => handleResponse(response))
		.then(result => {
				if (result) {
					dispatch(receiveSignPDF(result));
				}
		})
		.catch(error =>
			handleError(error, dispatch, receiveSignPDFFailure, 'Confirmation PDF')
		));
}
