import * as React from 'react';
import Moment from 'moment';
import qs from 'qs';
import { getStartEndDateByDuration } from '../helper';
import { DurationEnum } from '../Pdfview-modal/DurationEnum';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import {
  SelectInput,
  DateInput,
  crudUpdateMany
} from 'react-admin';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}));

const DurationSelection = (props) => {
  const classes = useStyles();

  const [startDate, setStartDate] = React.useState(Moment());
  const [endDate, setEndDate] = React.useState(Moment());
  const parsedQueryParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  const filters = parsedQueryParams && parsedQueryParams.filter ? JSON.parse(parsedQueryParams.filter) : {};

  const onSelectDuration = (event) => {
    const duration = getStartEndDateByDuration(event.target.value);
    setStartDate(duration.startDate);
    setEndDate(duration.endDate);

    if (event.target.value !== DurationEnum.CHOOSE_INDIVIDUAL) {
      props.setFilters({
        duration: event.target.value,
        start_date: '',
        end_date: '',
        import_id: filters.import_id ? filters.import_id : '',
        is_valid: filters.is_valid ? filters.is_valid : '',
        is_invalid: filters.is_invalid ? filters.is_invalid : ''
      });
    }
  }

  const choiceArray = [
    { id: DurationEnum.CURRENT_MONTH, name: 'Aktueller Monat' },
    { id: DurationEnum.CURRENT_YEAR, name: 'Aktuelles Jahr' },
    { id: DurationEnum.PREVIOUS_MONTH, name: 'Vormonat' },
    { id: DurationEnum.PREVIOUS_YEAR, name: 'Vorjahr' },
    { id: DurationEnum.CHOOSE_INDIVIDUAL, name: 'Individueller Zeitraum...' }
  ];

  return (
    <>
      <FormControl variant="filled" style={{
        marginTop: 0,
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 0
      }} className={classes.formControl} size="small">
        <SelectInput
          label="Zeitraum"
          source="duration"
          choices={choiceArray}
          allowEmpty
          emptyText={'Gesamter Zeitraum'}
          onChange={onSelectDuration}
        />
        {filters.duration === DurationEnum.CHOOSE_INDIVIDUAL && (
          <>
            <DateInput style={{
              marginRight: '10px',
              marginLeft: '10px'
            }}
              source="start_date"
              defaultValue={Moment(startDate).format('YYYY-MM-DD')}
              label="Datum von" />
            <DateInput source="end_date"
              defaultValue={Moment(endDate).format('YYYY-MM-DD')}
              label="Datum bis" />
          </>
        )}
      </FormControl>
    </>
  )
}

export default connect(undefined, { crudUpdateMany })(DurationSelection);
