import React, { Component } from 'react';
import { Card, Typography, Grid } from '@material-ui/core';
import Inputs from './singleRequestInputs';
import { Title } from 'react-admin';
class SingleRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Title title="Einzel-Anfrage" /> {/* @TODO Translate: Single request */}
        <Card style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
          <Grid lg={8} xs={12} container>
            <Grid item lg={12} style={{ padding: 40, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
              <Typography variant="h1" align="center" style={{ fontSize: 32, fontWeight: 'bold', textAlign: 'center' }}>
                Einzel-Anfrage
              </Typography> {/* @TODO Translate: Single request */}
            </Grid>
            <Grid item lg={12}>
              <Inputs />
            </Grid>
          </Grid>
        </Card>
      </>
    );
  }
}

export default SingleRequest;
