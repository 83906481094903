import * as React from 'react';
import classNames from 'classnames';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  TextInput,
  ShowButton,
  Pagination,
  useListContext,
  DataProviderContext
} from 'react-admin';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Tooltip from '@material-ui/core/Tooltip';
import { Buttons } from './createButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Warning from '@material-ui/icons/Warning';
import { TextFieldColor, StatusCode, StatusCodeDetails, ListActions, filterdConfirmationData } from './helper';
import { Crypt } from 'hybrid-crypto-js';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import Dexie from 'dexie';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { compose } from 'redux';
import confirmationsCTX from './confirmationsCTX';
import PropTypes from 'prop-types';
import PDFViewModal from './Pdfview-modal/pdfviewModal';
import DurationSelection from './FilterFields/DurationSelection';
import ImportSelect from './FilterFields/ImportSelection';
import { PermissionEnum } from '../../../shared/enums/PermissionEnum';
import PdfViewStepWizard from './Pdfview-modal/pdfViewStepWizard';
import jsonExport from 'jsonexport/dist';
import Papa from 'papaparse';
import qs from 'qs';

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 250]} {...props} />;

const db = new Dexie('zfu');
db.version(1).stores({
  confirmations:
    '++id, ustid1, ustid2, name, error_code, street, place, postcode, date, time, result_name, result_place, result_postcode, result_street, valid_from, valid_to, print, import_id, license_id, user_id, created_at, updated_at',
});

const useStyles = makeStyles(theme => (
  {
    statusWrapper: {
      display: 'flex'
    },
    filterWrapper: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 0,
      alignItems: 'flex-start'
    },
    filters: {
      display: 'flex',
      flexDirection: 'row'
    },
    status: {
      background: 'var(--infoBox-background)',
      padding: '0px 10px 0px 10px',
      borderRadius: '30px',
      cursor: 'pointer'
    },
    statusIcon: {
      fontSize: '20px',
      marginTop: '5px',
      marginRight: '5px'
    },
    selectedStatusBg: {
      background: 'var(--blue)',
    },
    disabled: {
      cursor: 'not-allowed'
    }
  }
));

const privateKey = localStorage.getItem('privateKey');

const setConfirmationData = async (confirmationListResponse) => {
  const json = confirmationListResponse;
  const crypt = new Crypt();
  db.transaction('rw', db.confirmations, async () => {
    const confirmationListData = await db.confirmations.toArray();
    const addedEntries = json ? json.filter(o1 => !confirmationListData.some(o2 => o1.id === o2.id)) : [];
    if (addedEntries) {
      await addedEntries.map(async (value, key) => {
        if (await db.confirmations.where({ id: value.id })) {
          const decrypted = await crypt.decrypt(privateKey, value.data);
          const message = JSON.parse(decrypted.message);

          // IF NEW DATA ADD TO OFFLINE DATABASE
          await db.confirmations.put({
            id: value.id,
            ustid1: message.ustid1,
            ustid2: message.ustid2,
            name: message.name,
            error_code: message.errorCode,
            street: message.street,
            place: message.place,
            postcode: message.postcode,
            date: message.date,
            time: message.time,
            result_name: message.result_name,
            result_place: message.result_place,
            result_postcode: message.result_postcode,
            result_street: message.result_street,
            valid_from: message.valid_from,
            valid_to: message.valid_to,
            print: message.print,
            import_id: value.import_id,
            license_id: value.license_id,
            user_id: value.user_id,
            created_at: value.created_at,
            updated_at: value.updated_at,
          });
        }
      });
    }

    const removedEntries = confirmationListData.filter(o1 => !confirmationListResponse.some(o2 => o1.id === o2.id));
    const removedIds = removedEntries ? removedEntries.map((obj) => obj.id) : [];

    db.confirmations.bulkDelete(removedIds);
  }).catch(e => {
    console.error(e.stack || e);
  });
}

const PostFilter = props => {
  const [validStatusCount, setValidStatusCount] = React.useState(null);
  const [inValidStatusCount, setInValidStatusCount] = React.useState(null);
  const [validStatus, setValidStatus] = React.useState(false);
  const [inValidStatus, setInValidStatus] = React.useState(false);

  const parsedQueryParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  const filters = parsedQueryParams && parsedQueryParams.filter ? JSON.parse(parsedQueryParams.filter) : {};

  React.useEffect(() => {
    if (filters) {
      setValidStatus(filters.is_valid ? filters.is_valid : false);
      setInValidStatus(filters.is_invalid ? filters.is_invalid : false);
    }
  }, []);

  const classes = useStyles();
  const data = useListContext();
  filterdConfirmationData(data.currentSort.field, data.currentSort.order, data.filterValues)
    .then((res) => {
      const { totalConfirmations } = res;
      const statusValid = totalConfirmations && totalConfirmations.filter((obj) => obj.error_code === 200).length;
      const statusInvalid = totalConfirmations && totalConfirmations.filter((obj) => obj.error_code !== 200).length;
      setValidStatusCount(statusValid);
      setInValidStatusCount(statusInvalid);
    });

  const setValidStatusFilter = () => {
    if (!inValidStatus) {
      const status = !validStatus;
      setValidStatus(status);
      props.setFilters({
        is_valid: status ? status : '',
        is_invalid: '',
        import_id: filters.import_id ? filters.import_id : '',
        duration: filters.duration ? filters.duration : '',
        start_date: filters.start_date ? filters.start_date : '',
        end_date: filters.end_date ? filters.end_date : ''
      });
    }
  }

  const setInValidStatusFilter = () => {
    if (!validStatus) {
      const status = !inValidStatus;
      setInValidStatus(!inValidStatus);
      props.setFilters({
        is_valid: '',
        is_invalid: status ? status : '',
        import_id: filters.import_id ? filters.import_id : '',
        duration: filters.duration ? filters.duration : '',
        start_date: filters.start_date ? filters.start_date : '',
        end_date: filters.end_date ? filters.end_date : ''
      });
    }
  }

  return (
    <Filter {...props} class="filter-wrapper" className={classes.filterWrapper}>
      <div className={classes.statusWrapper} alwaysOn>
        <Tooltip arrow title={<span className="tooltip">{inValidStatusCount} Datensätze mit möglichem Handlungsbedarf.</span>}>
          <div
            className={classNames([classes.status, { [classes.selectedStatusBg]: inValidStatus }, { [classes.disabled]: validStatus }])}
            style={{ marginRight: '10px' }}
            onClick={setInValidStatusFilter}>
            <Warning className={classes.statusIcon} style={{ color: 'var(--red)' }} />
            <span style={{ verticalAlign: 'super' }}>{inValidStatusCount}</span>
          </div>
        </Tooltip>
        <Tooltip arrow title={<span className="tooltip">{validStatusCount} Datensätze in Ordnung.</span>}>
          <div
            className={classNames([classes.status, { [classes.selectedStatusBg]: validStatus }, { [classes.disabled]: inValidStatus }])}
            onClick={setValidStatusFilter} >
            <CheckCircleIcon className={classes.statusIcon} style={{ color: 'var(--status-green)' }} />
            <span style={{ verticalAlign: 'super' }}>{validStatusCount}</span>
          </div>
        </Tooltip>
      </div>
      <div className={classes.filters} alwaysOn>
        <TextInput label="Suche..." source="q" alwaysOn />
        <DurationSelection {...props} alwaysOn />
        <ImportSelect {...props} alwaysOn />
      </div>
    </Filter>
  );
};

const ConfirmationsList = props => {
  const privateKey = localStorage.getItem('privateKey');
  const [state, setstate] = React.useState(null);
  const [showPDFModal, setPDFModal] = React.useState(false);
  const dataProvider = React.useContext(DataProviderContext);
  const [user, setUser] = React.useState([]);

  React.useEffect(async () => {
    db.transaction('rw', db.confirmations, async () => {
      const confirmation = await db.confirmations.count();
      setstate(confirmation);
    }).catch(e => {
      console.error(e.stack || e); // ERROR MESSAGE CONSOLE
    });

    if (!privateKey && state === 0) {
      return <Redirect to="/my-profile" />;
    }

    const { getConfirmationList } = props;
    await getConfirmationList();

    dataProvider.getOne('confirmationUserList')
      .then(({ data }) => {
        setUser(data);
      })
      .catch(error => {
        console.error(error);
      })
  }, []);

  const UserNameTextField = (data) => {
    const selectedUser = user && user.find((obj) => obj.id === data.record.user_id);
    if (selectedUser) {
      if (props.permissions === PermissionEnum.USER) {
        return (<span style={{
          color: 'var(--blue)',
          textDecoration: 'none'
        }}>{selectedUser.email}</span>);
      }
      return (<Link style={{
        color: 'var(--blue)',
        textDecoration: 'none'
      }} to={`user/${selectedUser.id}/show`}>{selectedUser.email}</Link>);
    }
    return <></>;
  };

  if (props.confirmationListResponse) {
    setConfirmationData(props.confirmationListResponse);
  }

  const onClickOnPdfView = () => {
    setPDFModal(true);
  }

  const dataExport = (posts) => {
    jsonExport(posts, null, (err, csv) => {
        if (csv) {
            Papa.parse(csv, {
              header: true,
              skipEmptyLines: 'greedy',
              complete: downloadFile,
            });
          }
    });
  }

  const downloadFile = (posts) => {
    if (posts && posts.data) {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(posts.data);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'confirmations' + fileExtension);
    }
  };

  return (
    <>
      {privateKey ? (
        ''
      ) : (
        <Alert variant="filled" severity="error">
          privater Schlüssel konnte nicht gefunden werden, die Sync Funktion und neue Anfragen sind abgeschaltet. {/* @TODO Translate: The private key could not be found. The synchronisation and new requests have been turned off. */}
          <br></br>
          <Link style={{ color: 'var(--white)', textDecoration: 'none', fontWeight: 'bolder' }} to="/my-profile">
            klicke hier um dein Schlüssel hochzuladen.
          </Link> {/* @TODO Translate: Click here to upload your key. */}
        </Alert>
      )}
      {props.confirmationListResponse && (
        <List
          {...props}
          empty={<Buttons />}
          actions={<ListActions onClickOnPdfView={onClickOnPdfView} />}
          title="Bestätigungen"
          filters={<PostFilter {...props} />}
          pagination={<PostPagination />}
          sort={{ field: 'id', order: 'DESC' }}
          exporter={dataExport}
          bulkActionButtons={false}
        >
          {/* @TODO Translate: Confirmations */}
          <Datagrid>
            <TextField source="ustid2" label="USt-IdNr." />
            {/* @TODO Translate: VAT registration number */}
            <StatusCode {...props} />
            <TextField source="name" label="Name" />
            {/* @TODO Translate: Name */}
            <TextFieldColor source="result_name" label="" />
            <TextField source="place" label="Ort" />
            {/* @TODO Translate: City */}
            <TextFieldColor source="result_place" label="" />
            <TextField source="postcode" label="Postleitzahl" />
            {/* @TODO Translate: ZIP code */}
            <TextFieldColor source="result_postcode" label="" />
            <TextField source="street" label="Straße & Nr." />
            {/* @TODO Translate: Street and Housenumber */}
            <TextFieldColor source="result_street" label="" />
            <StatusCodeDetails source="error_code" label="Status Code" />
            {/* @TODO Translate: Status Code */}
            <DateField source="created_at" label="Datum" />
            {/* @TODO Translate: Date */}
            <UserNameTextField label="Benutzer" />
            <ShowButton resource="confirmations" {...props} />
          </Datagrid>
        </List>
      )}
      {showPDFModal && (
        <PDFViewModal {...props} open={showPDFModal} handleClose={() => setPDFModal(false)}>
          <PdfViewStepWizard {...props} />
        </PDFViewModal>
      )}
    </>
  );
};

// Prop types of props.
ConfirmationsList.propTypes = {
  getConfirmationList: PropTypes.func,
  confirmationListResponse: PropTypes.array
}

// Set default props.
ConfirmationsList.defaultProps = {
  getConfirmationList: () => { },
  confirmationListResponse: []
}

export default compose(confirmationsCTX)(ConfirmationsList);
