import * as React from "react";
import saveFileToDisk from "./SaveFileToDisk";
import { Button } from "@material-ui/core";
import { authProvider } from "../../../provider";

export default function DownloadedKeyMessage(props) {
  const redirectToLogin = function () {
    authProvider.logout();
    window.location.href = '/login';
  }
  return (
    <div style={{ padding: "1em", maxWidth: "40em" }}>
      <h1>Ihr privater Schlüssel wird sicher bei Ihnen gespeichert.</h1>
      <p>Bitte bewahren Sie Ihren privaten Schlüssel gut auf!</p>
      <p>Sie benötigen den privaten Schlüssel um die Resultate Ihrer Abfragen entschlüsseln zu können!</p>

      <p>
        <Button onClick={() => saveFileToDisk(props.filename + '.pem')}
                variant="outlined"
                color="primary"
        >
          Erneut herunterladen
        </Button>
        &nbsp;
        <Button onClick={redirectToLogin}
                variant="contained"
                color="primary"
                type="submit"
        >
          Verstanden! Weiter zum Login
        </Button>
      </p>
    </div>
  );
};
