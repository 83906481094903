import React, { Component, useState, useRef } from 'react';
import { Button } from '@material-ui/core';

const FileReader = props => {
  const [uploadedFile, setUploadedFile] = useState(null);

  const handleChange = event => {
    const file = event.target.files[0];
    setUploadedFile(file);
  };

  const hiddenFileInput = useRef(null);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  return (
    <div className="App" style={{ width: '80%' }}>
      <div className="inputFileWrapper">
        <input type="file" name="file" ref={hiddenFileInput} placeholder={null} onChange={handleChange} accept=".csv, .xlsx, .xls" />
        <Button
          variant="contained"
          color="primary"
          style={{ whiteSpace: 'nowrap', paddingLeft: 28, paddingRight: 28 }}
          onClick={handleClick}
        >
          Datei wählen {/* @TODO Translate: Choose file */}
        </Button>
      </div>
      <div className="infoFile">Mögliches Datenformat: CSV, XLSX {/* @TODO Translate: Allowed file formats: */}</div>
      <div style={{ width: '100%', flexDirection: 'row', justifyContent: 'flex-end', display: 'flex', paddingTop: 20, paddingBottom: 14 }}>
        <Button variant="contained" color="primary" disabled={!uploadedFile} onClick={() => props.nextStep(uploadedFile)}>
          Hochladen {/* @TODO Translate: Upload */}
        </Button>
      </div>
    </div>
  );
};

export default FileReader;
