import { confirmation as T } from './constants';
import { updateObject } from '../../redux-utils/updateObject';
import { defaultStore } from '../../reducers/store';

const confirmationReducer = (state = defaultStore, action) => {
	switch (action.type) {
		case T.REQUEST_CONFIRMATION_LIST:
			return updateObject(state, {
				confirmationListResponse: {
					...state.confirmationListResponse,
					loading: true,
					confirmationError: false,
					confirmationErrorMessage: null
				},
			});

		case T.RECEIVE_CONFIRMATION_LIST:
			return updateObject(state, {
				confirmationListResponse: {
					...state.confirmationListResponse,
					loading: false,
					confirmationError: false,
					confirmationErrorMessage: null,
					confirmationList: action.confirmationList
				},
			});

		case T.RECEIVE_CONFIRMATION_LIST_FAILURE:
			return updateObject(state, {
				confirmationListResponse: {
					...state.confirmationListResponse,
					loading: false,
					confirmationError: true,
					confirmationErrorMessage: action.error,
				},
			});

		case T.REQUEST_SIGN_PDF:
				return updateObject(state, {
					signPDF: {
						...state.signPDF,
						loading: true,
						signPDFError: false,
						signPDFErrorMessage: null
					},
				});
	
			case T.RECEIVE_SIGN_PDF:
				return updateObject(state, {
					signPDF: {
						...state.signPDF,
						loading: false,
						signPDFError: false,
						signPDFErrorMessage: null,
						signPDFResponse: action.response.data
					},
				});
	
			case T.RECEIVE_SIGN_PDF_FAILURE:
				return updateObject(state, {
					signPDF: {
						...state.signPDF,
						loading: false,
						signPDFError: true,
						signPDFErrorMessage: action.error,
					},
				});

		default:
			return updateObject(state, {});
	}
}

export default confirmationReducer;