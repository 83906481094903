import React, { Component } from 'react';
import { Card, CardContent, CardHeader, Divider, Button, Snackbar } from '@material-ui/core';
import { Title } from 'react-admin';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Crypt, RSA } from 'hybrid-crypto-js';

class MyProfil extends Component {
  constructor(props) {
    super(props);
    this.hiddenFileInput = React.createRef();
    this.state = {
      file: null,
      user: null,
      errorOne: false,
      errorTwo: false,
      license: null,
      privateKey: localStorage.getItem('privateKey') || false,
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onFormSubmit(e) {
    e.preventDefault(); // Stop form submit
    const reader = new FileReader();
    reader.onload = async e => {
      const privateKeyStr = e.target.result;
      var crypt = new Crypt();
      const auth = await JSON.parse(localStorage.getItem('auth'));
      const publicKey = auth.license.publickey;
      const checkOne = privateKeyStr.includes('BEGIN RSA PRIVATE KEY') || false;
      const checkTwo = privateKeyStr.includes('END RSA PRIVATE KEY') || false;
      if (checkOne && checkTwo) {
        var encrypted = await crypt.encrypt(publicKey, 'testString1234');
        try {
          var decrypted = await crypt.decrypt(privateKeyStr, encrypted);
          localStorage.setItem('privateKey', privateKeyStr);
          window.location.reload();
        } catch (e) {
          this.setState({ errorOne: true });
        }
      } else {
        this.setState({ errorTwo: true });
      }
    };
    reader.readAsText(this.state.file);
  }

  handleCloseOne = () => {
    this.setState({ errorOne: false });
  };

  handleCloseTwo = () => {
    this.setState({ errorTwo: false });
  };

  onChange(e) {
    this.setState({ file: e.target.files[0] });
  }

  async componentDidMount() {
    const auth = await JSON.parse(localStorage.getItem('auth'));
    const authenticatedUser = (auth !== null)
      ? auth.user
      : null
    ;
    const license = (auth !== null)
      ? auth.license
      : null
    ;
    const privateKey = await localStorage.getItem('privateKey');
    this.setState({ user: authenticatedUser, license: license });
  }

  handleClick = event => {
    this.hiddenFileInput.current.click();
  };

  downloadTxtFile = () => {
    const element = document.createElement('a');
    const file = new Blob([this.state.privateKey], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = this.state.license.key + '.pem';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  userInfo = () => {
    return (
      <div>
        <h2>Benutzer</h2>
        <p>
          E-Mail: <b>{this.state.user.email}</b>
        </p> {/* @TODO Translate: E-Mail */}
        <p>
          Rolle: <b>{this.state.user.roles[0].name}</b>
        </p> {/* @TODO Translate: Role */}
      </div>
    );
  };

  companyInfo = () => {
    return (
      <>
        <h2>Firma</h2> {/* @TODO Translate: Company */}
        <p>
          Firmenname: <b>{this.state.license.company_name}</b>
        </p> {/* @TODO Translate: Name of the company */}
        <p>
          USt-IdNr.: <b>{this.state.license.ustid}</b>
        </p> {/* @TODO Translate: VAT registration number */}
        <p>
          Ansprechpartner:
          <b>
            {this.state.license.firstname} {this.state.license.lastname}
          </b>
        </p> {/* @TODO Contact person */}
        <p>
          Firmenadresse:
          <b>
            {this.state.license.street} {this.state.license.street_number}, {this.state.license.postcode} {this.state.license.place}
          </b>
        </p> {/* @TODO Translate: Company adress */}
      </>
    );
  };

  licenceInfo = () => {
    return (
      <>
        <h2>Lizenz</h2> {/* @TODO Translate: License. */}
        <p>
          Lizenzkey: <b>{this.state.license.key}</b>
        </p> {/* @TODO Translate: Licence key. */}
        <p>
          Lizenzstatus: <b>{this.state.license.status === 1 ? 'Gültig' : 'nicht Gültig'} </b>
        </p> {/* @TODO Translate: License status */}
        <p>
          privater Schlüssel: <b>{this.state.privateKey ? 'vorhanden' : 'nicht vorhanden'}</b>
        </p> {/* @TODO Translate: Private key. */}
      </>
    );
  };

  render() {
    return (
      <>
        {this.state.privateKey ? (
          ''
        ) : (
          <div style={{ marginBottom: 8 }}>
            <Alert variant="filled" severity="error">
              privater Schlüssel konnte nicht gefunden werden, die Sync Funktion und neue Anfragen sind
              abgeschaltet. {/* @TODO Translate: The private key could not be found. The synchronisation and new requests have been turned off. */}
            </Alert>
          </div>
        )}
        <Title title="Mein Konto"/>
        <Card>
          {this.state.user ? (
            <CardContent>
              <h1>Mein Konto</h1> {/* @TODO Translate: My account */}
              <h2>Privater Schlüssel</h2>
              {this.state.privateKey ? (
                <div>

                  {/* @TODO Translate: Download Key. */}
                  <p>
                    <Button variant="contained" color="primary" onClick={() => this.downloadTxtFile()}>
                      Schlüssel herunterladen {/* @TODO Translate: Download Key. */}
                    </Button>
                  </p>
                </div>
              ) : (
                <>

                  <form onSubmit={this.onFormSubmit}>
                    <p>
                      <Alert severity="info" style={{ marginBottom: '1em' }}>
                        Dein Schlüssel hat den Namen deines Lizenzkeys, die Dateiendung ist vom Typ
                        .pem {/* @TODO Translate: Your key file has the name of your license and the file extension .pem. */}
                      </Alert>
                      <div className="inputFileWrapper">
                        <input type="file" onChange={this.onChange} accept=".pem" ref={this.hiddenFileInput}/>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ whiteSpace: 'nowrap', paddingLeft: 28, paddingRight: 28 }}
                          onClick={this.handleClick}
                        >
                          Datei wählen {/* @TODO Translate: Select file */}
                        </Button>
                      </div>
                      <Button
                        disabled={this.state.file == null}
                        style={{ marginTop: 14 }}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Privaten Schlüssel hochladen
                      </Button> {/* @TODO Translate: Upload key */}
                    </p>
                  </form>
                </>
              )}
              <Divider/>

              {this.userInfo()}

              <Divider/>
              <div>
                <div style={{ width: '50%', padding: 0, float: 'left' }}>
                  {this.companyInfo()}
                </div>
                <div style={{ width: '50%', padding: 0, float: 'left' }}>
                  {this.licenceInfo()}
                </div>
              </div>
              <Divider style={{ clear: 'both' }}/>

            </CardContent>
          ) : null}
        </Card>
        <Snackbar open={this.state.errorOne} autoHideDuration={3000} onClose={this.handleCloseOne}>
          <Alert variant="filled" onClose={this.handleCloseOne} severity="error">
            Falscher privater Schlüssel! {/* @TODO Translate: Wrong private key! */}
          </Alert>
        </Snackbar>

        <Snackbar open={this.state.errorTwo} autoHideDuration={3000} onClose={this.handleCloseTwo}>
          <Alert variant="filled" onClose={this.handleCloseTwo} severity="error">
            Es wurde kein privater Schlüssel erkannt! {/* @TODO Translate: No private key was detected! */}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

export default MyProfil;
