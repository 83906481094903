import React, { useState } from 'react';
import { Crypt, RSA } from 'hybrid-crypto-js';
import { Button } from '@material-ui/core';
import { apiUrl } from '@zfu-utils';
import Dexie from 'dexie';

const db = new Dexie('zfu');
db.version(1).stores({
  confirmations:
    '++id, ustid1, ustid2, name, error_code, street, place, postcode, date, time, result_name, result_place, result_postcode, result_street, valid_from, valid_to, print, import_id, license_id, user_id, created_at, updated_at',
});

function Sync() {
  const [sync, setSync] = useState(false);
  const user = JSON.parse(localStorage.getItem('auth'));

  if (user) {
    async function getData() {
      return await fetch(apiUrl + '/confirmations', {
        method: 'GET',
        headers: new Headers({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + user.access_token,
        }),
      })
        .then(response => response.json())
        .then(async json => {
          const privateKey = await localStorage.getItem('privateKey');
          const crypt = new Crypt();
          await json.map(async (value, key) => {
            db.transaction('rw', db.confirmations, async () => {
              if (await db.confirmations.where({ id: value.id })) {
                const decrypted = await crypt.decrypt(privateKey, value.data);
                const message = JSON.parse(decrypted.message);

                const id = await db.confirmations.put({
                  id: value.id,
                  ustid1: message.ustid1,
                  ustid2: message.ustid2,
                  name: message.name,
                  error_code: message.errorCode,
                  street: message.street,
                  place: message.place,
                  postcode: message.postcode,
                  date: message.date,
                  time: message.time,
                  result_name: message.result_name,
                  result_place: message.result_place,
                  result_postcode: message.result_postcode,
                  result_street: message.result_street,
                  valid_from: message.valid_from,
                  valid_to: message.valid_to,
                  print: message.print,
                  import_id: value.import_id,
                  license_id: value.license_id,
                  user_id: value.user_id,
                  created_at: value.created_at,
                  updated_at: value.updated_at,
                });
              }
            }).catch(e => {
              console.error(e.stack || e);
            });
          });
        });
    }
    getData();
  }
  return (
    <>
      {sync ? (
        <div
          style={{
            position: 'fixed',
            zIndex: 9999999,
            display: 'flex',
            flex: 1,
            backgroundColor: 'rgba(0,0,0,0.6)',
            height: '100vh',
            width: '100vw',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              backgroundColor: 'rgba(255,255,255,1)',
              padding: 80,
              borderRadius: 10,
              boxShadow: '0px 0px 10px rgba(0,0,0,0.7)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <h2>Synchronisierung erfoderlich</h2> {/* TODO Translate: Synchronisation is needed */}
            <p>Es gibt neue Daten in der Datenbank, möchtest du sie jetzt aktualisieren?</p> {/* TODO Translate: New data available. Would you like to refresh them. */}
            <p></p>
            <Button variant="contained" color="primary">
              aktualisieren
            </Button>
            {/* TODO Translate: refresh */}
          </div>
        </div>
      ) : null}
    </>
  );
}

export default Sync;
