import * as React from 'react';
import { Show, TabbedShowLayout, Tab } from 'react-admin';
import { ConfirmationsList } from './helper';
import DetailsConfirmation from './confirmationDetails';

const options = {
  orientation: 'landscape',
  format: 'a4',
  unit: 'mm',
  compress: true,
};

const Title = props => {
  return <div>Bestätigung zu USt-IdNr.: {props.record.ustid2}</div>;
  /* { */
  /* @TODO Translate: Confirmation for the VAT registration number */
  /* } */
};
export const ConfirmationsShow = ({ records, ...props }) => {
  return (
    <Show {...props} title={<Title />}>
      <TabbedShowLayout>
        <Tab label="Details">
          {/* @TODO Translate: Details */}
          <DetailsConfirmation />
        </Tab>
        <Tab label="Verlauf" path="list">
          {/* @TODO Translate: Log */}
          <ConfirmationsList {...props} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
