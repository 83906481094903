import { Button, Card, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
export const Buttons = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Grid container spacing={6} style={{ display: 'flex', justifyContent: 'center' }}>
        <Grid item>
          <Link to="/newrequest" className="link">
            <Button variant="contained" color="primary" style={{ padding: 48 }}>
              Einzel-Anfrage {/* @TODO Translate: Single request */}
            </Button>
          </Link>
        </Grid>
        <Grid item>
          <Link to="/importer" className="link">
            <Button variant="contained" color="primary" style={{ padding: 48 }}>
              Mehrfach-Anfrage {/* @TODO Translate: Multiple requests */}
            </Button>
          </Link>
        </Grid>
      </Grid>
    </div>
  );
};
