import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ChipField,
  Filter,
  TextInput,
  ShowButton,
  CreateButton,
  PasswordInput,
  Show,
  SimpleShowLayout,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  EditButton,
  Create,
  SimpleForm,
  SelectInput,
  ReferenceField,
  email,
  required,
  ArrayField,
  SingleFieldList,
  SelectField,
  TopToolbar,
} from 'react-admin';
import { apiUrl } from '@zfu-utils';
const PostFilter = props => (
  <Filter {...props}>
    <TextInput label="Suche..." source="q" alwaysOn />
  </Filter>
);

const emailValidation = (value, allValues) => {
  return fetch(apiUrl + '/user/email/' + value).then(response => {
    if (response.status === 306) {
      return ['Diese E-Mail existiert bereits in unserem System'];
      /* { */
      /* @TODO Translate: This e-mail already exists. */
      /* } */
    }
  });

  // return [];
};

const MyEditButton = props => {
  const myID = JSON.parse(localStorage.getItem('auth'));
  return (
    <EditButton
      disabled={
        parseInt(props.record.id) === parseInt(myID.user.id) ||
        (props.record.roles && props.record.roles[0].name === 'superadmin' && myID.user.role === 'superadmin')
      }
      {...props}
    />
  );
};
const validateEmail = [required(), email(), emailValidation];

export const UserList = ({ permissions, ...props }) => {
  return (
    <List {...props} title="Konten" filters={<PostFilter />} bulkActionButtons={false}>
      {/* @TODO Translate: accounts. */}
      <Datagrid>
        <TextField source="email" label="E-Mail" />
        {permissions === 'superadmin' && (
          <ReferenceField label="Firma" source="license_id" reference="license" link="show">
            {/* @TODO Translate: company. */}
            <TextField source="company_name" />
          </ReferenceField>
        )}
        <ArrayField source="roles" label="Rolle">
          <SingleFieldList linkType="">
            <SelectField
              source="name"
              choices={[
                { id: 'admin', name: 'Administrator' }, // @TODO Translate: admin.
                { id: 'superadmin', name: 'Super-Administrator' }, // TODO Translate: super admin
                { id: 'user', name: 'Benutzer' }, // @TODO Translate: user
              ]}
            />
          </SingleFieldList>
        </ArrayField>
        <DateField source="created_at" label="erstellt am:" /> {/* @TODO Translate: created at:  */}
        <ShowButton resource="user" />
        <MyEditButton {...props} />
      </Datagrid>
    </List>
  );
};

const postDefaultValue = { role: 'user' };

const PostShowActions = ({ basePath, data, resource }) => {
  const myID = JSON.parse(localStorage.getItem('auth'));
  if (data) {
    return (
      <TopToolbar>
        <EditButton
          basePath={basePath}
          record={data}
          disabled={
            (myID && myID.user && parseInt(data.id) === parseInt(myID.user.id)) ||
            (data.roles && data.roles[0].name === 'superadmin' && myID.user.role === 'superadmin')
          }
        />
      </TopToolbar>
    );
  }
  return <></>;
};

export const UserCreate = props => (
  <Create {...props} title="Konten">
    <SimpleForm defaultValue={postDefaultValue} redirect="list">
      <TextInput source="email" label="E-Mail" required validate={emailValidation} />{/* @TODO Translate: Email */}
      <PasswordInput source="password" required security label="Passwort" />{/* @TODO Translate: Password */}
      <SelectInput
        source="role"
        required
        label="Rolle"
        choices={[
          { id: 'user', name: 'Benutzer' }, // @TODO Translate: user
          { id: 'admin', name: 'Administrator' }, // @TODO Translate: admin.
          props.permissions === 'superadmin' && { id: 'superadmin', name: 'Super-Administrator' }, // TODO Translate: super admin
        ]}
      />
      {props.permissions === 'superadmin' ? (
        <ReferenceInput label="Firma" source="license_id" reference="license" required>
          {/* @TODO Translate: Company */}
          <SelectInput optionText="company_name" optionValue="id" />
        </ReferenceInput>
      ) : null}
    </SimpleForm>
  </Create>
);

const UserDetailsTitle = props => <div>Konto: {props.record.email}</div>;
export const UserShow = ({ records, ...props }) => {
  return (
    <>
      <Show title={<UserDetailsTitle />} actions={<PostShowActions />} {...props}>
        <SimpleShowLayout>
          <TextField source="email" />
          <ArrayField source="roles" label="Rolle">
            {/* @TODO Translate: role */}
            <SingleFieldList linkType="">
              <SelectField
                source="name"
                choices={[
                  { id: 'admin', name: 'Administrator' }, // @TODO Translate: admin.
                  { id: 'superadmin', name: 'Super-Administrator' }, // TODO Translate: super admin
                  { id: 'user', name: 'Benutzer' }, // @TODO Translate: user
                ]}
              />
            </SingleFieldList>
          </ArrayField>

          {props.permissions === 'superadmin' && (
            <ReferenceField label="Firma" source="license_id" reference="license" link="show">
              {/* @TODO Translate: Company */}
              <TextField source="company_name" />
            </ReferenceField>
          )}
          <DateField source="created_at" label="erstellt am:" />
        </SimpleShowLayout>
      </Show>
    </>
  );
};
