import React, { cloneElement, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import PlusIcon from '@material-ui/icons/Add';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Moment from 'moment';
import Dexie from 'dexie';

import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import {
  List,
  SelectInput,
  Datagrid,
  TextField,
  DateField,
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
  sanitizeListRestProps,
  BooleanField,
  ChipField,
  Filter,
  TextInput,
  ShowButton,
  Pagination,
  SelectField,
} from 'react-admin';
import { DurationEnum } from './Pdfview-modal/DurationEnum';

export const StatusCodeDetails = props => {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
      <span style={{ marginRight: 12 }}>{props.record.error_code}</span>
      <Tooltip arrow title={<span className="tooltip">{codes[props.record.error_code]}</span>}>
        <HelpOutlineIcon style={{ fontSize: 16, display: 'flex', alignSelf: 'center' }} />
      </Tooltip>
    </div>
  );
};

export const StatusCode = props => {
  const code = false;
  if (props.record.error_code === 200) {
    return (
      <Tooltip arrow title={<span className="tooltip">gültig</span>}>
        <CheckCircleIcon style={{ color: '#00D020' }} />
      </Tooltip>
    ); // @TODO Translate: valid
  }
  if (props.record.error_code !== 200) {
    return (
      <Tooltip arrow title={<span className="tooltip">nicht gültig</span>}>
        <InfoIcon style={{ color: '#C70000' }} />
      </Tooltip>
    ); // @TODO Translate: invalid
  }
};
export const TextFieldColorSimple = source => {
  let bg = '';
  let color = '';
  let tip = '';
  if (source.source === 'A') {
    bg = '#00D020';
    color = '#fff';
    tip = 'stimmt überein';
  } // @TODO Translate: coincides
  if (source.source === 'B') {
    bg = '#FF8900';
    color = '#fff';
    tip = 'stimmt nicht überein';
  } // @TODO Translate: does not coincide
  if (source.source === 'C') {
    bg = '#FFD800';
    color = '#fff';
    tip = 'nicht angefragt';
  } // @TODO Translate: not requested
  if (source.source === 'D') {
    bg = '#989898';
    color = '#000';
    tip = 'vom EU-Mitgliedsstaat nicht mitgeteilt';
  } // @TODO Translate: no response from the EU member state

  return (
    <div style={{ display: 'flex' }}>
      <Tooltip title={<span className="tooltip">{tip}</span>} arrow>
        <span
          style={{
            backgroundColor: bg,
            width: 20,
            height: 20,
            color: color,
            borderRadius: 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 10,
            fontWeight: 'bold',
          }}
        >
          {source.source}
        </span>
      </Tooltip>
      <span style={{ marginLeft: 12 }}>{tip}</span>
    </div>
  );
};

export const TextFieldColor = props => {
  let bg = '';
  let color = '';
  let tip = '';
  if (props.record[props.source] === 'A') {
    bg = '#00D020';
    color = '#fff';
    tip = 'stimmt überein';
  } // @TODO Translate: coincides
  if (props.record[props.source] === 'B') {
    bg = '#FF8900';
    color = '#fff';
    tip = 'stimmt nicht überein';
  } // @TODO Translate: does not coincide
  if (props.record[props.source] === 'C') {
    bg = '#FFD800';
    color = '#fff';
    tip = 'nicht angefragt';
  } // @TODO Translate: not requested
  if (props.record[props.source] === 'D') {
    bg = '#989898';
    color = '#000';
    tip = 'vom EU-Mitgliedsstaat nicht mitgeteilt';
  } // @TODO Translate: no response from the EU member state

  return props.record[props.source] ? (
    <Tooltip title={<span className="tooltip">{tip}</span>} arrow>
      <span
        style={{
          backgroundColor: bg,
          width: 20,
          height: 20,
          color: color,
          borderRadius: 20,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: 10,
          fontWeight: 'bold',
        }}
      >
        {props.record[props.source]}
      </span>
    </Tooltip>
  ) : null;
};

const LoadFilter = props => {
  return <></>;
};

export const ConfirmationsList = props => {
  return (
    <List
      {...props}
      title={<></>}
      filterDefaultValues={{ ustid2: props.record.ustid2 }}
      sort={{ field: 'ustid2', order: 'DESC' }}
      bulkActionButtons={false}
    >
      <Datagrid>
        <TextField source="ustid2" label="USt-IdNr." /> {/* @TODO Translate: VAT registration number */}
        <StatusCode {...props} />
        <TextField source="name" label="Name" />
        {/* @TODO Translate: Name */}
        <TextFieldColor source="result_name" label="" />
        <TextField source="place" label="Ort" />
        {/* @TODO Translate: City */}
        <TextFieldColor source="result_place" label="" />
        <TextField source="postcode" label="Postleitzahl" />
        {/* @TODO Translate: ZIP code */}
        <TextFieldColor source="result_postcode" label="" />
        <TextField source="street" label="Straße & Nr." />
        {/* @TODO Translate: Street and Housenumber */}
        <TextFieldColor source="result_street" label="" />
        <TextField source="valid_from" label="Gültig vom" />
        {/* @TODO Translate: Valid from */}
        <TextField source="valid_to" label="Gültig bis" />
        {/* @TODO Translate: Valid until */}
        <StatusCodeDetails source="error_code" label="Status Code" />
        {/* @TODO Translate: Status code */}
        <TextField source="created_at" label="Datum" />
        {/* @TODO Translate: Date */}
        <ShowButton resource="confirmations" {...props} />
      </Datagrid>
    </List>
  );
};

export const UserConfirmationsList = () => {
  const props = {
    basePath: '/confirmations',
    hasCreate: false,
    hasEdit: false,
    hasList: true,
    hasShow: false,
    history: {},
    location: { pathname: '/', search: '', hash: '', state: undefined },
    match: { path: '/', url: '/', isExact: true, params: {} },
    options: {},
    permissions: null,
    resource: 'confirmations',
  };
  return (
    <List {...props} title="Überprüfungen" bulkActionButtons={false}>
      <Datagrid>
        <TextField source="ustid2" label="USt-IdNr." />
        {/* @TODO Translate: VAT registration number */}
        <TextField source="name" label="Name" />
        {/* @TODO Translate: Name */}
        <TextFieldColor source="result_name" label="" />
        <TextField source="place" label="Ort" />
        {/* @TODO Translate: City */}
        <TextFieldColor source="result_place" label="" />
        <TextField source="postcode" label="Postleitzahl" />
        {/* @TODO Translate: ZIP code */}
        <TextFieldColor source="result_postcode" label="" />
        <TextField source="street" label="Straße & Nr." />
        {/* @TODO Translate: Street and Housenumber */}
        <TextFieldColor source="result_street" label="" />
        <TextField source="valid_from" label="Gültig vom" />
        {/* @TODO Translate: Valid from */}
        <TextField source="valid_to" label="Gültig bis" />
        {/* @TODO Translate: Valid until */}
        <StatusCodeDetails source="error_code" label="Status Code" />
        {/* @TODO Translate: Status code */}
        <TextField source="created_at" label="Datum" />
        {/* @TODO Translate: Date */}
        <ShowButton resource="confirmations" />
      </Datagrid>
    </List>
  );
};

const codes = {
  // @TODO Translate: The requested VAT registration number is valid.
  200: 'Die angefragte USt-IdNr. ist gültig.',
  // @TODO Translate: The requested VAT registration number is invalid.
  201: 'Die angefragte USt-IdNr. ist ungültig.',
  // @TODO Translate: The requested VAT registration number is invalid. It is not registered in the corresponding EU member state. Notice: Your business partner can find out its valid VAT registration number at its responsible authority. Possibly it has to file an application so that the VAT registration number is stored in the corresponding data base.
  202: 'Die angefragte USt-IdNr. ist ungültig. Sie ist nicht in der Unternehmerdatei des betreffenden EU-Mitgliedstaates registriert. Hinweis: Ihr Geschäftspartner kann seine gültige USt-IdNr. bei der für ihn zuständigen Finanzbehörde in Erfahrung bringen. Möglicherweise muss er einen Antrag stellen, damit seine USt-IdNr. in die Datenbank aufgenommen wird.',
  // @TODO Translate: The requested VAT registration number is invalid. It is valid from ... on. (See field valid from).
  203: "Die angefragte USt-IdNr. ist ungültig. Sie ist erst ab dem ... gültig (siehe Feld 'Gueltig_ab').",
  // @TODO Translate: The requested VAT registration number is invalid. It was valid from ... until .... (See field valid_from and valid_until).
  204: "Die angefragte USt-IdNr. ist ungültig. Sie war im Zeitraum von ... bis ... gültig (siehe Feld 'Gueltig_ab' und 'Gueltig_bis').",
  // @TODO Translate: Your request could not be processed. Please try again later. If the error repeats itself, please contact the Federal Central Tax Office in Saarlouis.
  205: 'Ihre Anfrage kann derzeit durch den angefragten EU-Mitgliedstaat oder aus anderen Gründen nicht beantwortet werden. Bitte versuchen Sie es später noch einmal. Bei wiederholten Problemen wenden Sie sich bitte an das Bundeszentralamt für Steuern - Dienstsitz Saarlouis.',
  // @TODO Translate: Your german VAT registration number is invalid. A confirmation request will therefore not be processed.
  206: 'Ihre deutsche USt-IdNr. ist ungültig. Eine Bestätigungsanfrage ist daher nicht möglich. Den Grund hierfür können Sie beim Bundeszentralamt für Steuern - Dienstsitz Saarlouis - erfragen.',
  // @TODO Translate: Your VAT registration number was only supplied for taxation purposes of intra-Community acquisitions. You are not authorized to request confirmations.
  207: 'Ihnen wurde die deutsche USt-IdNr. ausschliesslich zu Zwecken der Besteuerung des innergemeinschaftlichen Erwerbs erteilt. Sie sind somit nicht berechtigt, Bestätigungsanfragen zu stellen.',
  // @TODO Translate: There is already a request of this number by another user. The processing of your request is currently not possible. Please try it again later.
  208: 'Für die von Ihnen angefragte USt-IdNr. läuft gerade eine Anfrage von einem anderen Nutzer. Eine Bearbeitung ist daher nicht möglich. Bitte versuchen Sie es später noch einmal.',
  // @TODO Translate: The requested VAT registration number in invalid. It does not correspond the structure demanded by this EU member state.
  209: 'Die angefragte USt-IdNr. ist ungültig. Sie entspricht nicht dem Aufbau der für diesen EU-Mitgliedstaat gilt. ( Aufbau der USt-IdNr. aller EU-Länder)',
  // @TODO Translate: The requested VAT registration number in invalid. The error checking number for this EU member state does not validate.
  210: 'Die angefragte USt-IdNr. ist ungültig. Sie entspricht nicht den Prüfziffernregeln die für diesen EU-Mitgliedstaat gelten.',
  // @TODO Translate: The requested VAT registration number in invalid. It contains invalid characters (e.g. blank, commata ...)
  211: 'Die angefragte USt-IdNr. ist ungültig. Sie enthält unzulässige Zeichen (wie z.B. Leerzeichen oder Punkt oder Bindestrich usw.).',
  // @TODO Translate: The requested VAT registration number in invalid. It contains an invalid country code.
  212: 'Die angefragte USt-IdNr. ist ungültig. Sie enthält ein unzulässiges Länderkennzeichen.',
  // @TODO Translate: It is not possible to confirm a german VAT registration number.
  213: 'Die Abfrage einer deutschen USt-IdNr. ist nicht möglich.',
  // @TODO Translate: Your german VAT registration number is invalid. Valid numbers start with DE followed by nine numbers.
  214: "Ihre deutsche USt-IdNr. ist fehlerhaft. Sie beginnt mit 'DE' gefolgt von 9 Ziffern.",
  // @TODO Translate: Your request does not contain all the needed data for a simple confirmation request. (Your german VAT registration number and the foreign number you want to check.) Your request therefore can not be processed.
  215: 'Ihre Anfrage enthält nicht alle notwendigen Angaben für eine einfache Bestätigungsanfrage (Ihre deutsche USt-IdNr. und die ausl. USt-IdNr.). Ihre Anfrage kann deshalb nicht bearbeitet werden.',
  // @TODO Translate:  Your request does not contain all the needed data for an extended confirmation request. (Your german VAT registration number, the foreign number you want to check, company name including legal form and city.) A simple request has been processed with the following result: The requested VAT registration number is valid.
  216: 'Ihre Anfrage enthält nicht alle notwendigen Angaben für eine qualifizierte Bestätigungsanfrage (Ihre deutsche USt-IdNr., die ausl. USt-IdNr., Firmenname einschl. Rechtsform und Ort). Es wurde eine einfache Bestätigungsanfrage durchgeführt mit folgenden Ergebnis: Die angefragte USt-IdNr. ist gültig.',
  // @TODO Translate: An error occured while processing the data from the requested EU member state. Your request could not be processed.
  217: 'Bei der Verarbeitung der Daten aus dem angefragten EU-Mitgliedstaat ist ein Fehler aufgetreten. Ihre Anfrage kann deshalb nicht bearbeitet werden.',
  // @TODO Translate: An extended confirmation is currently not possible. A simple request has been processed with the following result: The requested VAT registration number is valid.
  218: 'Eine qualifizierte Bestätigung ist zur Zeit nicht möglich. Es wurde eine einfache Bestätigungsanfrage mit folgendem Ergebnis durchgeführt: Die angefragte USt-IdNr. ist gültig.',
  // @TODO Translate: An error occured during the processing of an extended request. A simple request has been processed with the following result: The requested VAT registration number is valid.
  219: 'Bei der Durchführung der qualifizierten Bestätigungsanfrage ist ein Fehler aufgetreten. Es wurde eine einfache Bestätigungsanfrage mit folgendem Ergebnis durchgeführt: Die angefragte USt-IdNr. ist gültig.',
  // @TODO Translate: An error occured during the request of the official confirmation. You will receive no response.
  220: 'Bei der Anforderung der amtlichen Bestätigungsmitteilung ist ein Fehler aufgetreten. Sie werden kein Schreiben erhalten.',
  // @TODO Translate: The request does not contain all the needed parameters or an invalid data type.
  221: 'Die Anfragedaten enthalten nicht alle notwendigen Parameter oder einen ungültigen Datentyp. Weitere Informationen erhalten Sie bei den Hinweisen zum Schnittstelle - Aufruf.',
  // @TODO Translate: The queried VAT registration number is invalid.
  223: 'Die angefragte USt-IdNr. ist gültig. Die Druckfunktion steht nicht mehr zur Verfügung, da der Nachweis gem. UStAE zu § 18e.1 zu führen ist.',
  // @TODO Translate: The processing of your request is currently not possible. Please try again later.
  999: 'Eine Bearbeitung Ihrer Anfrage ist zurzeit nicht möglich. Bitte versuchen Sie es später noch einmal.',
};

export const statusCodes = codes;

export const ListActions = props => {
  const { className, exporter, filters, maxResults, onClickOnPdfView, ...rest } = props;
  const { currentSort, resource, displayedFilters, filterValues, basePath, selectedIds, showFilter, total } = useListContext();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const privateKey = localStorage.getItem('privateKey');

  return (
    <TopToolbar style={{
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      padding: 0
    }} className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}

      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '5px', alignSelf: 'flex-end' }}>
        <ExportButton 
          style={{
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: '25%'
          }}
          disabled={total === 0} 
          resource={resource} 
          sort={currentSort} 
          filterValues={filterValues} 
          maxResults={maxResults} />
        <Button
          style={{ marginLeft: 6 }}
          aria-controls="simple-menu"
          size="small"
          aria-haspopup="true"
          color="primary"
          variant="contained"
          onClick={handleClick}
          disabled={!privateKey}
          startIcon={<PlusIcon />}
        >
          Neue Anfrage {/* @TODO Translate: New request */}
        </Button>
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem>
            <Link to="/newrequest" className="link">
              Einzel-Anfrage {/* @TODO Translate: Single request */}
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/importer" className="link">
              Mehrfach-Anfrage {/* @TODO Translate: Multiple requests */}
            </Link>
          </MenuItem>
        </Menu>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', position: 'relative', alignSelf: 'flex-end' }}>
        <Button
          style={{ marginLeft: 6, backgroundColor: 'var(--red)', fontSize: '12px', marginTop: '10px' }}
          aria-controls="simple-menu"
          size="small"
          aria-haspopup="true"
          color="primary"
          variant="contained"
          onClick={onClickOnPdfView}
          disabled={!privateKey}
          startIcon={<PictureAsPdf />}
        >
          REVISIONSSICHER ABLEGEN {/* @TODO Translate */}
        </Button>
      </div>

    </TopToolbar>
  );
};

export const getStartEndDateByDuration = (duration) => {
  const date = Moment();
  let startRange = date;
  let endRange = date;
  switch (duration) {
    case DurationEnum.CURRENT_MONTH:
      startRange = Moment(date).startOf('month');
      endRange = Moment(date).endOf('month');
      break;

    case DurationEnum.CURRENT_YEAR:
      startRange = Moment(date).startOf('year');
      endRange = Moment(date).endOf('year');
      break;

    case DurationEnum.PREVIOUS_MONTH:
      startRange = Moment(date).subtract(1, 'months').startOf('month');
      endRange = Moment(date).subtract(1, 'months').endOf('month');
      break;

    case DurationEnum.PREVIOUS_YEAR:
      startRange = Moment(date).subtract(1, 'year').startOf('year');
      endRange = Moment(date).subtract(1, 'year').endOf('year');
      break;

    case DurationEnum.CHOOSE_INDIVIDUAL:
      startRange = Moment();
      endRange = Moment();
      break;

    default:
      break;
  }

  const startDate = Moment(startRange).format('YYYY-MM-DD');
  const endDate = Moment(endRange).format('YYYY-MM-DD');
  return {
    startDate,
    endDate
  }
}

export const filterdConfirmationData = (field, order, filterValue) => {
  const db = new Dexie('zfu');
  db.version(1).stores({
    confirmations:
      '++id, ustid1, ustid2, name, error_code, street, place, postcode, date, time, result_name, result_place, result_postcode, result_street, valid_from, valid_to, print, import_id, license_id, user_id, created_at, updated_at',
  });
  const result = db
      .transaction('rw', db.confirmations, async () => {
        const res = {};
        const confirmations = await db.confirmations.orderBy(field); // QUERY START

        // FILTER START
        if (filterValue) {
          if (filterValue.q) {
            confirmations.filter(function (confirmations) {
              return confirmations.name.toLowerCase().indexOf((filterValue.q).toLowerCase()) !== -1;
            });
          }
          const filter = Object.entries(filterValue);
          filter.map((value, key) => {
            return confirmations.filter(function (confirmations) {
              if (value[0] === 'q') {
                return confirmations.name.toLowerCase().indexOf((filterValue.q).toLowerCase()) !== -1;
              }
              if (value[0] === 'created_at') {
                return confirmations.created_at.includes(filterValue.created_at);
              }
              if (value[0] === 'import_id') {
                return confirmations.import_id === value[1];
              }
              if (value[0] === 'duration') {
                const duration = getStartEndDateByDuration(value[1]);
                let startDate = Moment();
                let endDate = Moment();
                const startDateObj = filter.find((data) => data[0] === 'start_date');
                const endDateObj = filter.find((data) => data[0] === 'end_date');
                startDate = startDateObj ? startDateObj[1] : Moment();
                endDate = endDateObj ? endDateObj[1] : Moment();
                const createdAt = Moment(confirmations.created_at).format('YYYY-MM-DD');
                if (value[1] === DurationEnum.CHOOSE_INDIVIDUAL) {
                  return ((new Date(createdAt)).getTime() >= (new Date(startDate)).getTime())
                  && ((new Date(createdAt)).getTime() <= (new Date(endDate)).getTime());
                }

                return ((new Date(createdAt)).getTime() >= (new Date(duration.startDate)).getTime())
                  && ((new Date(createdAt)).getTime() <= (new Date(duration.endDate)).getTime());
              }
              if (value[0] === 'start_date') {
                const createdAt = Moment(confirmations.created_at).format('YYYY-MM-DD');
                return ((new Date(createdAt)).getTime() >= (new Date(value[1])).getTime());
              }
              if (value[0] === 'end_date') {
                const createdAt = Moment(confirmations.created_at).format('YYYY-MM-DD');
                return ((new Date(createdAt)).getTime() <= (new Date(value[1])).getTime());
              }
              if (value[0] === 'is_valid') {
                return confirmations.error_code === 200;
              }
              if (value[0] === 'is_invalid') {
                return confirmations.error_code !== 200;
              }

              return confirmations[value[0]] === (value[0] === 'error_code' ? parseInt(value[1]) : value[1]);
            });
          });
        }
        // FILTER END

        const total = await confirmations.toArray(); // GET TOTAL QUERY ENTRYS

        if (order === 'DESC') {
          confirmations
            .reverse() // DESC
        }

        res.data = await confirmations.toArray();
        res.totalConfirmations = total;
        res.total = total.length;
        return res;
      })
      .catch(e => {
        console.error(e.stack || e); // ERROR MESSAGE CONSOLE
      });
    return result;
}