export default async function saveFileToDisk(fileName) {
  const file = new Blob([localStorage.getItem('privateKey')], { type: 'text/plain' });

  if (typeof self.showSaveFilePicker === "function") {
    try {
      const fileHandle = await self.showSaveFilePicker({
        suggestedName: fileName,
        types: [
          {
            description: "File",
            // ...
          },
        ],
      });
      const writeFile = async (fileHandle, contents) => {
        // Create a FileSystemWritableFileStream to write to.
        const writable = await fileHandle.createWritable();
        // Write the contents of the file to the stream.
        await writable.write(contents);
        // Close the file and write the contents to disk.
        await writable.close();
      };
      // write file
      writeFile(fileHandle, file).then(() => true);
    } catch (error) {
      return true;
    }
  } else {
    const element = document.createElement('a');
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }
};
