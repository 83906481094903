import * as React from 'react';
import { useState } from 'react';
import ReactCodeInput from 'react-verification-code-input';
import {
  Button,
  TextField,
  InputAdornment,
  FormControl,
  FilledInput,
  InputLabel,
  CircularProgress,
  FormHelperText,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Check from '@material-ui/icons/CheckCircleOutline';
import { Crypt, RSA } from 'hybrid-crypto-js';
import { Form, Field } from 'react-final-form';
import { apiUrl, defaultPublicKey } from '@zfu-utils';
import { Link } from 'react-router-dom';
import saveFileToDisk from './SaveFileToDisk';
import DownloadedKeyMessage from "./DownloadedKeyMessage";
import { ThemeProvider } from '@material-ui/styles';

const RegisterLicense = (props) => {
  const [next, setNext] = useState(false);
  const [load, setLoading] = useState(false);
  const [privateKey, setPrivateKey] = useState();
  const [publicKey, setPublicKey] = useState();
  const [key, setKey] = useState(null);
  const [values, setValues] = useState([]);
  const [error, setError] = useState(null);
  const [isKeyUploaded, setIsKeyUploaded] = useState(false);

  const _handleComplet = e => {
    if (e.replaceAll(' ', '').length === 12) {
      setLoading(true);

      return fetch(apiUrl + '/check-license', {
        method: 'POST',
        body: JSON.stringify({ key: e }),
        headers: new Headers({
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      })
        .then(response => response.json())
        .then(json => {
          if (json[0] === 'success') {
            var crypt = new Crypt();
            var rsa = new RSA();

            rsa.generateKeyPair(function (keyPair) {
              setPublicKey(keyPair.publicKey);
              setPrivateKey(keyPair.privateKey);
              localStorage.setItem('privateKey', keyPair.privateKey);
              localStorage.setItem('publicKey', keyPair.publicKey);
            }, 2048);
            setError(null);
            setNext(true);
            setLoading(false);
            setKey(e);
          } else if (json.errorkey) {
            setError('Dieser Produktschlüssel ist ungültig!'); // @TODO Translate: Your product key is invalid!
            setNext(false);
            setLoading(false);
            setKey(null);
          } else {
            {
              setError('Dieser Produktschlüssel wird bereits verwendet.'); // @TODO Translate: Your product is already beeing used.
              setNext(false);
              setLoading(false);
              setKey(null);
            }
          }
        });
    } else {
      setError('Wrong KEY');
      setNext(false);
      setKey(null);
    }
  };

  const _handleChange = e => {
    setError(null);
    if (e.replaceAll(' ', '').length === 12) {
      setError(null);
    }
  };
  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

  const onSubmit = async values => {
    await sleep(300);
    var crypt = new Crypt();
    var prikey = await crypt.encrypt(defaultPublicKey, privateKey);
    return fetch(apiUrl + '/register-license', {
      method: 'POST',
      body: JSON.stringify({
        email: values.email,
        password: values.password,
        publickey: localStorage.getItem('publicKey'),
        privatekey: prikey,
        key: key,
      }),
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then(response => response.json())
      .then(json => {
        if (json.email === values.email) {
          saveFileToDisk(key + '.pem');
          setIsKeyUploaded(true);
        } else {
          // @TODO Display error message.

        }
      });
  };

  const onValidate = async values => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Ist erfoderlich!'; // @TODO Translate: Is required!
    } else if (values.email) {
      var emailing = false;
      emailing = await fetch(apiUrl + '/user/email/' + values.email).then(response => {
        if (response.status === 306) {
          return true;
        }
      });
      if (emailing) {
        errors.email = 'Diese E-Mail wird bereits verwendet!'; // @TODO Translate: This e-mail is already in use!
      }
    }

    if (!values.password) {
      errors.password = 'Ist erfoderlich!'; // @TODO Translate: Is required!
    }
    if (!values.password2) {
      errors.password2 = 'Ist erfoderlich!'; // @TODO Translate: Is required!
    } else if (values.password2 !== values.password) {
      errors.password2 = 'Muss überreinstimmen!'; // @TODO Translate: Password and confirmation have to correspond!
    }
    return errors;
  };
  const [showPassword, setshowPassword] = useState(false);
  return (
    <ThemeProvider theme={props.theme}>
    <div
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
    >
      <h1 style={{ fontFamily: 'Nunito', fontWeight: 600, fontSize: '4rem', textAlign: 'center', marginBottom: 16, paddingBottom: 0 }}>
        Produktaktivierung
      </h1> {/* @TODO Translate: Product activation */}
      {!load && !key ? (
        <>
          <div>
            <p style={{ fontFamily: 'Nunito', fontWeight: 200, fontSize: 16, textAlign: 'center', paddingBottom: 16 }}>
              Geben Sie bitte Ihren Produktschlüssel ein. {/* @TODO Translate: Please enter the product key. */}
            </p>
            <ReactCodeInput type="text" fields={12} onComplete={e => _handleComplet(e)} onChange={e => _handleChange(e)} />
          </div>
          <Link style={{ margin: 20 }} to="/login">
            Abbrechen
          </Link>
          {/* @TODO Translate: Cancel */}
        </>
      ) : (
        key && (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <p style={{ fontFamily: 'Nunito', fontWeight: 200, fontSize: 32, marginRight: 16 }}>Produktschlüssel: </p> {/* @TODO Translate: Product key */}
            <p
              style={{
                fontFamily: 'Nunito',
                fontWeight: 600,
                fontSize: 32,
                letterSpacing: 16,
                backgroundColor: '#ccc',
                padding: 10,
                borderRadius: 32,
                paddingLeft: 24,
              }}
            >
              {key}
            </p>
            <Check color="action" style={{ height: 60, width: 60, marginLeft: 16 }} />
          </div>
        )
      )}
      {load && <CircularProgress />}
      {next && !isKeyUploaded && (
        <Form
          onSubmit={onSubmit}
          validate={onValidate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} style={{ width: "90%", maxWidth: "640px" }}>
              <Field name="email">
                {({ input, meta }) => (
                  <>
                    <TextField
                      id="email"
                      label="E-Mail"
                      variant="filled"
                      required
                      {...input}
                      fullWidth
                      error={meta.error}
                      errorText={meta.error && meta.touched && meta.error}
                    />
                    <FormHelperText style={{ marginLeft: 14 }} error>{meta.error && meta.touched && meta.error}</FormHelperText>
                  </>
                )}
              </Field>
              <Field name="password">
                {({ input, meta }) => (
                  <FormControl variant="filled" required fullWidth>
                    <InputLabel htmlFor="password">Passwort</InputLabel> {/* @TODO Translate: Password */}
                    <FilledInput
                      {...input}
                      id="password"
                      variant="filled"
                      error={meta.error}
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility" onClick={() => setshowPassword(!showPassword)} edge="end">
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <FormHelperText error>{meta.error && meta.touched && meta.error}</FormHelperText>
                  </FormControl>
                )}
              </Field>
              <Field name="password2">
                {({ input, meta }) => (
                  <FormControl variant="filled" required fullWidth style={{ marginBottom: 24 }}>
                    <InputLabel htmlFor="password2">Passwort wiederholen</InputLabel>
                    {/* @TODO Translate: Repeat password */}
                    <FilledInput
                      {...input}
                      id="password2"
                      error={meta.error}
                      errorText={meta.error && meta.touched && meta.error}
                      variant="filled"
                      type={showPassword ? 'text' : 'password'}
                    />
                    <FormHelperText error>{meta.error && meta.touched && meta.error}</FormHelperText>
                  </FormControl>
                )}
              </Field>
              <Button type="submit" variant="contained" fullWidth color="primary">
                Produkt Aktivieren
              </Button> {/* @TODO Translate: Activate product */}
            </form>
          )}
        />
      )}
      {!!key && isKeyUploaded && (
        <DownloadedKeyMessage filename={key} />
      )}
      {error && (
        <p style={{ fontFamily: 'Nunito', fontWeight: 200, fontSize: 16, textAlign: 'center', paddingBottom: 16, color: 'darkred' }}>
          {error}
        </p>
      )}
    </div>
    </ThemeProvider>
  );
};

export default RegisterLicense;
