import React, { Component } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import Dexie from 'dexie';
import { Crypt } from 'hybrid-crypto-js';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import importerCTX from './importerCTX';
import PropTypes from 'prop-types';

var parseString = require('xml2js').parseString;
const db = new Dexie('zfu');
db.version(1).stores({
  confirmations:
    '++id, ustid1, ustid2, name, error_code, street, place, postcode, date, time, result_name, result_place, result_postcode, result_street, valid_from, valid_to, print, import_id, license_id, user_id, created_at, updated_at',
});
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

class Uploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      importID: 1,
      user: false,
      arr: [],
      process: false,
      isDone: false,
      a: 0,
      b: 0,
      c: 0,
      d: 0,
      valid: 0,
    };
  }

  async componentDidMount() {
    var sorts = await JSON.parse(sessionStorage.getItem('sorter'));
    var data = this.props.parsedData;
    var user = await JSON.parse(localStorage.getItem('auth'));
    await this.setState({ user: user });
    await this.setState({ data: data });
    await this.startImporter(sorts, data);
  }

  async loadLocal() {
    await this.props.getConfirmationList();
    if (this.props.confirmationListResponse) {
      const json = this.props.confirmationListResponse;
      var res = {};
      const privateKey = localStorage.getItem('privateKey');
      var crypt = new Crypt();
      await json.map(async (value, key) => {
        await db
          .transaction('rw', db.confirmations, async () => {
            if (await db.confirmations.where({ id: value.id })) {
              var decrypted = await crypt.decrypt(privateKey, value.data);
              var message = JSON.parse(decrypted.message);
              const id = await db.confirmations.put({
                id: value.id,
                ustid1: message.ustid1,
                ustid2: message.ustid2,
                name: message.name,
                error_code: message.errorCode,
                street: message.street,
                place: message.place,
                postcode: message.postcode,
                date: message.date,
                time: message.time,
                result_name: message.result_name,
                result_place: message.result_place,
                result_postcode: message.result_postcode,
                result_street: message.result_street,
                valid_from: message.valid_from,
                valid_to: message.valid_to,
                print: message.print,
                import_id: value.import_id,
                license_id: value.license_id,
                user_id: value.user_id,
                created_at: value.created_at,
                updated_at: value.updated_at,

              });
            }
          })
          .catch(e => {
            console.error(e.stack || e);
          });
      });
      await sleep(500);
      return true;
    }
  }

  async onSubmit(values, sorts) {
    const that = this;
    var expertString = sorts.expert
      ? '&Firmenname=' +
      values[sorts.name] +
      '&Ort=' +
      values[sorts.place] +
      '&PLZ=' +
      values[sorts.postcode] +
      '&Strasse=' +
      values[sorts.street]
      : '';
    const queryString = '?UstId_1=' + this.state.user.license.ustid + '&UstId_2=' + values[sorts.ustid] + '' + expertString;
    /**
    *  @abstract
    *  https://evatr.bff-online.de/evatrRPC
    *  is the actual API from where the application gets the results of a certain company VAT registration number actually exists.
    *  @param UstId_1 is the VAT registration number of the company that does the request.
    *  The result comes in XML format.
    */
    await this.props.evatRequest(queryString);
    if (this.props.evatRequestResponse) {
      const response = this.props.evatRequestResponse;
      parseString(response, { trim: true }, async function (err, result) {
        const obj = {};
        obj.ustid1 = result.params.param[0].value[0].array[0].data[0].value[1].string[0];
        obj.ustid2 = result.params.param[2].value[0].array[0].data[0].value[1].string[0];
        obj.date = result.params.param[6].value[0].array[0].data[0].value[1].string[0];
        obj.print = result.params.param[3].value[0].array[0].data[0].value[1].string[0];
        obj.time = result.params.param[9].value[0].array[0].data[0].value[1].string[0];
        obj.errorCode = parseInt(result.params.param[1].value[0].array[0].data[0].value[1].string[0]);
        obj.name = result.params.param[14].value[0].array[0].data[0].value[1].string[0] || '';
        obj.place = result.params.param[5].value[0].array[0].data[0].value[1].string[0] || '';
        obj.street = result.params.param[13].value[0].array[0].data[0].value[1].string[0] || '';
        obj.postcode = result.params.param[7].value[0].array[0].data[0].value[1].string[0] || '';
        obj.result_name = result.params.param[10].value[0].array[0].data[0].value[1].string[0] || '';
        obj.result_place = result.params.param[8].value[0].array[0].data[0].value[1].string[0] || '';
        obj.result_street = result.params.param[15].value[0].array[0].data[0].value[1].string[0] || '';
        obj.result_postcode = result.params.param[4].value[0].array[0].data[0].value[1].string[0] || '';
        obj.valid_from = result.params.param[11].value[0].array[0].data[0].value[1].string[0] || '';
        obj.valid_to = result.params.param[12].value[0].array[0].data[0].value[1].string[0] || '';

        var crypt = new Crypt();

        await that.countStatus(obj);
        await that.setState({ arr: [...that.state.arr, obj] });

        const auth = await JSON.parse(localStorage.getItem('auth'));
        const publicKey = auth.license.publickey;
        var encrypted = crypt.encrypt(publicKey, JSON.stringify(obj));
        await that.props.saveConfirmation(JSON.stringify({ data: encrypted, import_id: that.state.importID, }));
        if (that.props.savedConfirmationResponse) {
          return true;
        }
      });
    }
  }

  countStatus(value) {
    var arr = [value.result_name, value.result_place, value.result_postcode, value.result_street];

    /**
     * The responses with A, B, C, D are actually known to our users. We provide an explanation on some points, but for some accounting software these codes should not be translated.
     */
    var allA = arr.filter(x => x === 'A').length;
    var allB = arr.filter(x => x === 'B').length;
    var allC = arr.filter(x => x === 'C').length;
    var allD = arr.filter(x => x === 'D').length;

    if (allA === 4) {
      this.setState({ a: this.state.a + 1 });
    }
    if (allB > 0 && allC === 0 && allD === 0) {
      this.setState({ b: this.state.b + 1 });
    }
    if (allC > 0 && allD === 0) {
      this.setState({ c: this.state.c + 1 });
    }
    if (allD > 0) {
      this.setState({ d: this.state.d + 1 });
    }
  }

  sendDate = async (value, sorts) => {
    return await this.onSubmit(value, sorts).then(newVall => {
      const newArr = newVall;
    });
  };

  async startImporter(sorts, data) {
    await this.props.importRequest();

    if (this.props.importResponse) {
      const json = this.props.importResponse;
      this.setState({ importID: json.id });
    }

    for (let index = 0; index < data.length; index++) {
      await this.sendDate(data[index], sorts);

      if (data.length === index + 1) {
        await this.loadLocal();
        this.setState({ isDone: true });
      }
    }
  }

  render() {
    const BorderLinearProgress = withStyles(theme => ({
      root: {
        height: 5,
        borderRadius: 2.5,
      },
      colorPrimary: {
        backgroundColor: '#C0CAE0',
      },
      bar: {
        borderRadius: 2.5,
        backgroundColor: this.state.arr.length === this.state.data.length ? '#00D020' : '#2A559B',
      },
    }))(LinearProgress);
    return (
      <div style={{ width: '100%', marginBottom: 24 }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <p style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {this.state.arr.length === this.state.data.length ? (
              <div style={{ display: 'flex', alignItems: 'center', width: '100%', flexDirection: 'row' }}>
                <DoneIcon color="#00D020" size={22} style={{ marginRight: 12 }} />
                <p>Ihre Anfragen wurden erfolgreich gesendet!</p> {/* @TODO Translate: Your requests have been successfully send! */}
              </div>
            ) : (
              <>
                <CircularProgress size={22} style={{ marginRight: 12 }} />
                <p>Ihre Anfragen werden gestellt…</p> {/* @TODO Translate: Your requests are being send! */}
              </>
            )}
          </p>
          <BorderLinearProgress variant="determinate" value={Math.round((100 / this.state.data.length) * this.state.arr.length)} />
          <h3 style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            {this.state.arr.length} / {this.state.data.length} Bestätigungen angefragt
          </h3> {/* @TODO Translate: Confirmation requested! */}
          <div>
            <div className="separator">Einfache Abfrage</div> {/* @TODO Translate: Simple request */}
            <span className="resultsbox">
              <CheckCircleOutlineIcon /> <b>{this.state.arr.filter(x => parseInt(x.errorCode) === 200).length}</b>
              <p>USt-IdNrn. sind gültig</p>
            </span> {/* @TODO Translate: VAT registration numbers are valid */}
            <span className="resultsbox">
              <CheckCircleOutlineIcon /> <b>{this.state.arr.filter(x => parseInt(x.errorCode) > 200).length}</b>
              <p>USt-IdNrn. sind ungültig</p>
            </span> {/* @TODO Translate: VAT registration numbers are invalid */}
            <div className="separator">Qualifizierte Abfrage</div> {/* @TODO Translate: Extended request */}
            <span className="resultsboxsm">
              <CheckCircleOutlineIcon /> <b>{this.state.a}</b>
              <p>stimmen vollständig überein</p>
            </span> {/* @TODO Translate: totally coincide */}
            <span className="resultsboxsm">
              <CheckCircleOutlineIcon /> <b>{this.state.b}</b>
              <p>enthalten mindestens eine Abweichung</p>
            </span> {/* @TODO Translate: at least one discrepancy */}
            <span className="resultsboxsm">
              <CheckCircleOutlineIcon /> <b>{this.state.c}</b>
              <p>enthalten mindestens ein nichtangefragtes Feld</p>
            </span> {/* @TODO Translate: contain at least one unrequested field */}
            <span className="resultsboxsm">
              <CheckCircleOutlineIcon /> <b>{this.state.d}</b>
              <p>enthalten mindestens ein Feld ohne Rückmeldung</p>
            </span> {/* @TODO Translate: at least one field without response */}
          </div>
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            {this.state.isDone && (
              <Button variant="contained" color="primary">
                <Link to="/confirmations" className="customButtonLight">
                  Zur Ergebnisliste
                </Link>
              </Button>
            )}
            {/* @TODO Translate: Go to results */}
          </div>
        </div>
      </div>
    );
  }
}

// Prop types of props.
Uploader.propTypes = {
  evatRequest: PropTypes.func,
  evatRequestResponse: PropTypes.any,

  importRequest: PropTypes.func,
  importResponse: PropTypes.any,

  saveConfirmation: PropTypes.func,
  savedConfirmationResponse: PropTypes.any,

  getConfirmationList: PropTypes.func,
  confirmationListResponse: PropTypes.array
}

// Set default props.
Uploader.defaultProps = {
  evatRequest: () => { },
  evatRequestResponse: null,

  importRequest: () => { },
  importResponse: null,

  saveConfirmation: () => { },
  savedConfirmationResponse: null,

  getConfirmationList: () => { },
  confirmationListResponse: []
}

export default compose(importerCTX)(Uploader);
