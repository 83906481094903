import Dexie from 'dexie';
import { getStartEndDateByDuration } from '../resources/confirmations/components/helper';
import Moment from 'moment';
import { DurationEnum } from '../resources/confirmations/components/Pdfview-modal/DurationEnum';

const db = new Dexie('zfu');
db.version(1).stores({
  confirmations:
    '++id, ustid1, ustid2, name, error_code, street, place, postcode, date, time, result_name, result_place, result_postcode, result_street, valid_from, valid_to, print, import_id, license_id, user_id, created_at, updated_at',
});

export default {
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const result = db
      .transaction('rw', db.confirmations, async () => {
        const res = {};
        const confirmations = await db.confirmations.orderBy(field); // QUERY START

        // FILTER START
        if (params.filter) {
          if (params.filter.q) {
            confirmations.filter(function (confirmations) {
              return confirmations.name.toLowerCase().indexOf((params.filter.q).toLowerCase()) !== -1;
            });
          }
          const filter = Object.entries(params.filter);
          filter.map((value, key) => {
            return confirmations.filter(function (confirmations) {
              if (value[0] === 'q') {
                return confirmations.name.toLowerCase().indexOf((params.filter.q).toLowerCase()) !== -1;
              }
              if (value[0] === 'created_at') {
                return confirmations.created_at.includes(params.filter.created_at);
              }
              if (value[0] === 'import_id') {
                return confirmations.import_id === value[1];
              }
              if (value[0] === 'duration') {
                const duration = getStartEndDateByDuration(value[1]);
                let startDate = Moment();
                let endDate = Moment();
                const startDateObj = filter.find((data) => data[0] === 'start_date');
                const endDateObj = filter.find((data) => data[0] === 'end_date');
                startDate = startDateObj ? startDateObj[1] : Moment();
                endDate = endDateObj ? endDateObj[1] : Moment();
                const createdAt = Moment(confirmations.created_at).format('YYYY-MM-DD');
                if (value[1] === DurationEnum.CHOOSE_INDIVIDUAL) {
                  return ((new Date(createdAt)).getTime() >= (new Date(startDate)).getTime())
                  && ((new Date(createdAt)).getTime() <= (new Date(endDate)).getTime());
                }

                return ((new Date(createdAt)).getTime() >= (new Date(duration.startDate)).getTime())
                  && ((new Date(createdAt)).getTime() <= (new Date(duration.endDate)).getTime());
              }
              if (value[0] === 'start_date') {
                const createdAt = Moment(confirmations.created_at).format('YYYY-MM-DD');
                return ((new Date(createdAt)).getTime() >= (new Date(value[1])).getTime());
              }
              if (value[0] === 'end_date') {
                const createdAt = Moment(confirmations.created_at).format('YYYY-MM-DD');
                return ((new Date(createdAt)).getTime() <= (new Date(value[1])).getTime());
              }
              if (value[0] === 'is_valid') {
                return confirmations.error_code === 200;
              }
              if (value[0] === 'is_invalid') {
                return confirmations.error_code !== 200;
              }

              return confirmations[value[0]] === (value[0] === 'error_code' ? parseInt(value[1]) : value[1]);
            });
          });
        }
        // FILTER END

        const total = await confirmations.toArray(); // GET TOTAL QUERY ENTRYS

        if (order === 'DESC') {
          confirmations
            .reverse() // DESC
            .offset((page - 1) * perPage, page * perPage) // PAGNATION
            .limit(perPage); // PAGNATION LIMIT
        } else {
          confirmations
            .offset((page - 1) * perPage, page * perPage) // PAGNATION
            .limit(perPage); // PAGNATION LIMIT
        }

        res.data = await confirmations.toArray();
        res.total = total.length;
        return res;
      })
      .catch(e => {
        console.error(e.stack || e); // ERROR MESSAGE CONSOLE
      });

    return result; // OUTPUT
  },
  getOne: async (resource, params) => {
    const res = {};
    const result = db
      .transaction('rw', db.confirmations, async () => {
        const confirmation = await db.confirmations.where('id').equals(parseInt(params.id)).limit(1).toArray();
        res.data = confirmation[0];
        return res;
      })
      .catch(e => {
        console.error(e.stack || e);
      });

    return result; // OUTPUT
  },
};
