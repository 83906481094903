import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { FilterSelection } from './steps/filterSelection';
import Dexie from 'dexie';
import Moment from "moment";
import { compose } from 'redux';
import confirmationsCTX from '../confirmationsCTX';
import qs from 'qs';

import { PreviewSection } from "./steps/previewSection";
import { DownloadPDF } from './steps/downloadPDF';
import { filterdConfirmationData } from '../helper';

const db = new Dexie('zfu');
db.version(1).stores({
  confirmations:
    '++id, ustid1, ustid2, name, error_code, street, place, postcode, date, time, result_name, result_place, result_postcode, result_street, valid_from, valid_to, print, import_id, license_id, user_id, created_at, updated_at',
});

const useStyles = makeStyles(theme => ({
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  wrapperClass: {
    margin: '5px 50px'
  }
}));

function getSteps() {
  return ['Bestätigungen filtern', 'Vorschau', 'Archivieren']; // @TODO Translate'
}

function PdfViewStepWizard(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [confirmationList, setConfirmationList] = React.useState([]);
  const [filteredConfirmationList, setFilteredConfirmationList] = React.useState([]);
  const [startDate, setStartDate] = React.useState(Moment());
  const [endDate, setEndDate] = React.useState(Moment());
  const [isGeneratedPDFView, setPDFView] = React.useState(false);

  const steps = getSteps();

  React.useEffect(() => {
    setConfirmations();
  }, []);

  const onSelectImport = (importId) => {
    setFilteredConfirmations(importId, null, null);
    setPDFView(true);
  }

  const onStartDateChange = (date) => {
    setStartDate(date);
    setFilteredConfirmations(null, date, endDate);
    setPDFView(true);
  }

  const onEndDateChange = (date) => {
    setEndDate(date);
    setFilteredConfirmations(null, startDate, date);
    setPDFView(true);
  }

  const onSelectDurationRange = (startDate, endDate) => {
    setFilteredConfirmations(null, startDate, endDate);
  }

  const setConfirmations = () => {
    db.transaction('rw', db.confirmations, async () => {
      const confirmationTable = await db.confirmations;
      const list = await confirmationTable.toArray();
      return list;
    }).then(result => {
      setConfirmationList(result);
      setMainPageFilteredConfirmationList();
      setPDFView(true);
      return result;
    });
  }

  const setFilteredConfirmations = (importId, startDate, endDate) => {
    let filteredConfirmationList = confirmationList;
    if (importId) {
      filteredConfirmationList = confirmationList.filter((confirmation) => confirmation.import_id === importId);
    }

    if (startDate && endDate) {
      filteredConfirmationList = confirmationList.filter((confirmation) => {
        const date = Moment(confirmation.created_at).format('YYYY-MM-DD');
        if ((new Date(date)).getTime() >= (new Date(startDate)).getTime()
          && (new Date(date)).getTime() <= (new Date(endDate)).getTime()) {
          return confirmation;
        }
      });
    }
    setFilteredConfirmationList(filteredConfirmationList);
    return filteredConfirmationList;
  }

  const setMainPageFilteredConfirmationList = () => {
    const parsedQueryParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const field = parsedQueryParams && parsedQueryParams.sort ? parsedQueryParams.sort : 'id';
    const order = parsedQueryParams && parsedQueryParams.order ? parsedQueryParams.order : 'DESC';

    const filters = parsedQueryParams && parsedQueryParams.filter ? JSON.parse(parsedQueryParams.filter) : null;
    filterdConfirmationData(field, order, filters)
    .then((response) => {
      setFilteredConfirmationList(response.data);
      setPDFView(true);
    });
  }

  const handleNextWithBase64 = async (base64) => {
    handleNext();
    await props.requestToSignPDF(base64);
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <FilterSelection
            {...props}
            confirmationList={confirmationList}
            onSelectImport={onSelectImport}
            onSelectDurationRange={onSelectDurationRange}
            onStartDateChange={onStartDateChange}
            onEndDateChange={onEndDateChange}
            handleNext={handleNext} />
        );
      case 1:
        return <div className={classes.wrapperClass}>
          <PreviewSection
            {...props}
            confirmationList={filteredConfirmationList}
            isGeneratedPDFView={isGeneratedPDFView}
            handleBack={handleBack}
            handleNext={handleNextWithBase64} />
        </div>;
      case 2:
        return <div className={classes.wrapperClass}>
          <DownloadPDF {...props} />
        </div>;
      default:
        return 'Unknown stepIndex';
    }
  }

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>All steps completed</Typography> {/* @TODO Translate to german: Vorgang abgeschlossen */}
            <div style={{ flexDirection: 'row', justifyContent: 'flex-end', display: 'flex', padding: 20 }}>
              <Button>Zur Ergebnisliste</Button> {/* @TODO Translate: See results */}
            </div>
          </div>
        ) : (
          <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>{getStepContent(activeStep)}</div>
          </div>
        )}
      </div>
    </Grid>
  );
}

// Prop types of props.
PdfViewStepWizard.propTypes = {
  requestToSignPDF: PropTypes.func,
  signPDFResponse: PropTypes.obj,
  signPDFLoading: PropTypes.bool,
  signPDFError: PropTypes.bool,
  signPDFErrorMessage: PropTypes.string
}

// Set default props.
PdfViewStepWizard.defaultProps = {
  requestToSignPDF: () => { },
  signPDFResponse: {},
  signPDFError: false,
  signPDFErrorMessage: ''
}

export default compose(confirmationsCTX)(PdfViewStepWizard);
