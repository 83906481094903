import Moment from 'moment';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { InputLabel } from '@material-ui/core';
import { SelectInput } from 'react-admin';
import * as _ from 'lodash';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
  },
}));

const ImportSelect = props => {
  const classes = useStyles();

  if (!props.confirmationListResponse) {
    return <></>;
  }

  const options = props.confirmationListResponse;
  const group = _.groupBy(options, 'import_id');
  const array = Object.entries(group);
  const choiceArray = [];
  array.map(value => {
    if (value[1] && value[1].length > 0) {
      const item = value[1].filter(obj => obj.import_id);
      if (item && item.length > 0) {
        // eslint-disable-next-line camelcase
        const aId = item[0]?.import_id;
        const aName = Moment(item[item.length - 1].created_at).format('DD.MM.YYYY HH:mm');
        return choiceArray.push({ id: aId, name: aName });
      }
      return [];
    }
    return [];
  });
  return (
    <FormControl variant="filled" style={{ marginTop: 0 }} className={classes.formControl} size="small">
      <InputLabel htmlFor="import_id">{props.label}</InputLabel>
      <SelectInput
        label="Anfrage wählen"
        {...props.input}
        source="import_id"
        choices={choiceArray.reverse()}
        allowEmpty
        emptyText={'Alles anzeigen'}
        onChange={props.onSelectImport}
      />
    </FormControl>
  );
};

export default ImportSelect;
