import React from 'react';
import PropTypes from 'prop-types';

class FakeInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={{ padding: 10, backgroundColor: 'rgba(0, 0, 0, 0.04)', borderRadius: 4, margin: 10, marginLeft: 0, fontWeight: 'bold' }}>
        {this.props.name}
        {this.props.isRequired ? '*' : ''}
      </div>
    );
  }
}

FakeInput.propTypes = {
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool || false,
};

export default FakeInput;
