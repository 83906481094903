import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import PdfViewStepWizard from './pdfViewStepWizard';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    textAlign: 'center',
    color: 'var(--navy-blue)'
  },
  title: {
    fontWeight: 900
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
}));

export default function PDFViewModal(props) {
  const classes = useStyles();
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={props.open} 
        onClose={props.handleClose} aria-labelledby="pdf-dialog">
        <DialogTitle className={classes.dialogTitle} disableTypography={true} id="pdf-dialog">
          <h2 className={classes.title}>Revisionssicheres PDF</h2>
          <IconButton aria-label="close" className={classes.closeButton} onClick={props.handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {props.children}
        </DialogContent>
      </Dialog>
    </>
  );
}