import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import { apiUrl } from '@zfu-utils';
const httpClient = fetchUtils.fetchJson;
const user = JSON.parse(localStorage.getItem('auth'));
export default {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const user = JSON.parse(localStorage.getItem('auth'));
    const query = {
      page: page,
      field: field,
      order: order,
      perPage: perPage,
      search: params.filter.q,
      filter: JSON.stringify(params.filter),
    };
    const url = process.env.REACT_APP_API_URL + '/' + resource + '?' + stringify(query);

    const result = httpClient(url, {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.access_token,
      }),
    }).then(({ json }) => ({
      data: json.data,
      total: json.total,
    }));
    return result;
  },
  getOne: (resource, params) => {
    const headers = {
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.access_token,
      }),
    };
    if (resource === 'confirmationUserList') {
      const url = apiUrl + '/user/confirmation/list-users-who-requested';
      return httpClient(url, headers).then(({ json }) => ({ data: json }));
    }
    return httpClient(apiUrl + '/' + resource + '/' + params.id, headers).then(({ json }) => ({
      data: json,
    }));
  },

  getMany: (resource, params) => {
    const query = {
      ids: JSON.stringify({ id: params.ids }),
    };
    const url = apiUrl + '/' + resource + '?' + stringify(query);
    return httpClient(url, {
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.access_token,
      }),
    }).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const user = JSON.parse(localStorage.getItem('auth'));
    const { field, order } = params.sort;
    const query = {
      page: page,
      field: field,
      order: order,
      perPage: perPage,
      search: params.filter.q,
      event_categoryid: params.filter.event_categoryid,
      filter: JSON.stringify(params.filter),
    };
    const url = apiUrl + '/' + resource + '?' + stringify(query);

    return httpClient(url, {
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.access_token,
      }),
    }).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get('content-range').split('/').pop(), 10),
    }));
  },

  update: (resource, params) =>
    httpClient(apiUrl + '/' + resource + '/' + params.id, {
      method: 'PUT',
      body: JSON.stringify(params.data),
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.access_token,
      }),
    }).then(({ json }) => ({ data: json })),
  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(apiUrl + '/' + resource + '?' + stringify(query), {
      method: 'PUT',
      body: JSON.stringify(params.data),
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.access_token,
      }),
    }).then(({ json }) => ({ data: json }));
  },

  create: (resource, params) =>
    httpClient(apiUrl + '/' + resource, {
      method: 'POST',
      body: JSON.stringify(params.data),
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.access_token,
      }),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),

  delete: (resource, params) =>
    httpClient(apiUrl + '/' + resource + '/' + params.id, {
      method: 'DELETE',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.access_token,
      }),
    }).then(({ json }) => ({ json })),

  deleteMany: (resource, params) => {
    return httpClient(apiUrl + '/' + resource + '/' + params.ids, {
      method: 'DELETE',
      body: JSON.stringify(params.data),
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.access_token,
      }),
    }).then(({ json }) => ({ data: json }));
  },
};
