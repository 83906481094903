import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Moment from 'moment';
import Radio from '@material-ui/core/Radio';
import { MenuItem, InputLabel, TextField } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { DurationEnum } from '../DurationEnum';
import Button from '@material-ui/core/Button';
import * as _ from 'lodash';

const FilterSelectionEnum = Object.freeze({
  CURRENT_VIEW: 'curent-view',
  IMPORT_SELECTION: 'import-selection',
  DURATION_SELECTION: 'duration-selection'
});

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    margin: '5px 0px'
  },
  filterGroup: {
    margin: '10px 0px'
  },
  filterDurationGroup: {
    margin: '10px 0px',
    display: 'flex',
    flexDirection: 'row'
  },
  textField: {
    margin: '10px 40px'
  },
  description: {
    color: 'var(--blue)',
    fontSize: '16px',
    fontWeight: '600'
  },
  errorMessage: {
    textAlign: 'center',
    color: 'red'
  }
}));

const ImportSelectDropdown = props => {
  const [selectedImportId, setImportId] = React.useState('');

  React.useEffect(() => {
    if (!props.selectedImport) {
      setImportId('');
    }
  }, [props.selectedImport]);

  const selectImport = (event) => {
    if (event.target.value) {
      setImportId(event.target.value);
      props.onSelectImport(event.target.value);
    }
  };

  const classes = useStyles();
  if (!props.confirmationList) {
    return <></>;
  }
  const options = props.confirmationList;
  const group = _.groupBy(options, 'import_id');

  const array = Object.entries(group);
  // RENDER ITEMS
  return (
    <FormControl variant="filled" className={classes.formControl} style={{ width: '80%' }}>
      <InputLabel htmlFor="import-section">Import wählen...</InputLabel>
      <Select
        variant="filled"
        id="import-section"
        name="import-section"
        defaultValue=""
        value={selectedImportId}
        autoWidth={true}
        disabled={props.disabled}
        onChange={selectImport}
      >
        {array.map(value => {
          if (value[1] && value[1].length > 0) {
            const item = value[1].filter((obj) => obj.import_id);
            if (item && item.length > 0) {
              return (
                <MenuItem key={item[0].import_id} value={item[0].import_id}>
                  {Moment(item[item.length - 1].created_at).format('DD.MM.YYYY hh:mm')} (#{item[0].import_id})
                </MenuItem>
              );
            }
            return <></>;
          }
        }).reverse()}
      </Select>
    </FormControl>
  );
};

export function FilterSelection(props) {
  const classes = useStyles();
  const [radioValue, setRadioValue] = React.useState(FilterSelectionEnum.CURRENT_VIEW);
  const [selectedImport, setSelectedImport] = React.useState('');
  const [startDate, setStartDate] = React.useState(Moment());
  const [endDate, setEndDate] = React.useState(Moment());
  const [selectedDuration, setSelectedDuration] = React.useState('');
  const [dateValidationError, setDateValidationError] = React.useState(false)

  React.useEffect(() => {

  }, []);

  const handleChange = (event) => {
    if (event.target.value === FilterSelectionEnum.DURATION_SELECTION) {
      setSelectedImport('');
    }

    if (event.target.value === FilterSelectionEnum.IMPORT_SELECTION) {
      setSelectedDuration('');
      setStartDate('');
      setEndDate('');
    }

    setRadioValue(event.target.value);
  };

  const onSelectImport = (id) => {
    setSelectedImport(id);
    props.onSelectImport(id);
  }

  const onSelectDuration = (event) => {
    const date = Moment();
    let startRange = date;
    let endRange = date;
    const selectedDuration = event.target.value;
    switch (selectedDuration) {
      case DurationEnum.CURRENT_MONTH:
        startRange = Moment(date).startOf('month');
        endRange = Moment(date).endOf('month');
        break;

      case DurationEnum.CURRENT_YEAR:
        startRange = Moment(date).startOf('year');
        endRange = Moment(date).endOf('year');
        break;

      case DurationEnum.PREVIOUS_MONTH:
        startRange = Moment(date).subtract(1, 'months').startOf('month');
        endRange = Moment(date).subtract(1, 'months').endOf('month');
        break;

      case DurationEnum.PREVIOUS_YEAR:
        startRange = Moment(date).subtract(1, 'year').startOf('year');
        endRange = Moment(date).subtract(1, 'year').endOf('year');
        break;

      case DurationEnum.CHOOSE_INDIVIDUAL:
        startRange = Moment();
        endRange = Moment();
        break;

      default:
        break;
    }

    const formatedStartDate = Moment(startRange).format('YYYY-MM-DD');
    const formatedEndDate = Moment(endRange).format('YYYY-MM-DD');
    setStartDate(formatedStartDate);
    setEndDate(formatedEndDate);
    setSelectedDuration(event.target.value);
    props.onSelectDurationRange(formatedStartDate, formatedEndDate);
  }

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
    setSelectedDuration(DurationEnum.CHOOSE_INDIVIDUAL);
    props.onStartDateChange(event.target.value);
    setDateValidationError(event.target.value > endDate);
  }

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
    setSelectedDuration(DurationEnum.CHOOSE_INDIVIDUAL);
    props.onEndDateChange(event.target.value);
    setDateValidationError(event.target.value < startDate);
  }

  return (
    <form noValidate>
      <h3 className={classes.title}>Bestätigungen filtern</h3>
      <p className={classes.description}>Bitte filtern Sie die Bestätigungen, die Sie revisionssicher ablegen möchten:</p>
      {radioValue === FilterSelectionEnum.CURRENT_VIEW && (
        <div className={classes.filterGroup}>
          <Radio
            onChange={handleChange}
            value={FilterSelectionEnum.CURRENT_VIEW}
            id="current-view"
            checked={radioValue === FilterSelectionEnum.CURRENT_VIEW}
            name="filter-selection"
          />
          <label htmlFor="current-view">Aktuell gefilterte Liste (Bildschirmansicht)</label>
        </div>
      )}
      <div className={classes.filterGroup}>
        <Radio
          onChange={handleChange}
          value={FilterSelectionEnum.IMPORT_SELECTION}
          checked={radioValue === FilterSelectionEnum.IMPORT_SELECTION}
          name="filter-selection"
        />
        <ImportSelectDropdown
          confirmationList={props.confirmationList}
          selectedImport={selectedImport}
          disabled={radioValue === FilterSelectionEnum.CURRENT_VIEW || radioValue === FilterSelectionEnum.DURATION_SELECTION}
          onSelectImport={onSelectImport} />
      </div>
      <div className={classes.filterGroup}>
        <Radio
          onChange={handleChange}
          value={FilterSelectionEnum.DURATION_SELECTION}
          name="filter-selection"
          checked={radioValue === FilterSelectionEnum.DURATION_SELECTION}
        />
        <FormControl variant="filled" className={classes.formControl} style={{ width: '80%' }}>
          <InputLabel htmlFor="duration">Zeitraum wählen...</InputLabel>
          <Select
            variant="filled"
            id="duration"
            name="duration"
            defaultValue=""
            value={selectedDuration}
            autoWidth={true}
            onChange={onSelectDuration}
            disabled={radioValue === FilterSelectionEnum.CURRENT_VIEW || radioValue === FilterSelectionEnum.IMPORT_SELECTION}
          >
            <MenuItem value={DurationEnum.CURRENT_MONTH}>Aktueller Monat</MenuItem>
            <MenuItem value={DurationEnum.CURRENT_YEAR}>Aktuelles Jahr</MenuItem>
            <MenuItem value={DurationEnum.PREVIOUS_MONTH}>Vormonat</MenuItem>
            <MenuItem value={DurationEnum.PREVIOUS_YEAR}>Vorjahr</MenuItem>
            <MenuItem value={DurationEnum.CHOOSE_INDIVIDUAL}>Individueller Zeitraum...</MenuItem>
          </Select>
        </FormControl>
      </div>
      {radioValue === FilterSelectionEnum.DURATION_SELECTION && (
        <>
          <div className={classes.filterDurationGroup}>
            <TextField
              id="start-date"
              label="Start Date"
              type="date"
              defaultValue=""
              value={startDate}
              name="start-date"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleStartDateChange}
            />
            <TextField
              id="end-date"
              label="End Date"
              type="date"
              defaultValue=""
              value={endDate}
              name="end-date"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleEndDateChange}
            />
          </div>
          {dateValidationError && (
            <div className={classes.errorMessage}>End date should be greater than start date.</div>
          )}
        </>
      )}
      <div style={{ width: '100%', flexDirection: 'row', justifyContent: 'flex-end', display: 'flex', paddingTop: 20, paddingBottom: 14 }}>
        <Button
          variant="contained"
          disabled={!(radioValue &&
            (selectedImport || (selectedDuration && !dateValidationError) || radioValue === FilterSelectionEnum.CURRENT_VIEW))}
          color="primary"
          onClick={props.handleNext}>
          Weiter {/* @TODO Translate */}
        </Button>
      </div>
    </form>
  );
}
