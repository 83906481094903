export const defaultStore = {
  // AUTHENTICATION (FORGET-PASSWORD)
  forgetPassword: {
    loading: false,
    forgetPasswordError: false,
    forgetPasswordErrorMessage: null,
    forgetPasswordResponse: null
  },

  resetPassword: {
    loading: false,
    resetPasswordError: false,
    resetPasswordErrorMessage: null,
    resetPasswordResponse: null
  },

  // AUTHENTICATION (CHECK-LICENSE)
  checkLiscenseResponse: {
    loading: false,
    checkLicenseError: false,
    checkLicenseErrorMessage: null,
    checkLicenseResponse: null
  },

  // CONFIRMATION RESPONSE
  confirmationListResponse: {
    loading: false,
    confirmationError: false,
    confirmationErrorMessage: null,
    confirmationList: null
  },

  // CONFIRMATION SAVE RESPONSE
  confirmationSaveResponse: {
    loading: false,
    confirmationSaveError: false,
    confirmationSaveErrorMessage: null,
    confirmationSaveResponse: null
  },

  // SINGLE REQUEST INPUT RESPONSE
  importerResponse: {
    loading: false,
    evatRequestError: false,
    evatRequestErrorMessage: null,
    evatRequestResponse: null
  },

  // IMPORT REQUEST AND RESPONSE
  import: {
    loading: false,
    importError: false,
    importErrorMessage: null,
    importResponse: null
  },

  // SIGN PDF
  signPDF: {
    loading: false,
    signPDFError: false,
    signPDFErrorMessage: null,
    signPDFResponse: null
  }
}

export default Object.freeze(defaultStore);
