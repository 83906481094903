import React from 'react';
import '@zfu-styles';
import { Admin, Resource, Authenticated } from 'react-admin';
import { Route } from "react-router-dom";
import { ConfirmationsList, ConfirmationsShow } from './resources/confirmations';
import { LicenseList, LicenseShow, LicenseCreate, LicenseEdit } from './resources/license';
import { UserList, UserCreate, UserEdit, UserShow, MyProfil } from './resources/user';
import { ForgetPassword, MyLogin, RegisterLicense, ResetPassword } from './resources/auth';
import { authProvider, masterDataProvider } from './provider';
import Dashboard from './resources/dashboard';
import { ImportList } from './resources/imports/list';
import { SupervisorAccountSharp, AccountBox } from '@material-ui/icons';
import { Importer, SingleRequest } from './resources/importer';
import { createMuiTheme } from '@material-ui/core/styles';
import MyLayout from './theme/Layout';
import germanMessages from 'ra-language-german';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import blue from '@material-ui/core/colors/blue';
import MyLogoutButton from './theme/logoutButton';
import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux';
import createAdminStore from './redux-utils/createAdminStore';
import { PermissionEnum } from './shared/enums/PermissionEnum';

const keymap = {
  TODO_ITEM: {
    MOVE_LEFT: 'left',
    MOVE_RIGHT: 'right',
    MOVE_UP: ['up', 'w'],
    DELETE: {
      osx: ['command+n'],
      windows: 'windows+n',
      linux: 'windows+n',
    },
  },
};
const App = () => {
  const colorScheme = 'light';
  const history = createBrowserHistory();
  const i18nProvider = polyglotI18nProvider(() => germanMessages, 'de');

  const myTheme = createMuiTheme({
    palette: {
      type: colorScheme,
      primary: {
        main: blue[700],
        contrastText: '#fff',
      },
      secondary: {
        main: blue[900],
        contrastText: '#fff',
      },
      typography: {
        fontFamily: ['Nunito'].join(','),
        body1: {
          fontWeight: 500,
        },
      },
    },
  });

  return (
    <Provider
      store={createAdminStore({
        authProvider,
        masterDataProvider,
        history,
      })}
    >
      <Admin
        disableTelemetry
        title="ZFU"
        history={history}
        i18nProvider={i18nProvider}
        dashboard={Dashboard}
        authProvider={authProvider}
        theme={myTheme}
        layout={MyLayout}
        loginPage={MyLogin}
        dataProvider={masterDataProvider}
        logoutButton={MyLogoutButton}
        customRoutes={[
          <Route key="register" exact path="/register" component={props => <RegisterLicense {...props} theme={myTheme} />}  noLayout />,
          <Route key="forget-password" exact path="/forget-password" component={props => <ForgetPassword {...props} theme={myTheme} />} noLayout />,
          <Route key="reset-password" exact path="/reset-password" component={props => <ResetPassword {...props} theme={myTheme} />} noLayout />,
          <Route
            key="importer"
            exact
            path="/importer"
            render={props => (
              <Authenticated>
                <Importer {...props} />
              </Authenticated>
            )}
          />,
          <Route
            key="newrequest"
            exact
            path="/newrequest"
            render={props => (
              <Authenticated>
                <SingleRequest {...props} />
              </Authenticated>
            )}
          />,
          <Route
            key="my-profile"
            exact
            path="/my-profile"
            render={props => (
              <Authenticated>
                <MyProfil {...props} />
              </Authenticated>
            )}
          />,
        ]}
      >
        {permissions => [
          <Resource
            key="confirmations"
            name="confirmations"
            list={ConfirmationsList}
            show={ConfirmationsShow}
            options={{ label: 'Bestätigungen' }}
          />,
          <Resource key="import" name="import" list={ImportList} />,
          permissions === PermissionEnum.SUPER_ADMIN || permissions === PermissionEnum.ADMIN ? (
            <Resource
              key="user"
              name="user"
              list={UserList}
              create={UserCreate}
              edit={UserEdit}
              show={UserShow}
              options={{ label: 'Konten' }}
              icon={SupervisorAccountSharp}
            />
          ) : null,
          permissions === PermissionEnum.SUPER_ADMIN ? (
            <Resource
              key="license"
              name="license"
              list={LicenseList}
              show={LicenseShow}
              create={LicenseCreate}
              edit={LicenseEdit}
              options={{ label: 'Lizenzen' }}
              icon={AccountBox}
            />
          ) : null,
          ,
        ]}
      </Admin>
    </Provider>
  );
};

export default App;
