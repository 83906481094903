export const confirmation = {
    REQUEST_CONFIRMATION_LIST: 'REQUEST_CONFIRMATION_LIST',
    RECEIVE_CONFIRMATION_LIST: 'RECEIVE_CONFIRMATION_LIST',
    RECEIVE_CONFIRMATION_LIST_FAILURE: 'RECEIVE_CONFIRMATION_LIST_FAILURE',

    REQUEST_SIGN_PDF: 'REQUEST_SIGN_PDF',
    RECEIVE_SIGN_PDF: 'RECEIVE_SIGN_PDF',
    RECEIVE_SIGN_PDF_FAILURE: 'RECEIVE_SIGN_PDF_FAILURE'
}

export default confirmation;