import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import { apiUrl } from '@zfu-utils';
import { authentication as T } from './constants';
import { handleError, handleResponse, getConfig } from '../../reducers/api';

// Check license
export const requestCheckLicense = () => ({
	type: T.REQUEST_CHECK_LICENSE
});

export const receiveCheckLicense = checkLicenseResponse => ({
	type: T.RECEIVE_CHECK_LICENSE,
	checkLicenseResponse
});

export const receiveCheckLicenseFailure = error => ({
	type: T.RECEIVE_CHECK_LICENSE_FAILURE,
	error
});

/**
 * Check license
 *
*/
export const checkLicense = (requestData) => dispatch => {
	dispatch(requestCheckLicense());
	const finalUrl = apiUrl + '/check-license';
	return trackPromise(axios.post(finalUrl, getConfig(), requestData)
		.then(response => handleResponse(response))
		.then(result => {
				if (result) {
					dispatch(receiveCheckLicense(result));
				}
		})
		.catch(error =>
			handleError(error, dispatch, receiveCheckLicenseFailure, 'Check License')
		));
}

// Forget password
export const requestForgetPassword = () => ({
	type: T.REQUEST_FORGET_PASSWORD
});

export const receiveForgetPassword = response => ({
	type: T.RECEIVE_FORGET_PASWWORD,
	response
});

export const receiveForgetPasswordFailure = error => ({
	type: T.RECEIVE_FORGET_PASWWORD_FAILURE,
	error
});

/**
 * Forget password
 *
*/
export const forgetPassword = (requestData) => dispatch => {
	dispatch(requestForgetPassword());
	const finalUrl = apiUrl + '/password/email';
	return trackPromise(axios.post(finalUrl, requestData)
		.then(response => handleResponse(response))
		.then(result => {
			dispatch(receiveForgetPassword(result));
		})
		.catch(error =>
			handleError(error, dispatch, receiveForgetPasswordFailure, error.response.data.message)
		));
}

// Reset password
export const requestResetPassword = () => ({
	type: T.REQUEST_RESET_PASSWORD
});

export const receiveResetPassword = response => ({
	type: T.RECEIVE_RESET_PASWWORD,
	response
});

export const receiveResetPasswordFailure = error => ({
	type: T.RECEIVE_RESET_PASWWORD_FAILURE,
	error
});

/**
 * Reset password
 *
*/
export const resetPassword = (requestData) => dispatch => {
	dispatch(requestResetPassword());
	const finalUrl = apiUrl + '/password/reset';
	return trackPromise(axios.post(finalUrl, requestData)
		.then(response => handleResponse(response))
		.then(result => {
			dispatch(receiveResetPassword(result));
		})
		.catch(error =>
			handleError(error, dispatch, receiveResetPasswordFailure, error.response.data.message)
		));
}