import * as React from 'react';
import { List, Datagrid, ChipField } from 'react-admin';

export const ImportList = props => (
  <List {...props} title="Imports" bulkActionButtons={false}>
    <Datagrid>
      <ChipField source="id" label="USt-IdNr." size="small" variant="outlined" /> {/* @TODO Translate: VAT registration number */}
    </Datagrid>
  </List>
);
