import { combineReducers } from 'redux';
import confirmationReducer from '../resources/confirmations/reducers';
import authenticationReducer from '../resources/auth/reducers';
import importerReducer from '../resources/importer/reducers';

// Combine all reducers.
export default combineReducers({
  confirmationReducer,
  authenticationReducer,
  importerReducer
})
