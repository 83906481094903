import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FileUpload from './upload';
import Sorter from './sorter';
import Uploader from './uploader';
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Hochladen', 'Zuordnen', 'Bestätigung']; // @TODO Translate: 'Upload', 'Map', 'Confirmation'
}

export default function HorizontalLabelPositionBelowStepper(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [uploadedFile, setUploadedFile] = React.useState(null);
  const [parsedData, setParsedData] = React.useState([]);
  const steps = getSteps();

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <FileUpload
            preStep={handleBack}
            nextStep={handleNextWithFile}
            data={value}
            onchange={e => {
              onchange(e);
            }}
          />
        );
      case 1:
        return <Sorter uploadedFile={uploadedFile} preStep={handleBack} nextStep={handleNextWithData} />;
      case 2:
        return <Uploader parsedData={parsedData} preStep={handleBack} nextStep={handleNext} />;
      default:
        return 'Unknown stepIndex';
    }
  }

  const handleNextWithFile = (file) => {
    setUploadedFile(file);
    handleNext();
  }

  const handleNextWithData = (data) => {
    setParsedData(data);
    handleNext();
  }

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [value, setValue] = React.useState('');

  const onchange = data => {
    setValue(data);
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>All steps completed</Typography> {/* @TODO Translate to german: Vorgang abgeschlossen */}
            <div style={{ flexDirection: 'row', justifyContent: 'flex-end', display: 'flex', padding: 20 }}>
              <Button>Zur Ergebnisliste</Button> {/* @TODO Translate: See results */}
            </div>
          </div>
        ) : (
          <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>{getStepContent(activeStep)}</div>
          </div>
        )}
      </div>
    </Grid>
  );
}
