import React, { useState } from 'react';
import { useLogin, useNotify, Notification } from 'react-admin';
import { ThemeProvider } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import authCTX from './authCTX';
import { Link } from 'react-router-dom';

const ForgetPassword = (props) => {
  const [email, setEmail] = useState('');
  const notify = useNotify();

  React.useEffect(() => {
    if (props.forgetPasswordError) {
      notify(props.forgetPasswordErrorMessage);
    }
    if (props.forgetPasswordResponse) {
      notify(props.forgetPasswordResponse);
    }
  }, [props.forgetPasswordError]);

  React.useEffect(() => {
    if (props.forgetPasswordResponse) {
      notify(props.forgetPasswordResponse);
    }
  }, [props.forgetPasswordResponse]);

  const submit = async (e) => {
    e.preventDefault();
    await props.forgetPassword({ email: email });
  };

  return (
    <ThemeProvider theme={props.theme}>
      <form onSubmit={submit}>
        <div className="container">
          <div className="login-warpper">
            <div className="login-container">
              <h1>Passwort vergessen?</h1> {/* @TODO Translate: Forgot password? */}
              <TextField
                name="email"
                type="email"
                label="E-Mail" // @TODO Translate: e-mail
                required
                variant="filled"
                style={{ width: '100%', marginBottom: 24 }}
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <Button style={{ width: '100%', marginTop: 4 }} variant="contained" color="secondary" type="submit">
                Send Email
              </Button>
              <Link style={{ margin: 20 }} to="/login">Back to login</Link>
            </div>
          </div>
        </div>
      </form>
      <Notification />
    </ThemeProvider>
  );
};

// Prop types of props.
ForgetPassword.propTypes = {
  forgetPassword: PropTypes.func,
  forgetPasswordResponse: PropTypes.any,
  forgetPasswordError: PropTypes.bool,
  forgetPasswordErrorMessage: PropTypes.string
}

// Set default props.
ForgetPassword.defaultProps = {
  forgetPassword: () => { },
  forgetPasswordResponse: null,
  forgetPasswordError: false,
  forgetPasswordErrorMessage: ''
}

export default compose(authCTX)(ForgetPassword);
