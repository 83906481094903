import React, { useState } from 'react';
import { useLogin, useNotify, translate, Notification } from 'react-admin';
import { ThemeProvider } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const MyLogin = ({ theme }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const login = useLogin();
  const notify = useNotify();
  const submit = e => {
    e.preventDefault();
    // call the login callback returned by the useLogin hook
    login({ email, password }).catch(() => notify('E-Mail oder Passwort Stimmen nicht überein!')); // @TODO Translate: E-Mail or password to not match!
  };

  return (
    <ThemeProvider theme={theme}>
      <form onSubmit={submit}>
        <div className="container">
          <div className="login-warpper">
            <div className="login-container">
              <TextField
                name="email"
                type="email"
                label="E-Mail" // @TODO Translate: e-mail
                required
                variant="filled"
                style={{ width: '100%', marginBottom: 24 }}
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <TextField
                name="password"
                type="password"
                label="Passwort" // @TODO Translate: password
                required
                variant="filled"
                style={{ width: '100%', marginBottom: 24 }}
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              <Button style={{ width: '100%', marginTop: 4 }} variant="contained" color="secondary" type="submit">
                Login
                {translate('ra.auth.sign_in')}
              </Button>
              <Link style={{ margin: 20 }} to="/register"> Zur Produktaktivierung </Link> {/* @TODO Translate: Activate product */}
              <Link to="/forget-password"> Passwort vergessenn </Link>
            </div>
          </div>
        </div>
      </form>
      <Notification />
    </ThemeProvider>
  );
};

export default MyLogin;
