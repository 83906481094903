import * as React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Form, Field } from 'react-final-form';
import { Crypt } from 'hybrid-crypto-js';
import { Grid, Button, TextField as TextFormField, ThemeProvider, createMuiTheme } from '@material-ui/core';
import { TextFieldColor, StatusCode, StatusCodeDetails } from './helper';
import { Link, withRouter } from 'react-router-dom';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import PDFViewModal from './Pdfview-modal/pdfviewModal';
import importerCTX from '../../importer/components/importerCTX';
import PdfViewWizardSingleConfirmation from './Pdfview-modal/pdfViewWizardSingleConfirmation';
import Dexie from 'dexie';
var parseString = require('xml2js').parseString;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00d020',
    }
  },
});

const ref = React.createRef();
const db = new Dexie('zfu');
db.version(1).stores({
  confirmations:
    '++id, ustid1, ustid2, name, error_code, street, place, postcode, date, time, result_name, result_place, result_postcode, result_street, valid_from, valid_to, print, import_id, license_id, user_id, created_at, updated_at',
});

const DetailsConfirmation = props => {
  const [isFormVisible, setFormVisibility] = React.useState(false);
  const [authData, setAuthData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [isFormSubmitted, setFormSubmitted] = React.useState(false);
  const [isPDFModalVisible, setPDFModalVisibility] = React.useState(false);

  const privateKey = localStorage.getItem('privateKey');

  React.useEffect(() => {
    const data = JSON.parse(localStorage.getItem('auth'));
    setAuthData(data);

    return () => {
      setFormSubmitted(false);
    };
  }, []);

  React.useEffect(() => {
    if (props.evatRequestResponse && isFormSubmitted) {
      const response = props.evatRequestResponse;
      parseString(response, { trim: true }, async (err, result) => {
        const obj = {};
        obj.ustid1 = result.params.param[0].value[0].array[0].data[0].value[1].string[0];
        obj.ustid2 = result.params.param[2].value[0].array[0].data[0].value[1].string[0];
        obj.date = result.params.param[6].value[0].array[0].data[0].value[1].string[0];
        obj.print = result.params.param[3].value[0].array[0].data[0].value[1].string[0];
        obj.time = result.params.param[9].value[0].array[0].data[0].value[1].string[0];
        obj.errorCode = parseInt(result.params.param[1].value[0].array[0].data[0].value[1].string[0]);
        obj.name = result.params.param[14].value[0].array[0].data[0].value[1].string[0] || '';
        obj.place = result.params.param[5].value[0].array[0].data[0].value[1].string[0] || '';
        obj.street = result.params.param[13].value[0].array[0].data[0].value[1].string[0] || '';
        obj.postcode = result.params.param[7].value[0].array[0].data[0].value[1].string[0] || '';
        obj.result_name = result.params.param[10].value[0].array[0].data[0].value[1].string[0] || '';
        obj.result_place = result.params.param[8].value[0].array[0].data[0].value[1].string[0] || '';
        obj.result_street = result.params.param[15].value[0].array[0].data[0].value[1].string[0] || '';
        obj.result_postcode = result.params.param[4].value[0].array[0].data[0].value[1].string[0] || '';
        obj.valid_from = result.params.param[11].value[0].array[0].data[0].value[1].string[0] || '';
        obj.valid_to = result.params.param[12].value[0].array[0].data[0].value[1].string[0] || '';

        var crypt = new Crypt();

        const auth = await JSON.parse(localStorage.getItem('auth'));
        const publicKey = auth.license.publickey;
        var encrypted = crypt.encrypt(publicKey, JSON.stringify(obj));

        await props.saveConfirmation(JSON.stringify({ data: encrypted }));
      });
    }
  }, [props.evatRequestResponse]);

  React.useEffect(async () => {
    if (props.savedConfirmationResponse && isFormSubmitted) {
      await props.getConfirmationList();
      setLoading(false);
    }
  }, [props.savedConfirmationResponse]);

  const setConfirmationData = async (confirmationListResponse) => {
    const privateKey = localStorage.getItem('privateKey');
    const json = confirmationListResponse;
    const crypt = new Crypt();
    db.transaction('rw', db.confirmations, async () => {
      const confirmationListData = await db.confirmations.toArray();
      const addedEntries = json ? json.filter(o1 => !confirmationListData.some(o2 => o1.id === o2.id)) : [];
      if (addedEntries) {
        await addedEntries.map(async (value, key) => {
          if (await db.confirmations.where({ id: value.id })) {
            const decrypted = await crypt.decrypt(privateKey, value.data);
            const message = JSON.parse(decrypted.message);

            // IF NEW DATA ADD TO OFFLINE DATABASE
            await db.confirmations.put({
              id: value.id,
              ustid1: message.ustid1,
              ustid2: message.ustid2,
              name: message.name,
              error_code: message.errorCode,
              street: message.street,
              place: message.place,
              postcode: message.postcode,
              date: message.date,
              time: message.time,
              result_name: message.result_name,
              result_place: message.result_place,
              result_postcode: message.result_postcode,
              result_street: message.result_street,
              valid_from: message.valid_from,
              valid_to: message.valid_to,
              print: message.print,
              import_id: value.import_id,
              license_id: value.license_id,
              user_id: value.user_id,
              created_at: value.created_at,
              updated_at: value.updated_at,
            });
          }
        });
      }

      const removedEntries = confirmationListData.filter(o1 => !confirmationListResponse.some(o2 => o1.id === o2.id));
      const removedIds = removedEntries ? removedEntries.map((obj) => obj.id) : [];

      db.confirmations.bulkDelete(removedIds);
      if (props.savedConfirmationResponse && isFormSubmitted) {
        const confirmationId = props.savedConfirmationResponse.id;
        props.history.push(`/confirmations/${confirmationId}/show`);
      }
    }).catch(e => {
      console.error(e.stack || e);
    });
  }

  React.useEffect(() => {
    if (props.confirmationListResponse && isFormSubmitted) {
      setConfirmationData(props.confirmationListResponse);
    }
  }, [props.confirmationListResponse]);

  const formData = {
    ustid2: props.record.ustid2,
    name: props.record.name,
    place: props.record.place,
    postcode: props.record.postcode,
    street: props.record.street
  }

  const onSubmitForm = async (values) => {
    const expertString = '&Firmenname=' + values.name + '&Ort=' + values.place + '&PLZ=' + values.postcode + '&Strasse=' + values.street;
    const queryString = '?UstId_1=' + authData.license.ustid + '&UstId_2=' + values.ustid2 + '' + expertString;
    setFormSubmitted(true);
    setLoading(true);
    await props.evatRequest(queryString);
  }

  const showVisibilityOfCorrectionButton = () => {
    if (props.record.error_code === 200
      && props.record.result_name === 'A'
      && props.record.result_place === 'A'
      && props.record.result_postcode === 'A'
      && props.record.result_street === 'A') {
      return false;
    }
    return true;
  };

  return (
    <div ref={ref}>
      <h1 className="headliner">
        USt-IdNr. <b>{props.record.ustid2}</b>
      </h1> {/* @TODO Translate: VAT registration number */}
      {loading ? (
        <div style={{ minHeight: '80vh' }}>
          <LinearProgress style={{ margin: 60 }} />
          <p style={{ textAlign: 'center' }}>Bitte warten Sie einen Moment. Die Anfrage wurde gestartet.</p> {/* @TODO Translate: Please wait a moment. Die Request had been started.  */}
        </div>
      ) : (
        <Grid container spacing={2}>
          <Grid item md={6}>
            <p>Bestätigungsverfahren gemäß §18e Umsatzsteuergesetz</p> {/* @TODO Translate: confirmation process according to §18e Umsatzsteuergesetz */}
            <p style={{ fontWeight: 'bold', marginBottom: 46 }}>
              Ihr Anfrage vom {props.record.date} um {props.record.time} Uhr
            </p>
            {/* @TODO Translate: Your request from $date_time */}
            <Grid container style={{ marginBottom: 16 }}>
              <Grid>Status:</Grid> <StatusCodeDetails {...props} />
            </Grid>
            {/* @TODO Translate: Status */}
            <Grid container style={{ marginBottom: 16, backgroundColor: 'rgba(0,0,0,0.05)', borderRadius: 5, padding: 20 }}>
              <Grid item md={9}>
                <Grid item md={12}>
                  <b>Name: </b> {props.record.name}
                </Grid>
                {/* @TODO Translate: Name */}
                <Grid item md={12}>
                  <b>Ort: </b> {props.record.place}
                </Grid>
                {/* @TODO Translate: City */}
                <Grid item md={12}>
                  <b>Postleitzahl: </b> {props.record.postcode}
                </Grid>
                {/* @TODO Translate: ZIP code */}
                <Grid item md={12}>
                  <b>Straße: </b> {props.record.street}
                </Grid>
                {/* @TODO Translate: Street */}
                <Grid item md={12}>
                  <b>Gültig von: </b> {props.record.valid_from}
                </Grid>
                {/* @TODO Translate: Valid from */}
                <Grid item md={12}>
                  <b>Gültig bis: </b> {props.record.valid_to}
                </Grid>
                {/* @TODO Translate: Valid until */}
              </Grid>
              {showVisibilityOfCorrectionButton() && (
                <Grid item md={3} style={{ position: 'relative' }}>
                  {
                    (isFormVisible === false) ? <Button
                      style={{ position: 'absolute', bottom: 0, right: 0 }}
                      className="button" variant="contained" color="primary"
                      onClick={() => setFormVisibility(true)} >
                      korrigieren
                    </Button> :
                    ''
                  }
                </Grid>
              )}
            </Grid>
            {isFormVisible && (
              <Grid container spacing={2} style={{ marginBottom: 16, padding: 8 }}>
                <Form initialValues={formData} onSubmit={onSubmitForm}>
                  {property => (
                    <form onSubmit={property.handleSubmit} style={{ width: '100%' }}>
                      <ThemeProvider theme={theme}>
                        <Grid container md={12} direction="row" style={{ alignItems: 'center' }}>
                          <Grid item md={12} style={{ marginBottom: 10 }}>
                            <Field name="ustid2">
                              {propsValue => {
                                return <TextFormField
                                  variant="filled"
                                  error={props.record.error_code !== 200}
                                  color={props.record.error_code === 200 ? 'primary' : null}
                                  focused
                                  fullWidth
                                  label="USt-IdNr."
                                  {...propsValue.input}
                                />
                              }}
                            </Field>
                          </Grid>
                          <Grid item md={12} style={{ marginBottom: 10 }}>
                            <Field name="name">
                              {propsValue => (
                                <TextFormField
                                  variant="filled"
                                  error={props.record.result_name !== 'A'}
                                  color={props.record.result_name === 'A' ? 'primary' : null}
                                  focused
                                  fullWidth
                                  label="Name"
                                  {...propsValue.input}
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item md={12} style={{ marginBottom: 10 }}>
                            <Field name="place">
                              {propsValue => (
                                <TextFormField
                                  variant="filled"
                                  error={props.record.result_place !== 'A'}
                                  color={props.record.result_place === 'A' ? 'primary' : null}
                                  focused
                                  fullWidth
                                  label="Ort"
                                  {...propsValue.input}
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item md={12} style={{ marginBottom: 10 }}>
                            <Field name="postcode">
                              {propsValue => (
                                <TextFormField
                                  variant="filled"
                                  error={props.record.result_postcode !== 'A'}
                                  color={props.record.result_postcode === 'A' ? 'primary' : null}
                                  focused
                                  fullWidth
                                  label="Postleitzahl"
                                  {...propsValue.input}
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item md={12} style={{ marginBottom: 10 }}>
                            <Field name="street">
                              {propsValue => (
                                <TextFormField
                                  variant="filled"
                                  error={props.record.result_street !== 'A'}
                                  color={props.record.result_street === 'A' ? 'primary' : null}
                                  focused
                                  fullWidth
                                  label="Straße"
                                  {...propsValue.input}
                                />
                              )}
                            </Field>
                          </Grid>
                        </Grid>
                      </ThemeProvider>
                      <Grid item md={12} style={{ marginBottom: 10, textAlign: 'right' }}>
                        <Button variant="text" color="primary" onClick={() => setFormVisibility(false)}>
                          Abbrechen
                        </Button>
                        <Button type="submit" variant="contained" className="button" color="primary">
                          Anfrage Senden
                        </Button>
                      </Grid>
                    </form>)}
                </Form>
              </Grid>
            )}
            <Grid container spacing={2} style={{ marginBottom: 16 }}>
              <Grid item md={4} className="formGroup">
                <span className="fakeInput">USt-IdNr.</span>
                {/* @TODO Translate: VAT registration number */}
                <Grid container spacing={3} style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', width: '100%' }}>
                  <Grid item>
                    <b>{props.record.ustid2}</b>
                  </Grid>
                  <Grid item>
                    <StatusCode {...props} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={2} className="formGroup">
                <span className="fakeInput">Name</span>
                {/* @TODO Translate: Name */}
                <TextFieldColor {...props} source="result_name" />
              </Grid>
              <Grid item md={2} className="formGroup">
                <span className="fakeInput">Ort</span>
                {/* @TODO Translate: City */}
                <TextFieldColor {...props} source="result_place" />
              </Grid>
              <Grid item md={2} className="formGroup">
                <span className="fakeInput">Postleitzahl</span>
                {/* @TODO Translate: ZIP code */}
                <TextFieldColor {...props} source="result_postcode" />
              </Grid>
              <Grid item md={2} className="formGroup">
                <span className="fakeInput">Straße</span>
                {/* @TODO Translate: Street */}
                <TextFieldColor {...props} source="result_street" />
              </Grid>

              <Grid container spacing={2} style={{ margin: 4, marginTop: 24 }}>
                <Grid item md={6}>
                  <Link to="/confirmations" style={{ textDecoration: 'none' }}>
                    <Button className="button" variant="contained" color="primary">
                      Zur Gesamtübersicht
                    </Button> {/* @TODO Translate: Go back to List */}
                  </Link>
                </Grid>
                <Grid item md={6} style={{ textAlign: 'right' }}>
                  <Button
                    style={{ backgroundColor: 'var(--red)', fontSize: '12px' }}
                    aria-controls="simple-menu"
                    size="small"
                    aria-haspopup="true"
                    color="primary"
                    variant="contained"
                    onClick={() => setPDFModalVisibility(true)}
                    disabled={!privateKey}
                    startIcon={<PictureAsPdf />}
                  >
                    REVISIONSSICHER ABLEGEN {/* @TODO Translate */}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4}>
            <div className="infoBox">
              <b>Bedeutung:</b>
              {/* @TODO Translate: Explanation */}
              <p>
                <b>A</b>
                <span>stimmt mit den Angaben überein, die hierzu im angefragten EU-Mitgliedstaat gespeichert sind.</span> {/* @TODO Translate: Coincides with the data that are registered in the requested EU member state. */}
              </p>
              <p>
                <b>B</b>
                <span>stimmt NICHT mit den Angaben überein, die hierzu im angefragt EU-Mitgliedstaat gespeichert sind</span>
                {/* @TODO Translate: Does not coincide with the data that is registered in the requested EU member state. */}
              </p>
              <p>
                <b>C</b>
                <span>diese Angaben wurden von Ihnen nicht angefragt</span>
                {/* @TODO Translate: These data has not been requested by you. */}
              </p>
              <p>
                <b>D</b>
                <span>diese Angaben wurden vom angefragt EU-Mitgliedstaat nicht mitgeteilt</span>
                {/* @TODO Translate: The informations have not been transmitted by the requested EU member state. */}
              </p>
            </div>
          </Grid>
        </Grid>
      )}
      {isPDFModalVisible && (
        <PDFViewModal {...props} open={isPDFModalVisible} handleClose={() => setPDFModalVisibility(false)}>
          <PdfViewWizardSingleConfirmation list={[props.record]} {...props} onClose={() => setPDFModalVisibility(false)} />
        </PDFViewModal>
      )}
    </div>
  );
};

// Prop types of props.
DetailsConfirmation.propTypes = {
  evatRequest: PropTypes.func,
  evatRequestResponse: PropTypes.any,
}

// Set default props.
DetailsConfirmation.defaultProps = {
  evatRequest: () => { },
  evatRequestResponse: null,
}

export default compose(importerCTX)(withRouter(DetailsConfirmation));
