import React, { forwardRef } from 'react';
import { useLogout } from 'react-admin';
import MenuItem from '@material-ui/core/MenuItem';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';

const MyLogoutButton = forwardRef((props, ref) => {
  const logout = useLogout();
  const handleClick = () => logout({}, '/login', false);
  return (
    <MenuItem onClick={handleClick} ref={ref}>
      <ExitIcon color="red" style={{ color: 'red' }} /> <b style={{ marginLeft: 12, color: 'red' }}> Abmelden / Nutzerwechsel</b> {/* @TODO Translate: Logout / Switch User */}
    </MenuItem>
  );
});

export default MyLogoutButton;
