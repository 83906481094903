import React, { Component } from 'react';
import { Button, Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import { Title } from 'react-admin';
import { Buttons } from '../confirmations/components/createButton';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import { UserConfirmationsList, ConfirmationsList } from '../confirmations/components/helper';
const Logo = require('../../assets/images/logo.jpg');
const poster1 = require('../../assets/images/logo.jpg');
// <img src="https://zfumsatzsteuer.de/wp-content/uploads/2020/01/cropped-Logo-in-L%C3%A4nge-gestaucht.jpg" />
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const privateKey = localStorage.getItem('privateKey');
    return (
      <>
        <Title title="ZFU VIP Tool" />
        {!privateKey ? (
          <Alert variant="filled" severity="error">
            privater Schlüssel konnte nicht gefunden werden, die Sync Funktion und neue Anfragen sind abgeschaltet. <br></br> {/* @TODO Translate: The private key could not be found. The synchronisation and new requests have been turned off. */}
            <Link style={{ color: '#fff', textDecoration: 'none', fontWeight: 'bolder' }} to="/my-profile">
              klicke hier um dein Schlüssel hochzuladen.
            </Link>
            {/* @TODO Translate: Click here to upload your private key. */}
          </Alert>
        ) : null}

          <Card style={{ padding: 24 }}>
            <div style={{ textAlign: 'center', marginBottom: 24, marginTop: 24 }}>
              <img src="http://zfulanding.henworx.de/images/VIP-logo.png"  />
            </div>

            {privateKey ? (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: 24, marginTop: 24 }}>
              <Buttons />
            </div>
            ) :
              <div style={{ width: '100%', maxWidth: '40em', marginBottom: 24, marginTop: 24, marginLeft: 'auto', marginRight: 'auto' }}>
                <h1>Willkommen bei VIP-Tool</h1>
                <p>Unternehmen, die Waren grenzüberschreitend in Länder der Europäischen Union umsatzsteuerfrei liefern, müssen die Umsatzsteuer-Identifikationsnummern ihrer Kunden auf Richtigkeit und Gültigkeit überprüfen. Diese Überprüfung muss normgerecht dokumentiert werden, um die Steuerfreiheit nicht zu riskieren. Wir helfen Ihnen, dieser Pflicht nachzukommen und die Überprüfung der USt-ID Ihrer Kunden einfach, schnell und unkompliziert durchzuführen sowie das Ergebnis rechtskonform und revisionssicher zu protokollieren.</p>
                <p>Um Ihre Unternehmenskontakte zu schützen, werden die Ergebnisse Ihrer Abfragen Ende-Zu-Ende verschlüsselt.
                <strong>Laden Sie bitte Ihren privaten Schlüssel in den Browser, um Abfragen einsehen und erstellen zu können.</strong></p>
                <div style={{ textAlign: 'right' }}>
                  <Link to="/my-profile" className="link">
                    <Button variant="contained"
                            size="large"
                            color={'primary'}
                            style={{ padding: '1em', width: '100%' }}
                      >
                      Zum Schlüssel Upload
                    </Button>
                  </Link>
                </div>
              </div>
            }
          </Card>

      </>
    );
  }
}
export default Dashboard;
